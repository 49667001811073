import { NutakuEnv } from 'src/context/Nutaku.Context';
import {
  getEnvValue,
  getNutakuUserIdParam,
  isInIframe,
} from 'src/shared/helpers';

export const appConfig = {
  app: {
    version: '0.10.0', // getEnvValue('REACT_APP_VERSION'),
  },
  supabase: {
    host: getEnvValue('REACT_APP_SUPABASE_HOST'),
    publicKey: getEnvValue('REACT_APP_SUPABASE_PUBLIC_KEY'),
  },
  host: {
    API: getEnvValue('REACT_APP_API_HOST'),
    CDN: 'https://teasegames.b-cdn.net/teasegames/ui_elements/client/web/public',
    EMOJI: getEnvValue('REACT_APP_CDN_EMOJI'),
  },
  nutaku: {
    nutakuShareLink: process.env.REACT_APP_NUTAKU_SHARE_URL,
    nutakuEnv: (() => {
      if (process.env.REACT_APP_NUTAKU_ENV) {
        return process.env.REACT_APP_NUTAKU_ENV as NutakuEnv;
      } else if (getNutakuUserIdParam()) {
        (function () {
          //@ts-ignore
          window.gadgets.rpc.call(
            null,
            'nutaku.setIframeHeight',
            null,
            '123px'
          );
        })();
        // document.body.classList.remove('h-100');
        //  document.body.style.height = window.screen.height + 'px';
        return NutakuEnv.SP;
      } else if (isInIframe()) {
        return NutakuEnv.Web;
      } else {
        return null;
      }
    })(),
    isNutaku: !!process.env.REACT_APP_NUTAKU_ENV || isInIframe(),
  },
  subscription: {
    patreon: {
      clientId: getEnvValue('REACT_APP_PATREON_ID'),
      // levels: {
      //   [SubscriptionLevel.Boyfriend]: '22200821',
      //   [SubscriptionLevel.Daddy]: '22183527',
      //   [SubscriptionLevel.Boss]: '22183578',
      // },
    },
    subscribestar: {
      // clientId: getEnvValue('REACT_APP_SUBSCRIBESTAR_ID'),
      // levels: {
      //   [SubscriptionLevel.Boyfriend]: '22200821',
      //   [SubscriptionLevel.Daddy]: '22183527',
      //   [SubscriptionLevel.Boss]: '22183578',
      // },
    },
    boosty: {
      clientId: null,
      // levels: {
      //   [SubscriptionLevel.Boyfriend]: '2469518',
      //   [SubscriptionLevel.Daddy]: '2435322',
      //   [SubscriptionLevel.Boss]: '2435323',
      // },
    },
  },
  postHog: {
    apiKey: getEnvValue('REACT_APP_PUBLIC_POSTHOG_KEY'),
    host: getEnvValue('REACT_APP_PUBLIC_POSTHOG_HOST'),
    selfHostedApiKey: getEnvValue('REACT_APP_PUBLIC_SELF_POSTHOG_KEY'),
    selfHostedHost: getEnvValue('REACT_APP_PUBLIC_SELF_POSTHOG_HOST'),
  },
};
