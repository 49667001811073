import { FilterQuery } from 'src/services/API/BotAPI';

export const countFilters = (filters: FilterQuery) => {
  let count = 0;
  if (filters.Appearance) {
    count += Object.keys(filters.Appearance).length;
  }
  if (filters.Personality) {
    count += filters.Personality.Occupation ? 1 : 0;
    count += filters.Personality.Personality ? 1 : 0;
    count += filters.Personality.Kinks?.length
      ? filters.Personality.Kinks?.length
      : 0;
  }

  return count;
};
