import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useModalContext } from 'src/context/Modal.Context';

import { useState } from 'react';
import { Connection } from 'src/@types/common';
import { ConnectionsAPI } from 'src/services/API/ConnectionsAPI';
import { Dialogue } from '../Dialogue/Dialogue';
import { useConnectionsStore } from './ConnectionsStore';

export const useOpenChat = () => {
  const queryClient = useQueryClient();
  const setIssChatOpen = useConnectionsStore((state) => state.setIsChatOpen);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const navigate = useNavigate();
  const { addModal } = useModalContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const getChatMutation = useMutation({
    mutationFn: (chat: Connection) =>
      ConnectionsAPI.getChat({
        BotId: chat.BotId,
        StartFrom: 0,
        PageSize: 20,
      }),

    onMutate: () => setIsChatLoading(true),

    onSuccess: (data, chat) => {
      setIsChatLoading(false);
      navigate(`/connections?ChatId=${chat.BotId}`, {
        state: { chatName: `Chat with ${chat.Name}` },
      });

      if (data?.data) {
        addModal({
          onClose: () => {
            setSearchParams({});
            setIssChatOpen(false);
            queryClient.invalidateQueries({
              queryKey: ['Connections'],
              exact: false,
              refetchType: 'all',
            });
          },
          children: (
            <Dialogue
              {...data.data}
              showMedia={searchParams.get('media') || undefined}
            ></Dialogue>
          ),
        });
      }
    },

    onError: () => setIsChatLoading(false),
  });

  const loadChat = (chat: Connection) => {
    getChatMutation.mutate(chat);
  };

  return { loadChat, isChatLoading };
};
