import useMedia from './useMedia';
// import {motion} from 'framer-motion';
// import { AnimParamInOutOpacityYNeg100, TransTweenAnticipate03 } from 'src/shared/animations';
import useKeepScrollPosition from './useKeepScrollPosition';
import { SetStateAction, useEffect, useRef, useState } from 'react';

import { typingContent } from '../../Chat/ChatMessage/ChatMessage';
import { MediaFilter } from '../Shared/MediaFilter/MediaFilter';
import { MediaBlockSwitcher } from '../Shared/MediaBlockSwitcher/MediaBlockSwitcher';
import { MediaType } from 'src/services/API/MediaAPI';
import { ChatMediaPreview } from 'src/components/Chat/ChatMedia/ChatMediaPreview';
import './../index.css';
import { getAxiosInstance } from 'src/services/axios/axios';
import { NoMedia } from '../Shared/NoMedia';
import { useUserContext } from 'src/context/User.context';
import { CommunityMediaUnavailable } from '../Shared/CommunityMediaUnavailable';
import { useTranslation } from 'react-i18next';

export enum MediaBlock {
  Dialogue = 'dialogue',
  Community = 'community',
}
export type MediaProps = {
  selectedMediaBlock: MediaBlock;
  botId: string;
  onSelectMediaBlock: (block: MediaBlock) => any;
};

export enum MediaStatus {
  Init = 'init',
  Idle = 'idle',
  Loading = 'loading',
}
export function CommunityMedia({
  botId,
  selectedMediaBlock,
  onSelectMediaBlock,
}: MediaProps) {
  const { user } = useUserContext();
  const { t } = useTranslation();

  const [selectedMediaType, setSelectedMediaType] = useState<MediaType | null>(
    null
  );
  if (!user!.MergedSubscription.Level) {
    return (
      <div className="media_container px-2">
        <div className="d-flex gap-2 mb-2 justify-content-between media_controls">
          <MediaFilter
            selectedType={selectedMediaType}
            onSelect={(mediaType) => setSelectedMediaType(mediaType)}
          />
          <MediaBlockSwitcher
            selectedBlock={selectedMediaBlock}
            onSelect={(block) => {
              // resetMedia();
              onSelectMediaBlock(block);
            }}
          />
        </div>
        <CommunityMediaUnavailable />
      </div>
    );
  }

  const { media, setLastMediumRef, total, status } = useMedia({
    botId,
    selectedMediaBlock,
  });

  const { containerRef } = useKeepScrollPosition([media]);

  const deleteMediaListenerRef = useRef(false);
  const [filteredOutMedia, setFilteredOutMedia] = useState<string[]>([]);
  useEffect(() => {
    if (deleteMediaListenerRef.current) {
      return;
    }
    deleteMediaListenerRef.current = true;
    getAxiosInstance().interceptors.response.use(
      (res) => {
        if (res.config.url === 'unpublishmedia' && res.status === 200) {
          const mediaId = JSON.parse(res.config.data).MediaId;
          setFilteredOutMedia([
            ...filteredOutMedia.filter((m) => m !== mediaId),
            mediaId,
          ]);
        }
        return res;
      },
      (err) => {
        throw err;
      }
    );
    getAxiosInstance().interceptors.response.use(
      (res) => {
        if (res.config.url === 'publishmedia' && res.status === 200) {
          const mediaId = JSON.parse(res.config.data).MediaId;
          setFilteredOutMedia([
            ...filteredOutMedia.filter((m) => m !== mediaId),
          ]);
        }
        return res;
      },
      (err) => {
        throw err;
      }
    );
  });

  if (!media) {
    return (
      <div
        ref={(ref) => setLastMediumRef(ref as SetStateAction<any>)}
        className="m-auto text-center h-100 d-flex justify-content-center align-items-center"
      >
        {typingContent}
      </div>
    );
  }
  // if (media.length === 0){
  //   return <NoMedia/>
  // }
  return (
    <div className="media_container px-2">
      <div className="d-flex gap-2 mb-2 justify-content-between media_controls">
        <MediaFilter
          selectedType={selectedMediaType}
          onSelect={(mediaType) => setSelectedMediaType(mediaType)}
        />
        <MediaBlockSwitcher
          selectedBlock={selectedMediaBlock}
          onSelect={(block) => {
            // resetMedia();
            onSelectMediaBlock(block);
          }}
        />
      </div>
      {media.length === 0 && <NoMedia />}

      <div className="w-100 m-auto media" ref={containerRef}>
        {media
          .filter(({ MediaType }) => {
            if (selectedMediaType === null) {
              return true;
            }
            return selectedMediaType === MediaType;
          })
          .filter((m) => !filteredOutMedia.includes(m.MediaId))
          .map((m, i) => {
            return (
              <div
                key={m.MediaId}
                style={{ height: 0, paddingBottom: '100%' }}
                className="position-relative overflow-hidden horny-border-gray rounded-2 border"
                ref={(ref) =>
                  status !== MediaStatus.Loading &&
                  i > media.length - 4 &&
                  total > media.length
                    ? setLastMediumRef(ref as SetStateAction<any>)
                    : null
                }
              >
                <ChatMediaPreview {...m} />
              </div>
            );
          })}
      </div>
      {status === MediaStatus.Loading && (
        <div className="m-auto text-center  d-flex justify-content-center">
          {typingContent}
        </div>
      )}
    </div>
  );
}
