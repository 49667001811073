import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'src/context/Modal.Context';
import { useNutakuContext } from 'src/context/Nutaku.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import {
  SubscriptionLevel,
  SubscriptionPricesConfig,
} from 'src/services/API/ResourcesAPI';
import { SubscriptionProvider } from 'src/services/API/UserAPI';
import { AddBlock } from '../Shared/AddBlock/AddBlock';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from '../Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from '../Shared/Icon/Icon';
import { BoostyProviderFlow } from './SubscriptionProviderFlow/BoostyProviderFlow';
import { NutakuProviderFlow } from './SubscriptionProviderFlow/NutakuProviderFlow';
import { PatreonProviderFlow } from './SubscriptionProviderFlow/PatreonProviderFlow';
import { SubscribeStarProviderFlow } from './SubscriptionProviderFlow/SubscribeStarProviderFlow';
import './index.css';

const levelIconsSrc = {
  [SubscriptionLevel.Friend]: IconSrc.Friend,
  [SubscriptionLevel.Boyfriend]: IconSrc.SubscriptionBoyfriend,
  [SubscriptionLevel.Daddy]: IconSrc.SubscriptionDaddy,
  [SubscriptionLevel.Boss]: IconSrc.SubscriptionBoss,
};

export type SubscriptionProps = {
  level: SubscriptionLevel;
  selectedLevel: boolean;
  subscriptionData: SubscriptionPricesConfig;
  selectedProvider: SubscriptionProvider | null;
  isYearly?: boolean;
};

export function Subscription({
  level,
  selectedLevel,
  subscriptionData,
  selectedProvider,
  isYearly,
}: SubscriptionProps) {
  const { t } = useTranslation();
  const { isNutaku, nutakuEnv, setIsPaymentFrame } = useNutakuContext();
  // const isNutaku = Boolean(nutakCotext?.isNutaku);
  const levelIconSrc = levelIconsSrc[level];
  const [subscriptionProvider, setSubscriptionProvider] =
    useState<SubscriptionProvider | null>(null);
  const { addModal } = useModalContext();
  const { prices } = useResourcesContext();

  const benefits = [
    {
      icon: IconSrc.Energy,
      text: t('Unlimited energy'),
    },
    {
      icon: IconSrc.Chat,
      text: t('Unlimited messages'),
    },
    {
      icon: IconSrc.Time,
      text: t('Priority in NSFW chat and Media Generation'),
      disabled: [SubscriptionLevel.Friend],
    },

    {
      icon: IconSrc.Up,
      text: t('Boost Engagement of Your AI'),
      disabled: [SubscriptionLevel.Friend, SubscriptionLevel.Boyfriend],
    },
    {
      icon: IconSrc.NoAds,
      text: t('No Ads'),
    },
    {
      icon: IconSrc.Save,
      text: t('Increased AI Memory'),
      disabled: [SubscriptionLevel.Friend, SubscriptionLevel.Boyfriend],
    },

    {
      icon: IconSrc.First,
      text: t('Features early access'),
      disabled: [
        SubscriptionLevel.Friend,
        SubscriptionLevel.Boyfriend,
        SubscriptionLevel.Daddy,
      ],
    },
  ];

  const levelRegularPercentage = {
    [SubscriptionLevel.Friend]: prices?.Subscriptions.Friend.Price!,
    [SubscriptionLevel.Boyfriend]: prices?.Subscriptions.Boyfriend.Price!,
    [SubscriptionLevel.Daddy]: 25,
    [SubscriptionLevel.Boss]: 60,
  };

  const subscriptionProvidersPreview = (
    <>
      <HornyButton
        onClick={() => setSubscriptionProvider(SubscriptionProvider.Patreon)}
        bg={HornyButtonBG.Red}
        className="w-max-content"
        height={HornyButtonHeight.Shorter}
      >
        <Icon src={IconSrc.Patreon} size={IconSize.Small} />
        {t('Pay with Patreon')}
      </HornyButton>

      {/* <HornyButton
        onClick={() =>
          setSubscriptionProvider(SubscriptionProvider.SubscribeStar)
        }
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Short}
      >
        <Icon src={IconSrc.SubscribeStar} size={IconSize.Small} />
        Pay with SubscribeStar
      </HornyButton> */}
      <HornyButton
        onClick={() => setSubscriptionProvider(SubscriptionProvider.Boosty)}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
        className="w-max-content"
      >
        <Icon src={IconSrc.Boosty} size={IconSize.Small} />
        {t('Pay with Boosty')}
      </HornyButton>
      <HornyButton
        onClick={() => setSubscriptionProvider(SubscriptionProvider.Card)}
        bg={HornyButtonBG.Red}
        className="w-max-content"
        height={HornyButtonHeight.Shorter}
      >
        <Icon src={IconSrc.Card} size={IconSize.Small} />
        {t('Pay with Card')}
      </HornyButton>
    </>
  );

  const tutorialHrefs = {
    [SubscriptionProvider.Card]:
      'https://temptationgames.com/guides-to-connect-accounts',
    [SubscriptionProvider.Patreon]:
      'https://temptationgames.com/guides-to-connect-accounts',
    [SubscriptionProvider.Boosty]:
      'https://temptationgames.com/guides-to-connect-accounts',
    [SubscriptionProvider.SubscribeStar]:
      'https://temptationgames.com/5f5a3b4ccabb4e3db6d70d9a7bf36958',
  };

  const subscriptionProviderElement = (
    el: JSX.Element,
    provider: SubscriptionProvider
  ) => {
    console.log({
      tutorialHrefs,
      provider,
    });
    return (
      <>
        {/* <div className="d-flex flex-column gap-3 align-items-center w-100"> */}
        {el}
        {/* <div className="d-flex  gap-3 align-items-center w-100"> </div>
        <div className="d-flex  gap-3 align-items-center w-100"> */}
        <HornyButton
          onClick={() => setSubscriptionProvider(null)}
          bg={HornyButtonBG.Red}
          height={HornyButtonHeight.Shorter}
          className="w-max-content"
        >
          <Icon src={IconSrc.Back} size={IconSize.Small} />
          {t('Back to list')}
        </HornyButton>
        <a href={tutorialHrefs[provider]} target="_blank">
          <Icon className="ms-2" src={IconSrc.Info} />
        </a>
        {/* </div>
      </div> */}
      </>
    );
  };

  const subscriptionProviderInteraction = {
    [SubscriptionProvider.Card]: () => (
      <PatreonProviderFlow
        subscriptionId={subscriptionData.Providers.Patreon.Id}
        subscriptionLevel={level}
        selectedProvider={false}
        mockCard={true}
      />
    ),
    [SubscriptionProvider.Patreon]: () => (
      <PatreonProviderFlow
        subscriptionId={subscriptionData.Providers.Patreon.Id}
        subscriptionLevel={level}
        selectedProvider={false}
        mockCard={false}
      />
    ),
    [SubscriptionProvider.SubscribeStar]: () => (
      <SubscribeStarProviderFlow
        subscriptionId={subscriptionData.Providers.SubscribeStar.Id}
        subscriptionLevel={level}
        selectedProvider={false}
      />
    ),
    [SubscriptionProvider.Boosty]: () => (
      <BoostyProviderFlow
        subscriptionLevel={level}
        selectedProvider={false}
        subscriptionId={subscriptionData.Providers.Boosty.Id}
      />
    ),
  };

  const selecteProviderIntegration = {
    [SubscriptionProvider.Card]: () => (
      <PatreonProviderFlow
        subscriptionId={subscriptionData.Providers.Patreon.Id}
        subscriptionLevel={level}
        selectedProvider={selectedProvider === SubscriptionProvider.Patreon}
        mockCard={true}
      />
    ),
    [SubscriptionProvider.Patreon]: () => (
      <PatreonProviderFlow
        subscriptionId={subscriptionData.Providers.Patreon.Id}
        subscriptionLevel={level}
        selectedProvider={selectedProvider === SubscriptionProvider.Patreon}
        mockCard={false}
      />
    ),
    [SubscriptionProvider.SubscribeStar]: () => (
      <SubscribeStarProviderFlow
        subscriptionId={subscriptionData.Providers.SubscribeStar.Id}
        subscriptionLevel={level}
        selectedProvider={
          selectedProvider === SubscriptionProvider.SubscribeStar
        }
      />
    ),
    [SubscriptionProvider.Boosty]: () => (
      <BoostyProviderFlow
        subscriptionId={subscriptionData.Providers.Boosty.Id}
        subscriptionLevel={level}
        selectedProvider={selectedProvider === SubscriptionProvider.Boosty}
      />
    ),
  };

  const energyRefill = {
    [SubscriptionLevel.Friend]: `${prices?.NoSubscription.Energy} → ${prices?.Subscriptions.Friend.Energy} ${t('daily refill')}`,
    [SubscriptionLevel.Boyfriend]: `${prices?.NoSubscription.Energy} → ${prices?.Subscriptions.Boyfriend.Energy} ${t('daily refill')}`,
    [SubscriptionLevel.Daddy]: `${prices?.NoSubscription.Energy} → ${prices?.Subscriptions.Daddy.Energy} ${t('daily refill')}`,
    [SubscriptionLevel.Boss]: t('Unlimited energy'),
  };

  const messagesRefill = {
    [SubscriptionLevel.Friend]: `${prices?.NoSubscription.Messages} → ${prices?.Subscriptions.Friend.Messages} ${t('daily refill')}`,
    [SubscriptionLevel.Boyfriend]: t('Unlimited messages'),
    [SubscriptionLevel.Daddy]: t('Unlimited messages'),
    [SubscriptionLevel.Boss]: t('Unlimited messages'),
  };

  return (
    <div
      className={`horny-bg-gradient_orange position-relative p-6 horny-shadow-orange w-100 mx-auto subscription rounded-5 ${selectedLevel ? 'opacity-75' : ''}`}
    >
      <div
        className="overflow-hidden position-absolute rounded-5 z-1"
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <motion.div
          className="position-absolute"
          initial={{ x: -500 }}
          animate={{ x: 500 }}
          transition={{
            duration: 5,
            repeat: Infinity,
            repeatType: 'loop',
            ease: 'easeInOut',
          }}
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `
            linear-gradient(
              60deg,
              transparent 30%, 
              rgba(255,255,255,0.1) 30%, 
              rgba(255,255,255,0.2) 50%,
              transparent 50%
            ),
            linear-gradient(
              60deg,
              transparent 55%, 
              rgba(255,255,255,0.05) 55%, 
              rgba(255,255,255,0.1) 65%, 
              transparent 65%
            )`,
          }}
        />
      </div>
      <div className={'d-flex flex-column gap-5 z-2 position-relative'}>
        <div className="d-flex justify-content-between align-items-center ">
          <div className="d-flex gap-2 align-items-center">
            <Icon src={levelIconSrc} size={IconSize.XL} />
            <span className="fs-2 fw-bold">
              {selectedLevel ? (
                <span>
                  <u>{t('Your subscription')}:</u>{' '}
                </span>
              ) : (
                ''
              )}
              {t(level as any)}
            </span>
          </div>
          <div className="d-flex gap-3 align-items-center">
            {!isNutaku && (
              <div className="fs-2 fw-bold">${subscriptionData.Price}</div>
            )}
            {level !== SubscriptionLevel.Boyfriend &&
              level !== SubscriptionLevel.Friend &&
              !isNutaku && (
                <div className="position-relative">
                  <div className="fs-4 opacity-75">
                    ${levelRegularPercentage[level]}
                  </div>
                  <div
                    className="bg-white position-absolute"
                    style={{
                      height: 2,
                      left: 0,
                      top: 12,
                      width: '110%',
                      transform: 'rotate(-27deg)',
                    }}
                  />
                </div>
              )}
            {!isNutaku &&
              level !== SubscriptionLevel.Boyfriend &&
              level !== SubscriptionLevel.Friend && (
                <AddBlock className="fs-6 horny-bg-dark_half opacity-75">
                  <div style={{ fontSize: 10 }}>
                    {Math.floor(
                      (levelRegularPercentage[level] - subscriptionData.Price) /
                        (levelRegularPercentage[level] * 0.01)
                    )}
                    {t('% discount')}
                  </div>
                </AddBlock>
              )}
            {isNutaku && isYearly && (
              <AddBlock className="fs-6 horny-bg-dark_half opacity-75">
                <div style={{ fontSize: 10 }}>
                  {Math.floor(
                    (1 -
                      (subscriptionData.NutakuPriceYear || 0) /
                        12 /
                        (subscriptionData.NutakuPrice || 1)) *
                      100
                  )}
                  {t('% discount')}
                </div>
              </AddBlock>
            )}
          </div>
        </div>
        <div className="d-grid subscription_grid gap-3">
          {benefits.map(({ icon, text, disabled }, i) => {
            return (
              <div
                key={text}
                className={`d-flex align-items-center gap-2 ${disabled && disabled.includes(level) ? 'opacity-50' : ''}`}
              >
                <Icon src={icon} size={IconSize.Small} />
                {!i ? (
                  <span
                    style={{
                      fontWeight:
                        level === SubscriptionLevel.Boss ? 'bold' : 'normal',
                    }}
                  >
                    {energyRefill[level]}
                  </span>
                ) : (
                  <span>{i === 1 ? messagesRefill[level] : text}</span>
                )}
              </div>
            );
          })}
        </div>
        {!isNutaku ? (
          <div className="gap-3 align-items-center justify-content-center d-flex flex-wrap">
            {!selectedLevel &&
              (selectedProvider
                ? selecteProviderIntegration[selectedProvider]()
                : !subscriptionProvider
                  ? subscriptionProvidersPreview
                  : subscriptionProviderElement(
                      subscriptionProviderInteraction[subscriptionProvider](),
                      subscriptionProvider!
                    ))}
          </div>
        ) : (
          <NutakuProviderFlow
            selectedLevel={selectedLevel}
            selectedProvider={true}
            isYearly={isYearly as boolean}
            subscriptionLevel={level}
            subscriptionId={''}
            subscriptionData={subscriptionData}
          />
        )}
      </div>
    </div>
  );
}
// const patreonInteraction = (
//   <>
//     <HornyButton
//       onClick={() =>
//         window.open(
//           'https://www.patreon.com/TemptationsAI/membership',
//           '_blank'
//         )
//       }
//       bg={HornyButtonBG.Red}
//       height={HornyButtonHeight.Short}
//     >
//       <Icon src={IconSrc.Discord} size={IconSize.Small} />
//       Subscribe
//     </HornyButton>

//     <HornyButton
//       onClick={() =>
//         window.open(
//           `https://patreon.com/oauth2/authorize?response_type=code&scope=identity&client_id=${process.env.REACT_APP_PATREON_ID}&redirect_uri=http://localhost:3000/patreon&state=their_session_id`,
//           '_blank'
//         )
//       }
//       bg={HornyButtonBG.Red}
//       height={HornyButtonHeight.Short}
//     >
//       <Icon src={IconSrc.Discord} size={IconSize.Small} />
//       Verify subscription
//     </HornyButton>
//   </>
// );

// const subscribestarInteraction = (
//   <>
//     <HornyButton
//       onClick={() =>
//         window.open(
//           'https://subscribestar.adult/temptations-ai/subscribe',
//           '_blank'
//         )
//       }
//       bg={HornyButtonBG.Red}
//       height={HornyButtonHeight.Short}
//     >
//       <Icon src={IconSrc.Discord} size={IconSize.Small} />
//       Subscribe w s
//     </HornyButton>

//     <HornyButton
//       onClick={() =>
//         window.open(
//           `https://subscribestar.adult/oauth2/authorize?client_id=YOUR_CLIENT_ID&redirect_uri=YOUR_REDIRECT_URL&response_type=code&scope=ONE_OR_MULTIPLE_SCOPES`,
//           '_blank'
//         )
//       }
//       bg={HornyButtonBG.Red}
//       height={HornyButtonHeight.Short}
//     >
//       <Icon src={IconSrc.Discord} size={IconSize.Small} />
//       Verify subscription
//     </HornyButton>
//   </>
// );

{
  /* <div className="h-100 d-flex align-items-center justify-content-center flex-column"> */
}
{
  /* <HornyButton
  onClick={() => nutakuCreatePayment()}
  bg={HornyButtonBG.Red}
  className="w-max-content"
  height={HornyButtonHeight.Medium}
>
  Month:
  <Icon src={IconSrc.NutakuCoin} size={IconSize.Small} />
  {isYearly
    ? Math.floor((subscriptionData.NutakuPriceYear || 0) / 12)
    : subscriptionData.NutakuPrice}
  {isYearly && (
    <div className="position-relative">
      <div className="opacity-75">
        {subscriptionData.NutakuPrice}
      </div>
      <div
        className="bg-white position-absolute"
        style={{
          height: 1,
          left: 0,
          top: 8,
          width: '110%',
          transform: 'rotate(-27deg)',
        }}
      />
    </div>
  )}
</HornyButton>
{isYearly && (
  <div className="opacity-75 mt-2">
    You will be charged{' '}
    <Icon src={IconSrc.NutakuCoin} size={IconSize.Small} />{' '}
    {subscriptionData.NutakuPriceYear} */
}
{
  /* </div> */
}
