import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import { AddBlock } from 'src/components/Shared/AddBlock/AddBlock';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { Price } from 'src/components/Shared/Price/Price';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { BotAPI } from 'src/services/API/BotAPI';
import {
  CharacterAPI,
  CharacterGenerationDraftAPIModel,
  CharacterGenerationPhotoChoice,
  CharacterGenerationStage,
  ImageGenerationModel,
} from 'src/services/API/CharacterAPI';
import { ConnectionsAPI } from 'src/services/API/ConnectionsAPI';
import { OnChoiceCallback } from '../CharacterGeneration';
import { PromptPicker } from '../ConfigureAppearance/PromptPicker';
import {
  TypePreview,
  TypePreviewSize,
} from '../Shared/TypePreview/TypePreview';
import { useQueryClient } from '@tanstack/react-query';

export type PhotoChoiceProps = {
  onChoice: OnChoiceCallback;
  fetched: CharacterGenerationPhotoChoice;
  onBack: () => any;
  regeneratePhotos: () => Promise<any>;
  updateDraftFromChildren: (draft: CharacterGenerationDraftAPIModel) => any;
  model: ImageGenerationModel;
  appearance: CharacterGenerationDraftAPIModel[CharacterGenerationStage.Appearance];
};

export function PhotoChoice({
  onChoice,
  fetched,
  onBack,
  regeneratePhotos,
  updateDraftFromChildren,
  model,
  appearance,
}: PhotoChoiceProps) {
  const [generatedImages, setGeneratedImages] = useState(fetched.Photos);
  const [regenerating, setRegenerating] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | null>(null);
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const [resfreshing, setRefreshing] = useState(false);
  const refreshingRef = useRef(false);
  const [isCreating, setIsCreating] = useState(false);
  const { prices } = useResourcesContext();
  const { capture } = useAnalyticsContext();
  const { addModal, removeModal } = useModalContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  useEffect(() => {
    // console.log({ generatedImages, selected, current: intervalRef });

    if (generatedImages.length < 4 && resfreshing && !refreshingRef.current) {
      refreshingRef.current = true;
      // setRefreshing(true);
      CharacterAPI.getExistingDraft().then(({ data }) => {
        const photos = data![CharacterGenerationStage.PhotoChoice]!.Photos;
        // console.log({ data });
        setGeneratedImages(photos);
        updateDraftFromChildren(data!);
        // onChoice(CharacterGenerationStage.PhotoChoice, {
        //     Photos: photos,
        //     SelectedPhoto: selected,
        // });
        setRefreshing(false);
        refreshingRef.current = false;
        // if (photos.length >= 4) {
        //   clearInterval(intervalRef.current!);
        // }
      });
    }
  });
  useEffect(() => {
    //    if (!intervalRef.current) {
    intervalRef.current = setInterval(() => setRefreshing(true), 2000);
    // }

    return () => {
      clearInterval(intervalRef.current!);
    };
  }, []);

  const regenerate = () => {
    if (isCreating) {
      return;
    }
    setIsCreating(true);
    regeneratePhotos()
      .then(() => {
        setGeneratedImages([]);
        setSelected(null);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };
  const updateDraft = () => {
    if (!selected || isCreating) {
      return;
    }
    setIsCreating(true);
    const data: CharacterGenerationPhotoChoice = {
      Photos: generatedImages,
      SelectedPhoto: selected,
    };
    onChoice(CharacterGenerationStage.PhotoChoice, data)
      .then(() =>
        BotAPI.create().then(({ data: { BotId } }) =>
          ConnectionsAPI.createChat({ BotId }).then(() => {
            capture({
              event: 'character_creation',
              data: {},
            });

            queryClient.invalidateQueries({
              queryKey: ['Connections'],
              exact: false,
              refetchType: 'all',
            });

            setTimeout(() => {
              window.location.href = '/?GirlId=' + BotId;
            }, 500);
          })
        )
      )
      .finally(() => {
        setIsCreating(false);
      });
  };

  const showFullSize = (src: string) => {
    const modal = addModal({
      children: (
        <div
          onClick={() => removeModal(modal)}
          className="w-100 h-100 position-relative"
        >
          <img
            src={src}
            className="center-absolute position-absolute z-2"
            style={{ maxHeight: '100%', maxWidth: '100%' }}
          />
          <img
            src={src + '?width=100&blur=50'}
            className="w-100 h-100 position-absolute center-absolute z-1"
          />
        </div>
      ),
    });
  };

  const getPhotoElement = (src: string, available: boolean) => {
    const isSelected = available && selected === src;
    return (
      <div
        className="position-relative d-flex align-items-center justify-content-center"
        style={{ width: 'calc(50% - 5px)' }}
      >
        {!available && (
          <Icon
            src={IconSrc.Loader}
            size={IconSize.XL}
            className="horny-spin position-absolute z-2"
          />
        )}
        <div
          onClick={() => {
            if (available) {
              showFullSize(src);
            }
          }}
        >
          <TypePreview
            src={src}
            size={TypePreviewSize.XL}
            selected={selected === src}
          />
        </div>
        {!isSelected && (
          <>
            {available && !src.includes('error') && (
              <HornyButton
                height={HornyButtonHeight.Medium}
                onClick={() => available && setSelected(src)}
                className="px-4 py-2 position-absolute d-flex align-items-center justify-content-center"
                style={{ bottom: '10%' }}
              >
                <div className="m-auto">
                  {selected === src ? 'selected' : t('Choose')}
                </div>
              </HornyButton>
            )}
          </>
        )}
      </div>
    );
  };
  const generatedSources = generatedImages.map(({ Photo }) => ({
    src: Photo,
    available: true,
  }));
  const mockSources = Array(4)
    .fill(
      process.env.REACT_APP_CDN_HOST +
        '/img/character/photo-choice/preview.png?height=500'
    )
    .map((src) => ({
      src: src as string,
      available: false,
    }));
  // [...generatedSources,
  const sources = [...generatedSources, ...mockSources].slice(0, 4);

  const callPicker = () => {
    // const appearanceConfig = Object.fromEntries(Object.entries(appearance).map(([key, val]) => {
    //   const title = appearanceCategories.find(c => c.CategoryValue === key)!.Title;
    //   return ['Please select ' + title, !!val];
    // }))

    const modal = addModal({
      children: (
        <PromptPicker
          description={t('Select a propmpt for Photos')}
          {...{
            isGenerated: true,
            multiplier: 1,
            charGen: false,
            // multiplier: 4,
            // categoryToFilterOut: 'Sex',
            model,
            // showNudity: true,
            onPick: (genParams) => {
              if (regenerating) {
                return;
              }
              setRegenerating(true);
              onChoice(
                CharacterGenerationStage.Appearance,
                appearance,
                genParams
              )
                .then(() => {
                  setGeneratedImages([]);
                })
                .finally(() => {
                  setRegenerating(false);
                });
              // regenerate();
              removeModal(modal);
            },
          }}
        />
      ),
    });
  };

  return (
    <div className="w-100 h-100 d-flex flex-column gap-2 justify-content-between h-100 text-start">
      {(isCreating || regenerating) && <FullSizeLoader />}
      <div className="overflow-y-auto  pt-20  horny-border-bottom pb-3 h-100 horny-disable-scrollbar">
        <div className="d-flex flex-column gap-2 position-relative h-100">
          <div className="d-flex gap-2">
            {sources
              .slice(0, 2)
              .map(({ src, available }, i) => getPhotoElement(src, available))}
          </div>
          <div className="d-flex gap-2">
            {sources
              .slice(2)
              .map(({ src, available }) => getPhotoElement(src, available))}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column gap-4 align-items-center">
        {selected && (
          <HornyButton
            {...{
              'data-anal': 'character_creation',
            }}
            height={HornyButtonHeight.Tall}
            {...(isCreating ? { disabled: true } : {})}
            onClick={() => updateDraft()}
            icon={IconSrc.check}
            text={t('Create your character')}
            className="mx-auto"
          ></HornyButton>
        )}
        {generatedImages.length >= 4 && (
          <HornyButton
            {...{
              'data-anal': 'media_request',
              'data-anal-request_type': 'regenerate',
              'data-anal-media_type': 'image',
              'data-anal-is_own_bot': true,
              'data-anal-bot_id': null,
            }}
            height={HornyButtonHeight.Tall}
            onClick={() => callPicker()}
            {...(isCreating ? { disabled: true } : {})}
            icon={IconSrc.Reload}
            text={t('Regenerate photos')}
            className="mx-auto"
          >
            <AddBlock className="horny-bg-dark_half">
              <Price amount={prices!.Media.Photo} />
            </AddBlock>
          </HornyButton>
        )}

        {generatedImages.length >= 4 && (
          <div className="mx-auto cursor-pointer  fw-bold text-decoration-underline">
            <u onClick={onBack}>{t('Back')}</u>
          </div>
        )}
      </div>
    </div>
  );
}
