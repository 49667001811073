import { useState } from 'react';
import {
  SubscriptionProviderFlowProps,
  captureSubscriptionByTrafficJunky,
} from './SubscriptionProviderFlow';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { IconSrc, IconSize, Icon } from 'src/components/Shared/Icon/Icon';
import { useUserContext } from 'src/context/User.context';
import { UserAPI, UserAPIModel } from 'src/services/API/UserAPI';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useResourcesContext } from 'src/context/ResourcesContext';
import {
  addClickMagicPurchase,
  addTrafficJunkyTag,
} from 'src/context/AnalyticsContext/helpersDOM';
import { SubscriptionPricesConfig } from 'src/services/API/ResourcesAPI';
import { NutakuEnv, useNutakuContext } from 'src/context/Nutaku.Context';
import { Nutaku, nutakuCreatePaymentOptions } from 'src/services/Nutaku/Nutaku';
import {
  WindowMessageEvent,
  WindowMessagePaymentFail,
  WindowMessagePaymentSuccess,
} from 'src/services/Window/Window';
import { NutakuAPI } from 'src/services/API/NutakuAPI';
import { useTranslation } from 'react-i18next';

export type NutakuProviderFlowProps = {
  isYearly: boolean;
  subscriptionData: SubscriptionPricesConfig;
  selectedLevel: boolean;
};
export function NutakuProviderFlow({
  isYearly,
  subscriptionLevel,
  subscriptionData,
  selectedLevel,
}: SubscriptionProviderFlowProps & NutakuProviderFlowProps) {
  const { t } = useTranslation();
  const { prices } = useResourcesContext();

  const [refreshing, setRefreshing] = useState(false);
  const { setUser, user } = useUserContext();
  const { capture } = useAnalyticsContext();
  const { isNutaku, nutakuEnv, setIsPaymentFrame, isPaymentFrame } =
    useNutakuContext();

  const [listenerIsSet, setListenerIsSet] = useState(false);

  const handleNutakuMessage = async (event: MessageEvent<any>) => {
    const storedUser = JSON.parse(JSON.stringify({ ...user })) as UserAPIModel;

    const message = event.data;
    let parsedMessage:
      | WindowMessagePaymentSuccess
      | WindowMessagePaymentFail
      | undefined = undefined;
    try {
      parsedMessage = JSON.parse(message) as
        | WindowMessagePaymentSuccess
        | WindowMessagePaymentFail;
      console.log({ parsedMessage });
    } catch (e) {}
    if (parsedMessage && parsedMessage.event === WindowMessageEvent.Payment) {
      if (parsedMessage.isOk === false) {
        setIsPaymentFrame(false);
        // return resolve(false);
      } else {
        await NutakuAPI.completeNutakuPurchase(parsedMessage.data.nutakuId)
          .then(() =>
            UserAPI.get().then(async ({ data }) => {
              if (data.Subscription.Level && !storedUser.Subscription.Level) {
                captureSubscriptionByTrafficJunky(data.Subscription.Level);
                addClickMagicPurchase(data.Subscription.Level);
              }
              if (
                data.Subscription.Level !== null &&
                data.Subscription.Level !== storedUser?.Subscription.Level
              ) {
                // capture({
                //   event: 'subscribe_process',
                //   data: {
                //     state: 'Patreon_verified',
                //     level: props.subscriptionLevel,
                //     income: prices!.Subscriptions[props.subscriptionLevel].Price,
                //   },
                // });
                await addTrafficJunkyTag(
                  prices!.Subscriptions[subscriptionLevel].Price,
                  subscriptionLevel
                );
                // await UserAPI.sendRevenue(
                //   prices!.Subscriptions[props.subscriptionLevel].Price
                // );

                setUser(data);
                // setIsPaymentFrame(false);
              }
            })
          )
          .catch((e) => setIsPaymentFrame(false));
      }
    } else {
      setIsPaymentFrame(false);
    }
  };
  // useEffect(() => {

  //   return () => window.removeEventListener("message", handleNutakuMessage)
  // }, []);
  const nutakuPaymentOptions: nutakuCreatePaymentOptions = {
    itemName: subscriptionLevel,
    itemId: isYearly
      ? subscriptionData.NutakuItemIdYear!
      : subscriptionData.NutakuItemId!,
    itemPrice: isYearly
      ? subscriptionData.NutakuPriceYear!.toString()
      : subscriptionData.NutakuPrice!.toString(),
    itemImage: subscriptionData.NutakuImageUrl!,
    itemDescription: subscriptionData.NutakuItemDescription!,
  };
  const nutakuCreatePayment = () => {
    if (isPaymentFrame) {
      return;
    }
    setIsPaymentFrame(true);
    capture({
      event: 'subscribe_process',
      data: {
        state: 'Click_nutaku',
        level: subscriptionLevel,
        // income: prices!.Subscriptions[props.subscriptionLevel].Price,
      },
    });

    if (nutakuEnv === NutakuEnv.Android) {
      Nutaku.createPayment(nutakuPaymentOptions);
      return;
    }
    if (nutakuEnv === NutakuEnv.Web) {
      // if(listenerIsSet){
      //   return;
      // }

      // setListenerIsSet(true);
      window.addEventListener('message', (message) =>
        handleNutakuMessage(message).finally(() => {
          window.removeEventListener('message', handleNutakuMessage);
          setIsPaymentFrame(false);
        })
      );
      console.log('messaging');
      window.parent.postMessage(
        JSON.stringify({
          action: 'payment',
          data: {
            purchase: {
              productId: isYearly
                ? subscriptionData.NutakuItemIdYear
                : subscriptionData.NutakuItemId,
              nutakuCost: isYearly
                ? subscriptionData.NutakuPriceYear
                : subscriptionData.NutakuPrice,
            },
            nutakuData: {
              nutakuDescription: subscriptionData.NutakuItemDescription,
              nutakuName: subscriptionLevel,
              nutakuImageUrl: subscriptionData.NutakuImageUrl,
            },
          },
        }),
        '*'
      );
      console.log('message sent');
    }
  };

  return (
    <div
      className={`h-100 d-flex align-items-center justify-content-center flex-column ${selectedLevel ? 'pe-none' : ''}`}
    >
      <HornyButton
        onClick={() => nutakuCreatePayment()}
        {...(selectedLevel ? { disabled: true } : {})}
        bg={HornyButtonBG.Red}
        className="w-max-content"
        height={HornyButtonHeight.Medium}
      >
        {t('Month')}:
        <Icon src={IconSrc.NutakuCoin} size={IconSize.Small} />
        {isYearly
          ? Math.floor((subscriptionData.NutakuPriceYear || 0) / 12)
          : subscriptionData.NutakuPrice}
        {isYearly && (
          <div className="position-relative">
            <div className="opacity-75">{subscriptionData.NutakuPrice}</div>
            <div
              className="bg-white position-absolute"
              style={{
                height: 1,
                left: 0,
                top: 8,
                width: '110%',
                transform: 'rotate(-27deg)',
              }}
            />
          </div>
        )}
      </HornyButton>
      {isYearly && (
        <div className="opacity-75 mt-2">
          {t('You will be charged')}{' '}
          <Icon src={IconSrc.NutakuCoin} size={IconSize.Small} />{' '}
          {subscriptionData.NutakuPriceYear}
        </div>
      )}
    </div>
  );
}
