import { useTranslation } from 'react-i18next';
import { ReportCharacter } from 'src/components/Report/ReportCharacter';
import { Icon, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useModalContext } from 'src/context/Modal.Context';
import { ConnectionAPIModel } from 'src/services/API/ConnectionsAPI';

export type ChatNamesProps = Pick<
  ConnectionAPIModel,
  'Author' | 'Name' | 'BotId'
>;
export function ChatNames({ Name, Author, BotId }: ChatNamesProps) {
  const { t } = useTranslation();
  const { addModal, removeModal } = useModalContext();
  const onReportClick = () => {
    const modal = addModal({
      children: (
        <ReportCharacter
          Id={BotId}
          onReport={() => {
            removeModal(modal);
            //  setTimeout(() => setReportText('Report'), 3000);
          }}
        />
      ),
    });
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-start gap-3">
        <div className="text-start fs-2">{Name} </div>
        <Icon
          src={IconSrc.Report}
          onClick={() => onReportClick()}
          className="cursor-pointer"
        />
        {/* {user && user.MessagesLeft < Infinity && (
          <div>{user.MessagesLeft} free messages</div>
        )} */}
      </div>
      <div className="d-flex gap-1 align-items-center">
        {t('Author')}:{' '}
        <span className="opacity-75 d-flex me-2 ">@{Author}</span>
      </div>
    </>
  );
}
