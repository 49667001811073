import { motion } from 'framer-motion';
import { useState } from 'react';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { useUserContext } from 'src/context/User.context';
import { GetChatResponse } from 'src/services/API/ConnectionsAPI';
import {
  AnimParamInOutOpacityY100,
  AnimParamInOutOpacityYNeg100,
  TransSpringPopup,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import { underlineSelected } from 'src/shared/utils';
import { Chat } from '../Chat/Chat';
import { CommunityMedia } from '../Media/CommunityMedia/CommunityMedia';
import {
  DialogueMedia,
  MediaBlock,
} from '../Media/DialogueMedia/DialogueMedia';
import { Icon, IconSize, IconSrc } from '../Shared/Icon/Icon';
import { ClearChat } from './ClearChat';
import './index.css';
import { useTranslation } from 'react-i18next';
// import { useSearchParams } from 'react-router-dom';
// import { useSearchParams } from 'react-router-dom';
export enum DialogueTabToShow {
  Chat = 'chat',
  Media = 'media',
}

export type DialogueProps = {
  showMedia?: string;
  // chatId: string;
  // Name: string;
  // Author: string;
  // IsBotAuthor: boolean;
};

export function Dialogue({
  showMedia,
  ...chatResponse
}: GetChatResponse & DialogueProps) {
  const { t } = useTranslation();
  // const [searchParams, setSearchParams] = useSearchParams();
  const { capture } = useAnalyticsContext();

  const [selectedTab, setSelectedTab] = useState<DialogueTabToShow | null>(
    () => {
      if (showMedia) {
        return DialogueTabToShow.Media;
      }

      return DialogueTabToShow.Chat;
    }
  );
  const [selectedMediaBlock, setSelectedMediaBlock] =
    useState<MediaBlock | null>(() => {
      if (showMedia === 'community') {
        capture({
          event: 'page_open',
          data: {
            page_name: 'Media_community',
          },
        });
        return MediaBlock.Community;
      }
      return MediaBlock.Dialogue;
    });
  const [loaded, setLoaded] = useState<boolean>(false);
  const { user } = useUserContext();
  const { addModal } = useModalContext();
  //   const renderContent = {
  //     [TabToShow.Discover]: () => <CharacterCarousel onLoaded={() => setLoaded(true)} />,
  //     [TabToShow.Connections]: () => <Connections />
  // };

  const switchMediaBlock = (block: MediaBlock) => {
    capture({
      event: 'page_open',
      data: {
        page_name:
          block === MediaBlock.Community ? 'Media_community' : 'Media_my',
      },
    });
    setSelectedMediaBlock(null);
    setSelectedMediaBlock(block);
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
      className="position-relative m-auto h-100 "
      style={{
        background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${chatResponse.Chat.CoverUrl})  center 10% / cover no-repeat`,
      }}
    >
      <div className="dialogue_header position-absolute w-100 z-2 pe-none"></div>
      {/* <ProfileHeader />
        <Header /> */}

      {
        <div
          className="fw-bold d-grid justify-content-between gap-3 position-absolute  m-auto px-7  z-3 mt-10 pt-10 w-100 my-5"
          style={{ height: 52, gridTemplateColumns: 'repeat(3, 1fr)' }}
        >
          <div></div>
          <div className="d-flex gap-4 justify-content-center">
            <motion.div
              whileTap={{ scale: 0.8 }}
              onClick={() => {
                capture({
                  event: 'page_open',
                  data: {
                    page_name: 'Chat',
                  },
                });
                setSelectedTab(DialogueTabToShow.Chat);
              }}
              className="d-flex flex-column gap-1 cursor-pointer"
            >
              <div className="horny-text-shadow text-nowrap">{t('Chat')}</div>
              {selectedTab === DialogueTabToShow.Chat && underlineSelected}
            </motion.div>
            <motion.div
              whileTap={{ scale: 0.8 }}
              onClick={() => {
                capture({
                  event: 'page_open',
                  data: {
                    page_name: 'Media_my',
                  },
                });
                setSelectedTab(DialogueTabToShow.Media);
                // setTimeout(() => setSelectedTab(DialogueTabToShow.Media), 4000);
              }}
              className="d-flex flex-column gap-1 cursor-pointer"
            >
              <div className="horny-text-shadow text-nowrap">{t('Media')}</div>
              {selectedTab === DialogueTabToShow.Media && underlineSelected}
            </motion.div>
            {/* <div  onClick={() => setSelectedTab(TabToSelect.Connections)}>Connections</div> */}
          </div>
          {selectedTab === DialogueTabToShow.Chat ? (
            <motion.div
              whileTap={{ scale: 0.8 }}
              animate="in"
              initial="initial"
              // exit="out"
              variants={AnimParamInOutOpacityYNeg100}
              transition={TransTweenAnticipate03}
              className="d-flex gap-1 fw-regular  align-items-center justify-content-end cursor-pointer horny-text_tiny"
              onClick={() => {
                capture({
                  event: 'page_open',
                  data: {
                    page_name: 'Clear_chat',
                  },
                });
                addModal({
                  children: <ClearChat id={chatResponse.Chat.BotId} />,
                });
              }}
            >
              <Icon src={IconSrc.Clear} size={IconSize.XSmall} />
              <i className="text-nowrap">{t('clear chat')}</i>
            </motion.div>
          ) : (
            <div></div>
          )}
        </div>
      }
      <motion.div
        animate={selectedTab === DialogueTabToShow.Chat ? 'in' : 'out'}
        //initial="initial"
        // exit="out"
        variants={AnimParamInOutOpacityYNeg100}
        transition={TransTweenAnticipate03}
        className={`${selectedTab === DialogueTabToShow.Chat ? 'd-block h-100' : 'd-none'}`}
      >
        <Chat {...chatResponse} />
      </motion.div>
      <motion.div
        animate={selectedTab === DialogueTabToShow.Media ? 'in' : 'out'}
        //initial="initial"
        // exit="out"
        variants={AnimParamInOutOpacityYNeg100}
        transition={TransTweenAnticipate03}
        className={`${selectedTab === DialogueTabToShow.Media ? 'd-block h-100' : 'd-none'}`}
      >
        {/* <AnimatePresence mode = "wait"> */}
        {!selectedMediaBlock ? (
          <div></div>
        ) : selectedMediaBlock === MediaBlock.Dialogue ? (
          <DialogueMedia
            selectedMediaBlock={MediaBlock.Dialogue}
            botId={chatResponse.Chat.BotId}
            onSelectMediaBlock={(block) => switchMediaBlock(block)}
          />
        ) : (
          <CommunityMedia
            selectedMediaBlock={MediaBlock.Community}
            botId={chatResponse.Chat.BotId}
            onSelectMediaBlock={(block) => switchMediaBlock(block)}
          />
        )}
        {/* </AnimatePresence> */}
      </motion.div>
    </motion.div>
  );
}
