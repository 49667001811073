import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import { AddBlock } from 'src/components/Shared/AddBlock/AddBlock';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { Price } from 'src/components/Shared/Price/Price';
import { Range } from 'src/components/Shared/Range/Range';
import { useModalContext } from 'src/context/Modal.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import {
  CharacterAPI,
  CharacterAppearanceCategoryAPIModel,
  CharacterAppearanceControl,
  CharacterGenerationAppearance,
  CharacterGenerationDraftAPIModel,
  CharacterGenerationStage,
  ImageGenerationModelType,
} from 'src/services/API/CharacterAPI';
import { MediaRequestBase } from 'src/services/API/MediaAPI';
import { OnChoiceCallback } from '../CharacterGeneration';
import { IntroBlock } from '../Shared/IntroBlock';
import {
  TypePreview,
  TypePreviewSize,
  TypePreviewColor,
} from '../Shared/TypePreview/TypePreview';
import { checkState } from '../Shared/helpers';
import { PromptPicker } from './PromptPicker';
import './index.css';

export type ConfigureProps = {
  onChoice: OnChoiceCallback;
  onForward?: (() => any) | null;
  fetched: CharacterGenerationAppearance | null;
  onBack: () => any;
  isGenerated: boolean;
  model: ImageGenerationModelType;
  onFilter?: (key: string, value: string | null) => any;
};

export function ConfigureAppearance({
  onChoice,
  onForward,
  fetched,
  onBack,
  isGenerated,
  model,
  onFilter,
}: ConfigureProps) {
  const { t } = useTranslation();
  const fetchedRef = useRef<boolean>(false);
  const [appearanceCategories, setAppearanceCategories] = useState<
    CharacterAppearanceCategoryAPIModel[]
  >([]);
  const [appearance, setAppearance] = useState<CharacterGenerationAppearance>(
    fetched || {}
  );
  const [modalId, setModalId] = useState<number | null>(null);
  const { addModal, removeModal } = useModalContext();
  const [isFilled, setIsFilled] = useState(false);
  const { prices } = useResourcesContext();
  const [error, setError] = useState('');

  // const [modal, setModal] = useState<number | null>(null);
  useEffect(() => {
    setIsFilled(
      !!appearanceCategories.length &&
        appearanceCategories.length === Object.keys(appearance).length
    );
  }, [appearanceCategories, appearance]);

  const [updating, setUpdating] = useState(false);

  const updateDraft = (genParams: MediaRequestBase) => {
    if (!isFilled) {
      return;
    }
    setUpdating(true);
    const stage = CharacterGenerationStage.Appearance;
    const data: CharacterGenerationDraftAPIModel[typeof stage] = appearance;
    // console.log({ stage, data, genParams });
    onChoice(stage, data, genParams).finally(() => setUpdating(false));
  };

  const setValueFromControl = (category: string, prompt: string) => {
    setAppearance({
      ...appearance,
      [category]: prompt,
    });
    onFilter && onFilter(category, prompt);
  };

  useEffect(() => {
    if (!fetchedRef.current) {
      fetchedRef.current = true;
      CharacterAPI.getAppearanceParams()
        .then(({ data }) => {
          setAppearanceCategories(data);
          const appearanceToMerge = appearance;
          data
            .filter(
              ({ Control }) => Control === CharacterAppearanceControl.Range
            )
            .forEach(({ CategoryValue, Prompts }) => {
              appearanceToMerge[CategoryValue] =
                appearance[CategoryValue] || Prompts[1].PromptValue;
            });
          setAppearance(appearanceToMerge);
        })
        .finally(() => {
          // fetchedRef.current = false;
        });
    }
  });

  const callPicker = () => {
    // const appearanceConfig = Object.fromEntries(Object.entries(appearance).map(([key, val]) => {
    //   const title = appearanceCategories.find(c => c.CategoryValue === key)!.Title;
    //   return ['Please select ' + title, !!val];
    // }))
    const appearanceConfig: Record<string, boolean> = {};
    appearanceCategories.forEach((c) => {
      // const title = appearanceCategories.find(c => c.CategoryValue === key)!.Title;
      appearanceConfig[t('Please select') + ' "' + t(c.Title as any) + '"'] =
        !!!appearance[c.CategoryValue];
    });
    // const appearanceConfig = Object.fromEntries(appearanceCategories.map((c) => {
    //   // const title = appearanceCategories.find(c => c.CategoryValue === key)!.Title;
    //   return ['Please select ' + c.Title, !!appearance[c.CategoryValue]];
    // }));

    // console.log({ appearanceConfig, appearance });
    if (!checkState(appearanceConfig, (e) => setError(e))) {
      return;
    }
    const modal = addModal({
      children: (
        <PromptPicker
          description={t('Select a pose for character cover')}
          {...{
            isGenerated,
            multiplier: 1,
            // multiplier: 4,
            // categoryToFilterOut: 'Sex',
            model,
            // showNudity: true,
            onPick: (genParams) => {
              updateDraft(genParams);
              removeModal(modal);
            },
          }}
        />
      ),
    });
  };

  if (!appearanceCategories.length) {
    return null;
  }
  return (
    <div className="w-100 d-flex flex-column gap-2  h-100 text-start position-relative">
      {updating && <FullSizeLoader />}
      <div
        className={`overflow-y-auto horny-disable-scrollbar  ${!onFilter ? ' pt-20  horny-border-bottom pb-3' : 'pt-20 pb-20'}`}
      >
        {onFilter && <div className="pt-10"></div>}
        {onFilter && <div className="pt-3"></div>}
        <div className="d-flex flex-column gap-6">
          {!onFilter && (
            <IntroBlock
              onButtonClick={console.log}
              title={t('Appearance')}
              description={t('Appearance descr')}
            />
          )}

          {appearanceCategories.map(
            ({ CategoryValue, Title, Control, Prompts }) => {
              return (
                <div className="d-flex flex-column gap-2" key={CategoryValue}>
                  <div className="d-flex justify-content-between">
                    <div className="fs-5 fw-bold">{t(Title as any)}</div>
                    {onFilter && (
                      <div
                        className={`horny-text_tiny cursor-pointer ${!appearance[CategoryValue] ? 'pe-none opacity-50' : ''}`}
                        onClick={() => {
                          onFilter(CategoryValue, null);
                          setAppearance((prev) => {
                            delete prev[CategoryValue];
                            return prev;
                          });
                        }}
                      >
                        <Icon src={IconSrc.Clear} size={IconSize.XSmall} />
                        {t('Clear filter')}
                      </div>
                    )}
                  </div>
                  {Control === CharacterAppearanceControl.Range ? (
                    <Range
                      onChange={(val) =>
                        setValueFromControl(CategoryValue, val)
                      }
                      startPosition={
                        Prompts.findIndex(
                          (p) => appearance[CategoryValue] === p.PromptValue
                        ) || 0
                      }
                      values={Prompts}
                      model={model}
                    />
                  ) : (
                    <div className="justify-content-between">
                      {Prompts.map(({ PromptValue, Image, Title }) => (
                        <div
                          className="d-inline-block horny-valign-top me-3 mb-3 justify-content-center"
                          key={PromptValue}
                          //  style={{ width: '82px' }}
                          onClick={() => {
                            setValueFromControl(CategoryValue, PromptValue);
                            onFilter && onFilter(CategoryValue, PromptValue);
                          }}
                        >
                          <TypePreview
                            size={TypePreviewSize.Small}
                            src={Image[model]}
                            selected={appearance[CategoryValue] === PromptValue}
                            color={
                              onFilter
                                ? TypePreviewColor.Grey
                                : TypePreviewColor.Red
                            }
                          />
                          <div
                            className="text-center pt-2 horny-break-words"
                            style={{ width: '84px' }}
                          >
                            {t(Title as any)}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            }
          )}
        </div>
        {onFilter && <div className="pb-5"></div>}
      </div>
      {!onFilter && (
        <div className=" mx-auto  d-flex gap-4 flex-column align-items-end pb-4">
          {!!error ? (
            <div
              className={`text-center w-100 ${error ? 'scale-up-ver-bottom' : ''}`}
            >
              {error}
            </div>
          ) : (
            <div className="opacity-0">{t('error')}</div>
          )}

          {onForward && !onFilter && (
            <HornyButton
              {...(updating ? { disabled: true } : {})}
              height={HornyButtonHeight.Tall}
              onClick={() => onForward()}
              icon={IconSrc.Next}
              text={t('Back to generated photos')}
              className="mx-auto"
            ></HornyButton>
          )}

          {!onFilter && (
            <HornyButton
              height={HornyButtonHeight.Tall}
              {...(updating ? { disabled: true } : {})}
              onClick={() => callPicker()}
              icon={!isGenerated ? IconSrc.Next : IconSrc.Reload}
              text={`${updating ? t('Generating...') : !isGenerated ? t('Choose Pose for Cover') : t('Regenerate photos')}`}
              className="mx-auto"
            >
              {isGenerated && (
                <AddBlock className="horny-bg-dark_half">
                  <Price amount={prices!.Media.Photo} />
                </AddBlock>
              )}
            </HornyButton>
          )}

          {!onFilter && (
            <div className="mx-auto cursor-pointer  fw-bold text-decoration-underline">
              <u onClick={onBack}>{t('Back')}</u>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
