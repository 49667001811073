import { App as CapacitorApp } from '@capacitor/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Container } from 'react-bootstrap';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import { appMock } from './App.mock';
import { AnalyticsProvider } from './context/AnalyticsContext/AnalyticsContext';
import { ModalProvider } from './context/Modal.Context';
import { NotificationsProvider } from './context/Notifications.Context';
import { NutakuProvider } from './context/Nutaku.Context';
import { ResourceProvider } from './context/ResourcesContext';
import { UserProvider } from './context/User.context';
import { AxiosDecorator } from './decorators/Axios.Decorator';
import './i18n/config';
import './index.css';
import { router } from './routing/Router';
import './shared/style.css';

const queryClient = new QueryClient();

CapacitorApp.addListener('backButton', ({ canGoBack }) => {
  if (canGoBack) {
    window.history.back();
  }
});

appMock();

const isNutakuShare = process.env.REACT_APP_DOMAIN_TYPE === 'NutakuShare';
const appLayout = isNutakuShare ? '' : 'App';

function App() {
  return !isNutakuShare ? (
    <QueryClientProvider client={queryClient}>
      <Container className={`${appLayout} mx-auto p-0 h-100`} fluid>
        <NotificationsProvider>
          <NutakuProvider>
            <AxiosDecorator>
              <UserProvider>
                <ResourceProvider>
                  <AnalyticsProvider>
                    <ModalProvider>
                      <RouterProvider router={router} />
                    </ModalProvider>
                  </AnalyticsProvider>
                </ResourceProvider>
              </UserProvider>
            </AxiosDecorator>
          </NutakuProvider>
        </NotificationsProvider>
      </Container>
    </QueryClientProvider>
  ) : (
    <QueryClientProvider client={queryClient}>
      <Container className={`${appLayout} mx-auto p-0 h-100`} fluid>
        <NotificationsProvider>
          <AxiosDecorator>
            <AnalyticsProvider>
              <ModalProvider>
                <RouterProvider router={router} />
              </ModalProvider>
            </AnalyticsProvider>
          </AxiosDecorator>
        </NotificationsProvider>
      </Container>
    </QueryClientProvider>
  );
}

export default App;
