import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PostHogProvider } from 'posthog-js/react';
import { appConfig } from './config/app';
// import { AgreementDecorator } from './decorators/Agreement.Decorator';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <PostHogProvider
    apiKey={appConfig.postHog.apiKey}
    options={{
      api_host: appConfig.postHog.host,
      persistence: 'localStorage',
    }}
  >
    <div className="position-absolute opacity-0 pe-none">
      {appConfig.app.version}
    </div>
    {/* <AgreementDecorator> */}
    <App />
    {/* </AgreementDecorator> */}
  </PostHogProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.info);
