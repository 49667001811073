//import { AnimatePresence, motion } from 'framer-motion';
import { useUserContext } from 'src/context/User.context';
import { ImageGenerationModel } from 'src/services/API/CharacterAPI';
import {
  ChatMessageAPIModel,
  ChatMessageAuthor,
  ChatMessageStatus,
  MessageType as MessageTypeEnum,
  RequestMediaParams,
} from 'src/services/API/ConnectionsAPI';
import { MediaType } from 'src/services/API/MediaAPI';
import { replaceUserName } from 'src/shared/helpers';
import { formatText, splitTextLines } from 'src/shared/utils';
import { ChatMedia } from '../ChatMedia/ChatMedia';
import { Replay, ReplayContent } from './Replay/Replay';
import { UnlockMedia } from './UnlockMedia/UnlockMedia';
import './index.css';
import { useTranslation } from 'react-i18next';

type AlignClass = {
  [author in ChatMessageAuthor]: string;
};

export const contentAlign: AlignClass = {
  [ChatMessageAuthor.User]: 'end',
  [ChatMessageAuthor.Bot]: 'start',
  [ChatMessageAuthor.Server]: 'center',
};

export type ChatMessageProps = ChatMessageAPIModel & {
  canRegenerate: boolean;
  onScroll?: () => any;
  fetching?: boolean;
  onRegenerate: () => any;
  regenerateDisabled: boolean;
  onMediaRequest: () => any;
  IsBotAuthor: boolean;
  // requestRandomMedia: (media: MediaType) => any;
  requestRandomMedia: (
    data: Omit<Omit<RequestMediaParams, 'BotId'>, 'Model'>,
    mediaType: MediaType
  ) => any;
  model: ImageGenerationModel;
  mediaChoiseDisabled: boolean;
};

export const typingContent = (
  <div className="chat_message_dots">
    <div className="chat_message_dot"></div>
    <div className="chat_message_dot"></div>
    <div className="chat_message_dot"></div>
  </div>
);

export function ChatMessage({
  MessageSender,
  MessageType,
  Id,
  SkipLLM,
  Text = '',
  Media,
  MessageStatus,
  onRegenerate,
  regenerateDisabled,
  IsBotAuthor,
  requestRandomMedia,
  canRegenerate,
  mediaChoiseDisabled,
  model,
}: ChatMessageProps) {
  const { t } = useTranslation();
  const contentAlignClass = contentAlign[MessageSender];
  const { user } = useUserContext();
  const messageContent =
    MessageStatus === ChatMessageStatus.preparing
      ? typingContent
      : Text
        ? SkipLLM
          ? t(Text as any)
          : MessageSender === ChatMessageAuthor.Bot
            ? formatText(replaceUserName(Text, user!) || '')
            : splitTextLines(replaceUserName(Text, user!) || '')
        : null;

  return (
    <div
      className={`d-flex justify-content-${contentAlignClass} gap-2`}
      // key={MessageStatus}
      // initial="initial"
      // animate="in"
      // exit="out"
      // variants={animateParameters}
      // transition={animateTransitions}
    >
      <div
        className={`chat_message chat_message_${MessageSender} text-white d-flex flex-column`}
      >
        {messageContent}
        {MessageType !== MessageTypeEnum.Normal &&
          MessageSender == ChatMessageAuthor.Bot && (
            <UnlockMedia
            mediaChoiseDisabled={mediaChoiseDisabled}

              mediaType={MessageType}
              onClick={(mediaType) => {
                // !regenerateDisabled && showModal();
              }}
              requestRandomMedia={(data, type) =>
                requestRandomMedia(data, type)
              }
              IsBotAuthor={IsBotAuthor}
              BotId={Id}
              model={model}
            />
          )}
        {Media && <ChatMedia media={Media} className="" />}
      </div>

      {canRegenerate && (
        <Replay
          BotId={Id}
          isRegenerating={
            MessageStatus === ChatMessageStatus.pendingLink ||
            MessageStatus === ChatMessageStatus.preparing
          }
          onReplay={() => onRegenerate()}
          disabled={regenerateDisabled}
          IsBotAuthor={IsBotAuthor}
          content={Media ? ReplayContent.Media : ReplayContent.Text}
          {...(Media && Media[0].MediaType
            ? { mediaType: Media[0].MediaType }
            : {})}
        />
      )}
    </div>
  );
}

// export const getChatMessageActionElement = () => {
//     return (  )
// }
