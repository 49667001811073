import { motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useNutakuContext } from 'src/context/Nutaku.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import { Platform, SubscriptionLevel } from 'src/services/API/ResourcesAPI';
import {
  AnimParamInOutOpacityYNeg100,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import {
  Icon,
  IconSize,
  IconSrc,
  SubscriptionIcons,
} from '../Shared/Icon/Icon';
import { Timer } from '../Shared/Timer/Timer';
import { Subscription } from './Subscription';
import './index.css';

export enum ShowSubscriptionsReason {
  Messages = 'messages',
  Energy = 'energy',
  CommunityMedia = 'communityMedia',
}
export type SubscriptionsProps = {
  showReason?: ShowSubscriptionsReason;
  source?: string;
};

const subscriptionsEnergy = {
  [SubscriptionLevel.Friend]: 400,
  [SubscriptionLevel.Boyfriend]: 400,
  [SubscriptionLevel.Daddy]: 1200,
  [SubscriptionLevel.Boss]: Infinity,
};

/* @todo: Разделить на 2 компонента, если будет много различий Nutaku и Web */
export const subscriptionsOrder = [
  SubscriptionLevel.Friend,
  SubscriptionLevel.Boyfriend,
  SubscriptionLevel.Daddy,
  SubscriptionLevel.Boss,
];
export function Subscriptions({ showReason, source }: SubscriptionsProps) {
  const { t } = useTranslation();
  const nutakCotext = useNutakuContext();
  const [isYearly, setIsYearly] = useState(true);

  const isNutaku = Boolean(nutakCotext?.isNutaku);
  const { user } = useUserContext();

  const { prices } = useResourcesContext();
  const { capture } = useAnalyticsContext();
  const pathName = useMemo(
    () => window.location.pathname + window.location.search,
    []
  );

  const [showTimer] = useState(
    () => !!showReason && showReason !== ShowSubscriptionsReason.CommunityMedia
  );
  const timerText = {
    [ShowSubscriptionsReason.Messages]: (
      <span>
        {t('No free messages left reason', {
          messages: prices?.NoSubscription.Messages,
        })}{' '}
        {/* No free messages left. Up to {prices?.NoSubscription.Messages} in{' '} */}
      </span>
    ),
    [ShowSubscriptionsReason.Energy]: (
      <div className="d-flex align-items-center">
        {t('Too few energy')}.&nbsp;
        <Icon src={IconSrc.Energy} size={IconSize.XSmall} />
        {user?.Subscription.Level
          ? subscriptionsEnergy[user?.Subscription.Level]
          : prices?.NoSubscription.Energy}{' '}
        &nbsp; {t('in')}
      </div>
    ),
    [ShowSubscriptionsReason.CommunityMedia]: (
      <div className="d-flex align-items-center">
        {t('Subscribe to see community media')}
      </div>
    ),
  };

  const subscriptionLevelsToRender: SubscriptionLevel[] = user?.Subscription
    .Level
    ? []
    : (Object.keys(prices!.Subscriptions) as SubscriptionLevel[]);
  if (user?.Subscription.Level !== null) {
    Object.keys(prices!.Subscriptions).forEach((key) => {
      if (
        subscriptionsOrder.indexOf(key as SubscriptionLevel) >=
        subscriptionsOrder.indexOf(
          user?.Subscription.Level as SubscriptionLevel
        )
      ) {
        subscriptionLevelsToRender.push(key as SubscriptionLevel);
      }
    });
  }

  return (
    <div className="text-center w-100  p-0 main_container mx-auto h-100">
      <motion.div
        className="w-100 h-100  d-flex flex-column gap-6 text-start overflow-hidden"
        animate="in"
        initial="out"
        exit="out"
        variants={AnimParamInOutOpacityYNeg100}
        transition={TransTweenAnticipate03}
      >
        {/* <div style={{height: 60}}></div> */}
        <div className="w-100 h-100  d-flex flex-column gap-6 text-start overflow-y-auto pb-20 px-3 horny-disable-scrollbar">
          <div className="pt-20 d-flex flex-row gap-4 align-items-center justify-content-center">
            <Icon src={IconSrc.Premium} size={IconSize.XL} />
            <div className="fw-bold fs-1">{t('Premium')}</div>
            {/* <Image
              style={{ width: 200 }}
              onLoad={() =>
                capture({
                  event: 'page_open',
                  data: {
                    page_name: 'Subscriptions',
                    reason: showReason || source || 'none',
                  },
                })
              }
              onError={() =>
                capture({
                  event: 'page_open',
                  data: {
                    page_name: 'Subscriptions',
                    reason: showReason || source || 'none',
                  },
                })
              }
              src={
                getImgUrl('subscriptions/Premium.png?width=400')
                // appConfig.host.CDN + '/img/subscriptions/Premium.png?width=400'
              }
            /> */}
          </div>
          {user?.InviteSubscription.Level !== null && (
            <div className="d-flex flex-column gap-1 opacity-75">
              <div className="d-flex flex-row align-items-center gap-2 justify-content-center">
                <div>{t('You currently have active Invite Subscription')}:</div>
                <div className="d-flex flex-row align-items-center gap-2">
                  <Icon
                    src={
                      SubscriptionIcons[
                        user?.InviteSubscription
                          .Level as keyof typeof SubscriptionIcons
                      ]
                    }
                    size={IconSize.Small}
                  />
                  <div className="fw-bold">
                    {t(user?.InviteSubscription.Level as any)}
                  </div>
                </div>
              </div>
              <div className="text-center">
                {t('Invite Subscription ends')}{' '}
                <b>{String(user?.InviteSubscription.Ends).split('T')[0]}</b>
              </div>
              <div className="d-flex flex-row align-items-center gap-1 justify-content-center">
                <Icon src={IconSrc.Warning} size={IconSize.Small} />
                <div>{t('Buying regular subscription will override it.')}</div>
              </div>
            </div>
          )}
          {showReason && (
            <div className="d-flex justify-content-center gap-1">
              {timerText[showReason]}
              {showTimer && <Timer onTimeReach={console.log} />}
            </div>
          )}
          {subscriptionLevelsToRender
            .filter((key) =>
              isNutaku
                ? prices!.Subscriptions[
                    key as SubscriptionLevel
                  ].Platform?.includes(Platform.Nutaku)
                : prices!.Subscriptions[
                    key as SubscriptionLevel
                  ].Platform?.includes(Platform.Web)
            )
            .reverse()
            .map((key) => {
              return (
                <Subscription
                  key={key}
                  {...{
                    level: key as SubscriptionLevel,
                    isYearly: isYearly,
                    subscriptionData:
                      prices!.Subscriptions[key as SubscriptionLevel],
                    selectedLevel: user!.Subscription.Level === key,
                    selectedProvider: user!.Subscription.Provider,
                  }}
                />
              );
            })}
        </div>
        {isNutaku && (
          <div
            className="position-absolute w-100 justify-content-center z-50 d-flex"
            style={{ bottom: '5%' }}
          >
            <div className="d-inline-flex flex-row gap-3 horny-bg_grey rounded-3 ps-10 pe-10 pt-4 pb-4 horny-shadow-gray">
              <div className="fw-bold">{t('Yearly subscription')}:</div>
              <Form>
                <Form.Check
                  defaultChecked
                  type="switch"
                  id="custom-switch"
                  className="horny-toggle"
                  // className={isFetching ? 'pe-none opacity-75' : ''}
                  onChange={(e) => {
                    setIsYearly(e.target.checked);
                    console.log(isYearly);
                  }}
                />
              </Form>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
}
