import './index.css';
import { Icon, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { AddBlock } from 'src/components/Shared/AddBlock/AddBlock';
import { Price } from 'src/components/Shared/Price/Price';
import {
  MessageType,
  RequestMediaParams,
} from 'src/services/API/ConnectionsAPI';
import { MediaType } from 'src/services/API/MediaAPI';
import { PromptPicker } from 'src/components/CharacterGeneration/ConfigureAppearance/PromptPicker';
import { useModalContext } from 'src/context/Modal.Context';
import { ImageGenerationModel } from 'src/services/API/CharacterAPI';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export type UnlockMediaProps = {
  mediaType: MessageType;
  onClick: (mediaType: MediaType) => any;
  BotId: string;
  IsBotAuthor: boolean;
  requestRandomMedia: (
    data: Omit<Omit<RequestMediaParams, 'BotId'>, 'Model'>,
    mediaType: MediaType
  ) => any;
  model: ImageGenerationModel;
  mediaChoiseDisabled: boolean;
};

export function UnlockMedia({
  mediaType,
  onClick,
  BotId,
  IsBotAuthor,
  requestRandomMedia,
  model,
  mediaChoiseDisabled
}: UnlockMediaProps) {
  const { t } = useTranslation();
  const iconSrc = {
    [MessageType.RandomImage]: IconSrc.Photo,
    [MessageType.RandomVideo]: IconSrc.Video,
    [MessageType.RandomAudio]: IconSrc.Audio,
    [MessageType.Normal]: IconSrc.Audio,
  }[mediaType];

  const text = {
    [MessageType.RandomImage]: 'photo',
    [MessageType.RandomVideo]: 'video',
    [MessageType.RandomAudio]: 'audio',
    [MessageType.Normal]: '',
  }[mediaType];
  const { prices } = useResourcesContext();
  const mediaMatch = {
    [MessageType.RandomImage]: MediaType.Photo,
    [MessageType.RandomVideo]: MediaType.Video,
    [MessageType.RandomAudio]: MediaType.Audio,
    [MessageType.Normal]: MediaType.Audio,
  }[mediaType];
  const priceMatch = {
    [MessageType.RandomImage]: prices!.Media['Photo'],
    [MessageType.RandomVideo]: prices!.Media['Video'],
    [MessageType.RandomAudio]: prices!.Media['Audio'],
    [MessageType.Normal]: 0,
  }[mediaType];

  const { addModal, removeModal } = useModalContext();

  const showModal = () => {
    const modal = addModal({
      children: (
        <PromptPicker
          description={t('Select a propmpt for your media')}
          charGen={false}
          buttonText={`${t('Request')} ${t(mediaMatch as any)}`}
          multiplier={1}
          isGenerated={true}
          // showNudity={false}
          onPick={(params) => {
            requestRandomMedia(
              {
                // Place: mediaScenarioPlace,
                ...params,
              },
              mediaMatch
            );
            // setPromptParams(params);
            // onLevelDown([
            //   {
            //     sender: ChatMessageAuthor.User,
            //     content: params.title,
            //   },
            //   {
            //     sender: ChatMessageAuthor.Bot,
            //     content: 'Please select the place',
            //     emoji: '128540',
            //   },
            // ]);
            removeModal(modal);
          }}
          // multiplier={1}
          // isGenerated={false}
          model={model}
        />
      ),
    });
  };

  return (
    <motion.div
      className="unlock-media horny-bg-gradient_red rounded-3 d-flex justify-content-center align-items-center cursor-pointer horny-shadow"
      whileTap={{ scale: 0.8 }}
      // {...{
      //   'data-anal': 'media_request',
      //   'data-anal-request_type': 'random',
      //   'data-anal-media_type': mediaMatch,
      //   'data-anal-is_own_bot': IsBotAuthor,
      //   'data-anal-bot_id': BotId,
      // }}
      onClick={() => !mediaChoiseDisabled && showModal()}
    >
      <div className="d-flex flex-column gap-3 align-items-center">
        <Icon src={iconSrc} />
        <div className="text-center mx-1">
          {t('Unlock')} {t(text as any)}
        </div>
        <AddBlock>
          <Price amount={priceMatch} />
        </AddBlock>
      </div>
    </motion.div>
  );
}
