import { Range as ReactRange } from 'react-range';

import './index.css';
// import { useState } from 'react';
import { Icon, IconSize, IconSrc } from '../Icon/Icon';
import {
  CharacterAppearancePrompt,
  ImageGenerationModelType,
} from 'src/services/API/CharacterAPI';

export type RangeProps = {
  values: CharacterAppearancePrompt[];
  thumbSrc?: string;
  onChange?: (value: string) => any;
  startPosition: number;
  model: ImageGenerationModelType;
};
export function Range({ onChange, startPosition, values, model }: RangeProps) {
  // const [thumbPosition, setThumbPosition] = useState<number>(startPosition);

  const thumbSize = 80;
  // const thumbBackgroundXShift = 560 - (thumbPosition + 3) * thumbSize;
  // console.log({
  //   thumbPosition,
  //   values,
  //   slide: values[thumbPosition],
  // });
  return (
    <div className="range_wrapper d-flex align-items-center mx-auto">
      <ReactRange
        step={1}
        min={0}
        max={values.length - 1}
        values={[startPosition]}
        onChange={([changedPosition]) => {
          // console.log({changing: changedPosition})
          // setThumbPosition(changedPosition);
          onChange && onChange(values[changedPosition].PromptValue);
        }}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className="range_input position-relative horny-bg-gradient_pink rounded-pill d-flex justify-content-between align-items-center px-1"
            style={{
              ...props.style,
              height: '10px',
              width: '100%',
              backgroundColor: '#ccc',
            }}
          >
            {children}
            <Icon size={IconSize.Micro} src={IconSrc.Back} />
            <Icon
              size={IconSize.Micro}
              src={IconSrc.Back}
              className="rotate-180"
            />
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            className="rounded-3 border border-1 border-danger-subtle z-1 position-absolute border-2"
            style={{
              ...props.style,
              marginBottom: -70,
              height: `${thumbSize}px`,
              width: `${thumbSize}px`,
              //   backgroundColor: '#999',
              // backgroundPosition: `${thumbBackgroundXShift}px 0px`,
              backgroundImage: `url(${values[startPosition].Image[model]})`,
              backgroundSize: 'cover',
            }}
          >
            <div
              className="position-absolute w-100 text-center fw-bold"
              style={{ bottom: -20 }}
            >
              {values[startPosition].Title}
            </div>
          </div>
        )}
      />
    </div>
  );
}
