// import { ChevronDownIcon } from 'lucide-react'
import { Portal, Select } from '@ark-ui/react';
import { faUpDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

type Item = { label: string; value: string;}

type ArkSelectProps = {
  onChange: (e: string) => void;
  items: Item[];
  defaultValue: string;
};

export const ArkSelect = ({ onChange, items, defaultValue }: ArkSelectProps) => {
  const { t } = useTranslation();
  return (
    <Select.Root
      items={items}
      onValueChange={(e) => onChange(e.value[0])}
      defaultValue={[defaultValue]}
    >
      {/* <Select.Label>Framework</Select.Label> */}
      <Select.Control>
        <Select.Trigger className="tw-flex tw-flex-row tw-gap-4 tw-px-5 tw-py-2 tw-border tw-text-white tw-border-horny-gray-600 tw-rounded-md tw-bg-horny-gray-900 tw-font-extrabold tw-shadow tw-text-xl tw-w-60 tw-justify-between">
          <Select.ValueText defaultValue={items[0].label} defaultChecked={true} />
          <Select.Indicator>
            <FontAwesomeIcon icon={faUpDown} size="xs" />
          </Select.Indicator>
        </Select.Trigger>
        {/* <Select.ClearTrigger>Clear</Select.ClearTrigger> */}
      </Select.Control>
      <Portal>
        <Select.Positioner>
          <Select.Content className="tw-bg-horny-gray-900 tw-border tw-border-horny-gray-600 tw-rounded-md tw-shadow tw-px-5 tw-py-3 tw-w-60">
            <Select.ItemGroup className="tw-flex tw-flex-col tw-gap-4">
              {/* <Select.ItemGroupLabel>Frameworks</Select.ItemGroupLabel> */}
              {items.map((item) => (
                <Select.Item
                  key={item.label}
                  item={item}
                  className="tw-flex tw-flex-row tw-gap-4 tw-cursor-pointer tw-text-xl"
                >
                  <Select.ItemText>{item.label}</Select.ItemText>
                  <Select.ItemIndicator>✓</Select.ItemIndicator>
                </Select.Item>
              ))}
            </Select.ItemGroup>
          </Select.Content>
        </Select.Positioner>
      </Portal>
      <Select.HiddenSelect />
    </Select.Root>
  );
};
