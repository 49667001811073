import { createContext, useState, useContext, ReactElement } from 'react';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import { NutakuAPI } from 'src/services/API/NutakuAPI';
import { nutakuPaymentEvent } from 'src/services/Nutaku/Nutaku';
import { useNotificationsContext } from './Notifications.Context';
import { AxiosError } from 'axios';
import { NotificationType } from 'src/components/Layout/Notification/Notification';
import { useUserContext } from './User.context';
import { appConfig } from 'src/config/app';

export enum NutakuEnv {
  Android = 'Android',
  Web = 'Web',
  SP = 'SP',
}
export type NutakuContextProps = {
  isNutaku: boolean | undefined;
  nutakuEnv: NutakuEnv | null;
  isPaymentFrame: boolean;
  setIsPaymentFrame: (value: boolean) => void;
  onCompletePayment: (event: nutakuPaymentEvent) => Promise<any>;
  onDestroyPayment: () => void;
  // analytics: PostHog | null;
};

export const NutakuContext = createContext<NutakuContextProps>({
  isNutaku: undefined, // !!process.env.NUTAKU_ENV,
  nutakuEnv: null, // process.env.NUTAKU_ENV as NutakuEnv || null,
  isPaymentFrame: false,
  setIsPaymentFrame: () => {},
  onCompletePayment: async () => {},
  onDestroyPayment: () => {},
});

export const NutakuContextProvider = NutakuContext.Provider;
export const useNutakuContext = () =>
  useContext<NutakuContextProps>(NutakuContext);
let a = 1;
export const NutakuProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const { setUser, user } = useUserContext();
  const { addNotification } = useNotificationsContext();
  const [isPaymentFrame, setIsPaymentFrame] = useState(false);
  // const [isPaymentError, setIsPaymentError] = useState(false);
  const [isNutaku, setIsNutaku] = useState(() => appConfig.nutaku.isNutaku);

  const [nutakuEnv, setNutakuEnv] = useState(() => {
    return appConfig.nutaku.nutakuEnv;
  });

  // onCreatePayment -> ничего // When item purchase screen appears.
  // onDestroyPayment" -> убрать лоудер // When item purchase screen disappears.
  // onCompletePayment" -> начислить // When clicking on Purchase in item purchase screen.
  // onCancelPayment" -> ничего // When clicking on Close or "x" in top right corner in item purchase screen.
  // onErrorPayment" -> ничего? В окне юзер и так увидит ошибку? // When an error appears in item purchase screen.

  // Nutaku.addListener('onCompletePayment', (event))
  const onDestroyPayment = () => {
    setIsPaymentFrame(false);
  };

  const onCompletePayment = (event: nutakuPaymentEvent) => {
    console.log('onCompletePayment: ', event.__RESULT);
    return NutakuAPI.completeNutakuPurchase(event.__RESULT!).catch(
      (error: AxiosError) => {
        addNotification({
          message: error.message,
          type: NotificationType.Error,
          timestamp: Date.now(),
        });
      }
    );
  };

  return (
    <NutakuContextProvider
      value={{
        isNutaku,
        nutakuEnv,
        isPaymentFrame,
        setIsPaymentFrame,
        onCompletePayment,
        onDestroyPayment,
      }}
    >
      {isPaymentFrame && <FullSizeLoader />}
      {children}
    </NutakuContextProvider>
  );
};
