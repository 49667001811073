import { appConfig } from 'src/config/app';
import { SubscriptionLevel } from 'src/services/API/ResourcesAPI';
const prices = {
  [SubscriptionLevel.Friend]: '4.00',
  [SubscriptionLevel.Boyfriend]: '15.00',
  [SubscriptionLevel.Daddy]: '20.00',
  [SubscriptionLevel.Boss]: '40.00',
};
export const addTrafficJunkyTag = async (
  value: number,
  level: SubscriptionLevel
) => {
  return new Promise((res) => res(true));

  const id = {
    [SubscriptionLevel.Friend]: '1000469601',
    [SubscriptionLevel.Boyfriend]: '1000462131',
    [SubscriptionLevel.Daddy]: '1000462141',
    [SubscriptionLevel.Boss]: '1000462151',
  }[level];
  return new Promise((res) => {
    const container = document.getElementById('stuff');
    const image = document.createElement('img');
    image.id = id + '_cpa_testing';
    image.style.width = '1px';
    image.style.height = '1px';
    container?.appendChild(image);

    image.src = `https://ads.trafficjunky.net/ct?a=${id}&member_id=1006336301&cb=${Date.now()}&cti=${Date.now()}&ctv=${value}&ctd=${level}`;
    image.onload = () => res(true);
    image.onerror = () => res(true);
  });
};

export const addTrafficJunkyReg = async (id: string) => {
  return new Promise((res) => res(true));

  return new Promise((res) => {
    const container = document.getElementById('stuff');
    const image = document.createElement('img');
    image.id = 1000462111 + '_cpa_testing';
    image.style.width = '1px';
    image.style.height = '1px';
    container?.appendChild(image);

    image.src = `https://ads.trafficjunky.net/ct?a=${1000462111}&member_id=1006336301&cb=${Date.now()}&cti=${Date.now()}&ctv=${id}&ctd=${'Reg'}`;
    image.onload = () => res(true);
    image.onerror = () => res(true);
  });
};

export const addTrafficStarTag = async () => {
  return new Promise((res) => res(true));
  return new Promise((res) => {
    const container = document.getElementById('stuff');
    const image = document.createElement('img');
    image.style.width = '1px';
    image.style.height = '1px';
    container?.appendChild(image);
    // setTimeout(() => {
    image.src = 'https://tsyndicate.com/api/v2/cpa/132927/pixel.gif';
    // console.log('append');
    // }, 100);

    image.onload = () => res(true);
    image.onerror = () => res(true);
  });
};

export const addClickMagicReg = async () => {
  if (appConfig.nutaku.isNutaku) {
    return true;
  }
  return new Promise((res) => {
    //@ts-ignore
    window.clickmagick_cmc = {
      uid: '196900',
      hid: '2525246420',
      cmc_project: 'Temptations AI',
      cmc_goal: 'a',
      cmc_ref: 'optin',
      vid_info: 'on',
    };
    reappendClickMagic();
    res(true);
  });
};

export const addClickMagicPurchase = async (level: SubscriptionLevel) => {
  if (appConfig.nutaku.isNutaku) {
    return true;
  }
  return new Promise((res) => {
    //@ts-ignore
    window.clickmagick_cmc = {
      uid: '196900',
      hid: '2525246420',
      cmc_project: 'Temptations AI',
      cmc_goal: 's',
      cmc_ref: 'sale',
      vid_info: 'on',
      cmc_amt: prices[level],
    };
    reappendClickMagic();
    res(true);
  });
};

const reappendClickMagic = () => {
  const script = document.createElement('script');
  script.src = 'https://cdn.clkmc.com/cmc.js';
  document.head.append(script);
};
