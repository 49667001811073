import { AxiosError, AxiosResponse } from 'axios';
import { ReactElement, useEffect, useRef } from 'react';
import { NotificationType } from 'src/components/Layout/Notification/Notification';
import { getAxiosInstance } from 'src/services/axios/axios';
import { useNotificationsContext } from '../context/Notifications.Context';
// import { getSupabaseClient } from 'src/services/supabase/supabase.client';

type AxiosDecoratorProps = {
  children: ReactElement | ReactElement[];
};
const axiosInstance = getAxiosInstance();
export function AxiosDecorator({ children }: AxiosDecoratorProps) {
  const { addNotification } = useNotificationsContext();
  const responseCatcherIsSetUp = useRef(false);
  useEffect(() => {
    if (!responseCatcherIsSetUp.current) {
      responseCatcherIsSetUp.current = true;
      axiosInstance.interceptors.response.use(
        (res: AxiosResponse<any>) => res,
        (error: AxiosError) => {
          Number(error.response?.status) >= 500 &&
            error.config?.url !== 'SignUpAnonUser' &&
            addNotification({
              message: `error: ${error.message} \n
                code: ${error.code} \n
                response: ${error.response?.data} \n
                `,
              type: NotificationType.Error,
              timestamp: Date.now(),
            });

          if (
            Number(error.response?.status) === 401 &&
            !window.location.pathname.includes('patreon')
          ) {
            console.error('401 here');
            //  return window.location.reload();
          }

          throw error;
        }
      );
    }
  }, []);
  // const supaBaseClient = getSupabaseClient();

  return <>{children}</>;
}
