import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { appConfig } from 'src/config/app';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import {
  addClickMagicPurchase,
  addTrafficJunkyTag,
} from 'src/context/AnalyticsContext/helpersDOM';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import { UserAPI, UserAPIModel } from 'src/services/API/UserAPI';
import {
  SubscriptionProviderFlowProps,
  SubscriptionProviderFlowStage,
} from './SubscriptionProviderFlow';

export type MockCardPatreon = {
  mockCard: boolean;
};
export function PatreonProviderFlow(
  props: SubscriptionProviderFlowProps & MockCardPatreon
) {
  const { t } = useTranslation();
  const name = props.mockCard ? 'card' : 'patreon';
  const iconSrc = props.mockCard ? IconSrc.Card : IconSrc.Patreon;
  const [stage, setStage] = useState<SubscriptionProviderFlowStage>(
    props.selectedProvider
      ? SubscriptionProviderFlowStage.ReadyToUpdate
      : SubscriptionProviderFlowStage.Init
  );
  const storedSelected = useMemo(() => {
    return props.selectedProvider;
  }, [props.selectedProvider]);

  useEffect(() => {
    if (
      props.selectedProvider === true &&
      !storedSelected &&
      stage === SubscriptionProviderFlowStage.StartedPayment
    ) {
      setStage(SubscriptionProviderFlowStage.ReadyToUpdate);
    }
  }, [props.selectedProvider]);
  const { prices } = useResourcesContext();

  const [refreshing, setRefreshing] = useState(false);
  const { setUser, user } = useUserContext();
  const { capture } = useAnalyticsContext();
  const refresh = () => {
    if (refreshing) {
      return false;
    }
    setRefreshing(true);
    const storedUser = JSON.parse(JSON.stringify({ ...user })) as UserAPIModel;
    UserAPI.get().then(async ({ data }) => {
      if (data.Subscription.Level && !storedUser.Subscription.Level) {
        // captureSubscriptionByTrafficJunky(data.Subscription.Level);
        try {
          addClickMagicPurchase(data.Subscription.Level);
        } catch (e) {}
      }
      if (
        data.Subscription.Level !== null &&
        data.Subscription.Level !== storedUser?.Subscription.Level
      ) {
        // capture({
        //   event: 'subscribe_process',
        //   data: {
        //     state: 'Patreon_verified',
        //     level: props.subscriptionLevel,
        //     income: prices!.Subscriptions[props.subscriptionLevel].Price,
        //   },
        // });
        await addTrafficJunkyTag(
          prices!.Subscriptions[props.subscriptionLevel].Price,
          props.subscriptionLevel
        );
        // await UserAPI.sendRevenue(
        //   prices!.Subscriptions[props.subscriptionLevel].Price
        // );

        setUser(data);
        // captureMyTracker({
        //   type: "reachGoal",
        //   goal: "customEvent",
        //   value: prices?.Subscriptions[props.subscriptionLevel].Price,
        // });
      }
      setRefreshing(false);
    });
  };

  const stageElement = {
    [SubscriptionProviderFlowStage.Init]: (
      <>
        <HornyButton
          // data-anal="subscribe_process"
          // data-anal-state="Click_patreon"
          // data-anal-level={props.subscriptionLevel}
          className="w-max-content"
          onClick={() => {
            capture({
              event: 'subscribe_process',
              data: {
                state: 'Click_' + name,
                level: props.subscriptionLevel,
                // income: prices!.Subscriptions[props.subscriptionLevel].Price,
              },
            });
            window.open(
              'https://www.patreon.com/checkout/TemptationsAI?rid=' +
                props.subscriptionId,
              '_blank'
            );
            setStage(SubscriptionProviderFlowStage.StartedPayment);
          }}
          bg={HornyButtonBG.Red}
          height={HornyButtonHeight.Shorter}
        >
          <Icon src={iconSrc} size={IconSize.Small} />
          <div className="text-nowrap">
            {props.mockCard
              ? t('Subscribe with card')
              : t('Subscribe with Patreon')}
          </div>
        </HornyButton>

        <HornyButton
          // data-anal="subscribe_process"
          // data-anal-state="Click_verify_patreon"
          // data-anal-level={props.subscriptionLevel}
          className="w-max-content"
          onClick={() => {
            capture({
              event: 'subscribe_process',
              data: {
                state: 'Click_verify_' + name,
                level: props.subscriptionLevel,
                // income: prices!.Subscriptions[props.subscriptionLevel].Price,
              },
            });
            window.open(
              `https://patreon.com/oauth2/authorize?response_type=code&scope=identity&client_id=${appConfig.subscription.patreon.clientId}&redirect_uri=${window.location.origin}/patreon&state=their_session_id`,
              '_blank'
            );
            setStage(SubscriptionProviderFlowStage.StartedVerification);
          }}
          bg={HornyButtonBG.Red}
          height={HornyButtonHeight.Shorter}
        >
          <Icon src={iconSrc} size={IconSize.Small} />
          <div className="text-nowrap">{t('Already subscribed')}</div>
        </HornyButton>
      </>
    ),

    [SubscriptionProviderFlowStage.StartedPayment]: (
      <HornyButton
        // data-anal="subscribe_process"
        // data-anal-state="Click_verify_patreon"
        // data-anal-level={props.subscriptionLevel}
        className="w-max-content"
        onClick={() => {
          capture({
            event: 'subscribe_process',
            data: {
              state: 'Click_verify_' + name,
              level: props.subscriptionLevel,
              // income: prices!.Subscriptions[props.subscriptionLevel].Price,
            },
          });
          window.open(
            `https://patreon.com/oauth2/authorize?response_type=code&scope=identity&client_id=${appConfig.subscription.patreon.clientId}&redirect_uri=${window.location.origin}/patreon&state=their_session_id`,
            '_blank'
          );
          setStage(SubscriptionProviderFlowStage.StartedVerification);
        }}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
      >
        <Icon src={iconSrc} size={IconSize.Small} />
        <div className="text-nowrap">{t('Verify subscription')}</div>
      </HornyButton>
    ),
    [SubscriptionProviderFlowStage.StartedVerification]: (
      <HornyButton
        // data-anal="subscribe_process"
        // data-anal-state="Click_refresh_patreon"
        // data-anal-level={props.subscriptionLevel}
        className="w-max-content"
        onClick={() => {
          capture({
            event: 'subscribe_process',
            data: {
              state: 'Click_refresh_' + name,
              level: props.subscriptionLevel,
              // income: prices!.Subscriptions[props.subscriptionLevel].Price,
            },
          });
          refresh();
        }}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
      >
        <Icon
          src={IconSrc.Reload}
          size={IconSize.Small}
          className={`${refreshing ? 'horny-spin' : ''}`}
        />
        <div className="text-nowrap">{t('Refresh')}</div>
      </HornyButton>
    ),
    [SubscriptionProviderFlowStage.ReadyToUpdate]: (
      <HornyButton
        // data-anal="subscribe_process"
        // data-anal-state="Click_upgrade_patreon"
        // data-anal-level={props.subscriptionLevel}
        className="w-max-content"
        onClick={() => {
          capture({
            event: 'subscribe_process',
            data: {
              state: 'Click_upgrade_' + name,
              level: props.subscriptionLevel,
              // income: prices!.Subscriptions[props.subscriptionLevel].Price,
            },
          });
          window.open(
            'https://www.patreon.com/checkout/TemptationsAI?rid=' +
              props.subscriptionId,
            '_blank'
          );
          setStage(SubscriptionProviderFlowStage.StartedVerification);
        }}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
      >
        <Icon src={iconSrc} size={IconSize.Small} />
        <div className="text-nowrap">{t('Upgrade')}</div>
      </HornyButton>
    ),
  };
  return stageElement[stage];
}
