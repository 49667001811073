import { Image } from 'react-bootstrap';
import './index.css';
import { useModalContext } from 'src/context/Modal.Context';
import { MediaAPI } from 'src/services/API/MediaAPI';
import { MediaDisplayPhoto } from 'src/components/Media/Shared/MediaDisplay/MediaDisplayPhoto';

export type ChatMediaPhotoProps = {
  src: string;
  MediaId: string;
};
export function ChatMediaPhoto({ src, MediaId }: ChatMediaPhotoProps) {
  const { addModal } = useModalContext();
  return (
    <Image
      src={src}
      className="chat_media_photo horny-border_3 mw-100 h-100"
      onClick={() =>
        MediaAPI.getSingleMedia({ MediaId }).then(({ data }) =>
          addModal({
            children: (
              <div className="d-flex h-100 w-100  justify-content-center align-items-center align-content-center">
                {' '}
                <MediaDisplayPhoto {...data} />{' '}
              </div>
            ),
            className: 'horny-bg-dark_opac',
            bg: '',
          })
        )
      }
    />
  );
}
