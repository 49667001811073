import { Image } from 'react-bootstrap';
import './index.css';
import { useModalContext } from 'src/context/Modal.Context';
import { MediaAPI, MediaType } from 'src/services/API/MediaAPI';
import { errorImageSrc, getMediaDisplay } from 'src/shared/utils';
import { useRef, useState } from 'react';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';

export type ChatMediaPreviewProps = {
  PreviewUrl: string;
  MediaId: string;
  MediaType: MediaType;
};
export function ChatMediaPreview({
  PreviewUrl,
  MediaId,
  MediaType: type,
}: ChatMediaPreviewProps) {
  const { addModal } = useModalContext();

  const [src, setSrc] = useState(() => PreviewUrl + '?height=512');

  const [loaded, setLoaded] = useState(false);
  // const preview = {
  //     [MediaType.Photo]: PreviewUrl,
  //     [MediaType.Video]: '/chat/media/video/cover.jpg',
  //     [MediaType.Audio]: '/chat/media/audio/cover.png',
  // }[type];
  //   const display = {
  //     [MediaType.Photo]: (data: MediaAPIModel) => <MediaDisplayPhoto {...data} />,
  //     [MediaType.Video]: (data: MediaAPIModel) => (
  //       <MediaDisplayVideoAudio {...data} />
  //     ),
  //     [MediaType.Audio]: (data: MediaAPIModel) => (
  //       <MediaDisplayVideoAudio {...data} />
  //     ),
  //   }[type];
  const imageRef = useRef<HTMLImageElement | null>(null);
  const { capture } = useAnalyticsContext();

  return (
    <Image
      src={src}
      style={{
        width: src === errorImageSrc ? 200 : 200,
      }}
      // style={{
      //   height: loaded ? 'auto' : imageRef.current? imageRef.current.parentElement!.clientWidth * 1.875 : 'auto'
      // }}
      ref={imageRef}
      onLoad={() => setLoaded(true)}
      onError={() => setSrc(errorImageSrc)}
      className="chat_media_photo horny-border_3 mw-100 cursor-pointer rounded-3"
      onClick={() =>
        MediaAPI.getSingleMedia({ MediaId }).then(({ data }) => {
          capture({
            event: 'page_open',
            data: {
              page_name: 'Media_fullscreen',
              source: 'media',
              bot_id: data.BotId,
            },
          });
          addModal({
            children: (
              <div className="d-flex h-100 w-100  justify-content-center align-items-center align-content-center">
                {' '}
                {getMediaDisplay(data, type)(data)}{' '}
              </div>
            ),
            className: 'horny-bg-dark_opac',
            bg: '',
          });
        })
      }
    />
  );
}
