import { useTranslation } from 'react-i18next';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { Timer } from 'src/components/Shared/Timer/Timer';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';

export function LeftMessagesCount() {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { prices } = useResourcesContext();
  let messagesToRefill: number;
  if (!user?.MergedSubscription.Level) {
    messagesToRefill = Number(prices?.NoSubscription.Messages);
  } else {
    const subscription = prices?.Subscriptions[user.MergedSubscription.Level];
    messagesToRefill = Number(subscription?.Messages);
  }
  if (
    messagesToRefill === Infinity ||
    user?.MergedSubscription.Level !== null ||
    messagesToRefill <= Number(user!.MessagesLeft)
  ) {
    return null;
  }
  return user!.MessagesLeft > 0 ? (
    <div className="d-flex align-items-center horny-text_tiny gap-1">
      {user!.MessagesLeft} {t('free')}{' '}
      <Icon src={IconSrc.Chat} size={IconSize.Tiny} />
      {t('left')}.
    </div>
  ) : user.IsAnonymous ? (
    <div className="d-flex align-items-center horny-text_tiny gap-1">
      {t('Sign up to get more')}{' '}
      <Icon src={IconSrc.Chat} size={IconSize.Tiny} />
    </div>
  ) : (
    <div className="d-flex align-items-center horny-text_tiny gap-1">
      + {messagesToRefill} <Icon src={IconSrc.Chat} size={IconSize.Tiny} />{' '}
      {t('in')}
      <Timer onTimeReach={console.log} />
    </div>
  );
}
