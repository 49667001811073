import { AnimatePresence, motion } from 'framer-motion';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import {
  ChatMessageAuthor,
  ChatMessageStatus,
  GetChatResponse,
  MessageType as MessageTypeEnum,
} from 'src/services/API/ConnectionsAPI';
import { ChatMessage, typingContent } from './ChatMessage/ChatMessage';
import { Interaction } from './Interaction/Interaction';
import { LocalMessageProps } from './LocalMessage/LocalMessage';
import './index.css';
import useKeepScrollPosition from './useKeepScrollPosition';
import useMessages from './useMessages';
// import { InteractionContainer } from "./Interaction/InteractionContainer";
export enum ChatStatus {
  Init = 'init',
  Idle = 'idle',
  Waiting = 'waiting',
  WaitingText = 'waitingText',
  Loading = 'loading',
}

export type ChatProps = {
  chatId: string;
  Name: string;
  Author: string;
  IsBotAuthor: boolean;
};

export enum InteractionType {
  Text = 'text',
  Media = 'media',
}

export function Chat(chatResponse: GetChatResponse) {
  const [init, setInit] = useState(false);

  const {
    messages,
    setLastMessageRef,
    requestTextMessage,
    total,
    status,
    requestMedia,
    mediaInProgress,
    userChoiceIsReady,
    userResponses,
    mediaIsPending,
    regenerateMedia,
    regenerateText,
    requestRandomMedia,
    textIsPending,
  } = useMessages(chatResponse);
  const { containerRef } = useKeepScrollPosition([messages]);
  const interactionRef = useRef(null);
  const observerRef = useRef<ResizeObserver | null>(null);
  const [interactionHeight, setInteractionHeight] = useState(0);

  const [localMessages, setLocalMessages] = useState<LocalMessageProps[]>([]);
  const [interactionType, setInterationType] = useState<InteractionType>(
    InteractionType.Text
  );

  // const { analytics } = useUserContext();
  const { capture } = useAnalyticsContext();

  // const scrollDown = () => {
  //   (containerRef.current as unknown as HTMLElement).scrollTo(0, 1000000000000);
  // }

  useEffect(() => {
    // if (!observerRef.current && interactionRef.current ){
    const observer = new ResizeObserver(function (entries) {
      // since we are observing only a single element, so we access the first element in entries array
      let rect = entries[0].contentRect;

      // console.log({
      //   rect,
      //   entries,
      //   height: rect.height,
      // });

      // if (rect.height === 0){
      //   observerRef.current!.unobserve(interactionRef.current!)
      // }
      setInteractionHeight(rect.height);
      scrollChatToBottom();
    });

    observer.observe(interactionRef.current!);

    return () => {
      if (interactionRef.current) {
        observer.unobserve(interactionRef.current);
      } else {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    setTimeout(() => scrollChatToBottom(), 500);
  }, [messages.length]);
  // useEffect(() => {
  //   if (!init && containerRef.current && messages) {
  //     // console.log(containerRef.current);
  //     scrollChatToBottom();
  //     setInit(true);
  //   }
  // }, [init, messages]);

  const scrollChatToBottom = () => {
    setTimeout(() => {
      (containerRef.current as unknown as HTMLElement).scrollTo(0, 10000000000);
    }, 75);
  };

  const loadingBlock = (
    <div className={`d-flex justify-content-center`} key={'loading'}>
      <div
        className={`chat_message chat_message_notice text-white d-flex flex-column`}
      >
        {typingContent}
      </div>
    </div>
  );

  const welcomeBlock = (
    <div className={`d-flex justify-content-center`} key="welcome">
      {/* <div
        className={`chat_message chat_message_notice text-white d-flex flex-column`}
      >
        Hello and welcome. Be sure you're safe.
      </div> */}
    </div>
  );

  const topBlock =
    status === ChatStatus.Loading ? [loadingBlock] : [welcomeBlock];

  const lastIndexToRegenerateMedia = [...messages]
    .reverse()
    .findIndex(
      ({ MessageSender, MessageType, Media, MessageStatus, SkipLLM }) => {
        return (
          MessageSender === ChatMessageAuthor.Bot &&
          MessageType === MessageTypeEnum.Normal &&
          Media &&
          MessageStatus === ChatMessageStatus.ready
        );
      }
    );
  const lastIndexToRegenerateText = [...messages]
    .reverse()
    .findIndex(
      ({ MessageSender, MessageType, Media, MessageStatus, SkipLLM }) => {
        return (
          MessageSender === ChatMessageAuthor.Bot &&
          MessageType === MessageTypeEnum.Normal &&
          !Media &&
          !SkipLLM &&
          MessageStatus === ChatMessageStatus.ready
        );
      }
    );
  return (
    <div className="position-relative h-100">
      <div
        className="chat position-relative px-2 horny-disable-scrollbar"
        style={{ paddingBottom: interactionHeight + 20 }}
        ref={containerRef}
      >
        {/* {topBlock} */}
        <AnimatePresence>
          {[...messages].map((m, i) => (
            <motion.div
              key={m.Id}
              layout
              transition={{ layout: { duration: 0.2 } }}
            >
              <div
                ref={(ref) =>
                  i === 5 && messages.length < total
                    ? setLastMessageRef(ref as SetStateAction<any>)
                    : null
                }
              >
                <ChatMessage
                  model={chatResponse.Chat.ImageGenerationModel}
                  mediaChoiseDisabled={!!mediaInProgress || mediaInProgress === null}
                  canRegenerate={
                    (i === messages.length - 1 - lastIndexToRegenerateMedia ||
                      i === messages.length - 1 - lastIndexToRegenerateText) &&
                    !textIsPending.current
                  }
                  {...m}
                  // requestRandomMedia={(mediaType) => {
                  //   !mediaInProgress &&  requestMedia() ;//requestRandomMedia(m.Id, mediaType);
                  // }}
                  requestRandomMedia={(data, type) =>
                    requestMedia(
                      {
                        ...data,
                        BotId: chatResponse.Chat.BotId,
                        // Model: chatResponse.Chat.ImageGenerationModel
                      },
                      type,
                      true
                    )
                  }
                  IsBotAuthor={chatResponse.Chat.IsBotAuthor}
                  onMediaRequest={() => {}}
                  onRegenerate={() => {
                    const { Media } = m;
                    if (Media) {
                      const { MediaType: type } = Media[0];
                      return regenerateMedia(
                        {
                          BotId: chatResponse.Chat.BotId,
                          MessageId: m.Id,
                        },
                        type
                      );
                    } else {
                      return regenerateText({
                        BotId: chatResponse.Chat.BotId,
                        MessageId: m.Id,
                      });
                    }
                  }}
                  regenerateDisabled={
                    (m.Media && mediaIsPending.current) ||
                    (!m.Media && status === ChatStatus.WaitingText)
                  }
                />
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
        {/* {localMessages.map((lM) => (
            <LocalMessage {...lM} key={lM.content} />
          ))} */}
      </div>
      <div
        className="chat_interaction position-absolute w-100 mx-auto pb-3 root"
        ref={interactionRef}
        // onTouchMove={e => {
        //   // e.preventDefault();
        //   return false;
        // }}
        // onTouchStart={e => {
        //   // e.preventDefault();
        //   return false;
        // }}
      >
        {messages && (
          <Interaction
            model={chatResponse.Chat.ImageGenerationModel}
            BotId={chatResponse.Chat.BotId}
            Name={chatResponse.Chat.Name}
            Place={chatResponse.Chat.Place}
            userResponses={userResponses}
            userResponsesDisabled={!userChoiceIsReady}
            mediaChoiseDisabled={!!mediaInProgress || mediaInProgress === null}
            mediaInProgress={mediaInProgress}
            disabled={mediaIsPending.current}
            disabledWriting={!!textIsPending.current}
            Author={chatResponse.Chat.Author}
            requestTextMessage={(text) => {
              // capture({
              //   event: 'text_request',
              //   data: {
              //     request_type: 'generate',
              //     is_own_bot: chatResponse.Chat.IsBotAuthor,
              //   },
              // });
              return requestTextMessage(text);
            }}
            requestMedia={(data, type) =>
              requestMedia(
                {
                  ...data,
                  BotId: chatResponse.Chat.BotId,
                  // Model: chatResponse.Chat.ImageGenerationModel
                },
                type
              )
            }
            addLocalMessages={(newMessages: LocalMessageProps[]) => {
              setLocalMessages([...localMessages, ...newMessages]);
              scrollChatToBottom();
            }}
            clearLocalMessages={() => setLocalMessages([])}
            IsBotAuthor={chatResponse.Chat.IsBotAuthor}
          />
        )}
      </div>
    </div>
  );
}
