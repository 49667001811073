// import { HornyNutakuSDK } from 'hornynutakusdk';
import { useState } from 'react';
import { Connections } from 'src/components/Connections/Connections';
import { CharacterCarousel } from 'src/components/Discover/CharacterCarousel/CharacterCarousel';
import { HeaderContainer } from 'src/components/Layout/Header/Header';
import { UserNameRequest } from 'src/components/Layout/UserNameRequest/UserNameRequest';
import { Top } from 'src/components/Top/Top';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
// import { useNutakuContext } from 'src/context/Nutaku.Context';
import { Invite } from 'src/components/Invite/Invite';
import { useUserContext } from 'src/context/User.context';
import { PageControls } from './PageControls/PageControls';
import { SubProposal, subProposalStorageKey } from './SubProposal';
import './index.css';

export enum TabToShow {
  Discover = 'discover',
  Connections = 'connections',
  Top = 'top',
  Invite = 'invite',
}

export type MainProps = {
  tabToShow: TabToShow;
};

export function Main({ tabToShow = TabToShow.Discover }: MainProps) {
  // const nutakCotext = useNutakuContext();

  // // Пример получения userId Nutaku
  // const [nutakuData, setNutakuData] = useState<{ value: string } | null>(null);

  // if (nutakCotext.nutakuEnv === NutakuEnv.Android) {
  //   useEffect(() => {
  //     HornyNutakuSDK.getNutakuUserId().then((result) => {

  //       setNutakuData(result);
  //     });
  //   }, []);
  // }
  // END Пример получения userId Nutaku

  // Пример листнера
  // const commonHandler = (event: PaymentEvent) => {
  //   console.log('Received event: ', event);
  // };

  // HornyNutakuSDK.addListener('onCreatePayment', commonHandler);
  // END Пример листнера

  // const createPayment = () => {
  //   HornyNutakuSDK.createPayment()
  //     .then((result) => {
  //       console.log('result: ' + result.value);
  //     })
  //     .catch((error) => {
  //       console.error('Error creating payment: ' + error);
  //     });
  // };

  const { capture } = useAnalyticsContext();

  const [selectedTab, setSelectedTab] = useState<TabToShow>(() => {
    // console.log(tabToShow);
    return tabToShow;
  });
  const { user } = useUserContext();
  const { modals } = useModalContext();
  const [init, setInit] = useState(false);
  const [showSubProposal, setShowSubProposal] = useState(() => {
    const storedProposal = localStorage.getItem(subProposalStorageKey);
    if (!storedProposal) {
      return true;
    }
    const storedProposalAsTimestamp = Number(storedProposal);
    if (Number.isNaN(storedProposalAsTimestamp)) {
      localStorage.removeItem(subProposalStorageKey);
      return true;
    }

    const timeDiff = Date.now() - storedProposalAsTimestamp;

    if (timeDiff / 1000 / 60 / 60 / 24 >= 3) {
      return true;
    }

    return false;
  });

  const hideSubProposal = () => {
    localStorage.setItem(subProposalStorageKey, Date.now().toString());
    setShowSubProposal(false);
  };
  // useEffect(() => {

  //  capture({
  //     event: 'page_open',
  //     data: {
  //       page_name: selectedTab
  //     }
  //   });

  // }, [selectedTab]);
  // useEffect(() => {
  //   window.history.replaceState(null, 'Page', tabPaths[selectedTab] + selectedTab === TabToShow.Discover ? window.location.search : '');
  // }, [selectedTab]);
  const heightExtract =
    showSubProposal && user && user.MergedSubscription.Level === null
      ? 140
      : 80;
  // const heightExtract = 80;

  return (
    <div className="position-relative m-auto h-100">
      {/* Nutaku ID вывод */}
      {/* {nutakuData ? <div>{nutakuData.value}</div> : <div>Loading...</div>} */}
      {/* Nutaku ID вывод */}

      {/* Nutaku payment */}
      {/* <button onClick={createPayment}>Create Payment</button> */}
      {/* Nutaku payment */}
      {/* <div>{process.env.REACT_APP_NUTAKU_ENV}</div>
      <div>{JSON.stringify(prices?.Subscriptions, null, 2)}</div> */}
      {/* УБИТЬ ТО ЧТО ВЫШЕ */}

      {user && !user!.Nickname && <UserNameRequest />}
      <HeaderContainer absolute={true} />
      <div className="main_container h-100">
        <div
          className="d-flex"
          style={{ height: `calc(100% - ${heightExtract}px)` }}
        >
          <div
            className={`${selectedTab === TabToShow.Discover ? 'd-block h-100 w-100' : 'opacity-0 h-100 w-0 pe-none position-absolute  '}`}
          >
            <CharacterCarousel
              hidden={selectedTab !== TabToShow.Discover || !!modals.length}
              active={selectedTab === TabToShow.Discover}
            />
          </div>
          {selectedTab === TabToShow.Connections && (
            <div
              className={`${selectedTab === TabToShow.Connections ? 'd-block h-100 w-100' : 'd-none'}`}
            >
              <Connections active={selectedTab === TabToShow.Connections} />
            </div>
          )}
          {selectedTab === TabToShow.Top && (
            <div
              className={`${selectedTab === TabToShow.Top ? 'd-block h-100 w-100' : 'd-none'}`}
            >
              <Top active={selectedTab === TabToShow.Top} />
            </div>
          )}
          {selectedTab === TabToShow.Invite && (
            <div
              className={`${selectedTab === TabToShow.Invite ? 'd-block h-100 w-100' : 'd-none'}`}
            >
              <Invite active={selectedTab === TabToShow.Invite} />
            </div>
          )}
        </div>
        {showSubProposal && user && user.MergedSubscription.Level === null && (
          <SubProposal onClose={() => hideSubProposal()} />
        )}
        <PageControls
          selectedTab={selectedTab}
          onTabSelect={(tabToShow) => setSelectedTab(tabToShow)}
        />
      </div>
    </div>
  );
}
