import {
  faBookmark,
  faBoxArchive,
  faCamera,
  faCommentDots,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { ArkAvatar } from 'src/components/Shared/Ark/ArkAvatar';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useUserContext } from 'src/context/User.context';
// import { ConnectionAPIModel } from 'src/services/API/ConnectionsAPI';
import { faDownToLine, faUpToLine } from '@fortawesome/pro-duotone-svg-icons';
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import {
  ConnectionStatus,
  Connection as ConnectionType,
  ConnectionsResponse,
} from 'src/@types/common';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import { appConfig } from 'src/config/app';
import { ConnectionsAPI } from 'src/services/API/ConnectionsAPI';
import { replaceUserName } from 'src/shared/helpers';
import { cn, formatStory } from 'src/shared/utils';
import '../../../../node_modules/@fortawesome/fontawesome-svg-core/styles.css';
import { useConnectionsStore } from '../ConnectionsStore';
import { useOpenChat } from '../OpenChatModal';
import './index.css';
import { useTranslation } from 'react-i18next';

export type ConnectionProps = {
  connection: ConnectionType;
  lastItemRef?: React.Ref<HTMLDivElement>;
};

export function Connection({ connection, lastItemRef }: ConnectionProps) {
  const {t} = useTranslation();
  const storyRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const [isStoryHidden, setIsStoryHidden] = useState(true);

  const [status, setStatus] = useState<ConnectionStatus>(connection.Status);
  const [prevStatus, setPrevStatus] = useState<ConnectionStatus>(
    connection.Status
  );
  const currentFilter = useConnectionsStore((state) => state.filter);
  const onlyOwn = useConnectionsStore((state) => state.onlyOwn);
  const setIsChatOpen = useConnectionsStore((state) => state.setIsChatOpen);

  const { user } = useUserContext();

  const queryClient = useQueryClient();

  const { capture } = useAnalyticsContext();

  const { loadChat, isChatLoading } = useOpenChat();

  const { data, mutate, isPending, isError, error } = useMutation({
    mutationFn: async (newStatus: ConnectionStatus) => {
      ConnectionsAPI.changeConnectionStatusNest({
        BotId: connection.BotId,
        Status: newStatus,
      });
    },
    onMutate: async (newStatus: ConnectionStatus) => {
      setPrevStatus(status);
      setStatus(newStatus);

      // delay
      await new Promise((r) => setTimeout(r, 100));

      if (
        currentFilter !== 'All' &&
        ((currentFilter === 'Favorite' && newStatus !== 'Favorite') ||
          (currentFilter === 'Archived' && newStatus !== 'Archived') ||
          (currentFilter === 'Untagged' && newStatus !== null))
      ) {
        queryClient.setQueryData<
          InfiniteData<AxiosResponse<ConnectionsResponse, any>, unknown>
        >(['Connections', onlyOwn, currentFilter], (oldData) => {
          if (!oldData) return undefined;
          const newPages = oldData.pages.map((page) => ({
            ...page,
            data: {
              ...page.data,
              Connections: page.data.Connections.filter(
                (conn) => conn.BotId !== connection.BotId
              ),
            },
          }));
          return {
            ...oldData,
            pages: newPages,
          };
        });
      } else {
        queryClient.setQueryData<
          InfiniteData<AxiosResponse<ConnectionsResponse, any>, unknown>
        >(['Connections', onlyOwn, currentFilter], (oldData) => {
          if (!oldData) return undefined;

          const newPages = oldData.pages.map((page) => ({
            ...page,
            data: {
              ...page.data,
              Connections: page.data.Connections.map((conn) => {
                if (conn.BotId === connection.BotId) {
                  return {
                    ...conn,
                    Status: newStatus,
                  };
                } else {
                  return conn;
                }
              }),
            },
          }));

          return {
            ...oldData,
            pages: newPages,
          };
        });
      }
    },

    onError: () => {
      setStatus(prevStatus);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Connections'],
        exact: false,
        refetchType: 'inactive',
      });
      // Можно сразу рефетчить, но нет смысла
      // await queryClient.refetchQueries({
      //   queryKey: ['Connections', onlyOwn, currentFilter],
      //   type: 'active',
      //   exact: true,
      // });
    },
  });

  useEffect(() => {
    const isEllipsisActive = (el: HTMLDivElement) => {
      return el.offsetHeight < el.scrollHeight;
    };

    if (storyRef.current && isEllipsisActive(storyRef.current)) {
      setIsEllipsisActive(true);
    }
  }, []);

  return (
    <motion.div
      className={`tw-flex tw-flex-col tw-gap-4 tw-w-full tw-p-5 tw-content-start`}
      ref={lastItemRef}
      key={connection.BotId}
      layout="position"
    >
      {isChatLoading && <FullSizeLoader />}
      <div className="tw-flex tw-flex-row gap-4 tw-items-center">
        <ArkAvatar
          size="sm"
          src={connection.AvatarUrl}
          fallback={connection.Name[0]}
        />
        <div className="tw-flex tw-flex-col tw-gap-2">
          <div className="tw-font-bold tw-text-2xl">{connection.Name}</div>
          <div className="tw-opacity-75 tw-font-bold tw-text-lg tw-flex tw-flex-row tw-gap-4">
            <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
              <FontAwesomeIcon icon={faHeart} />
              <span>{connection.LikesCount}</span>
            </div>
            <div className="tw-flex tw-flex-row tw-gap-2 tw-text-lg tw-items-center">
              <FontAwesomeIcon icon={faCamera} />
              <span>{connection.MediaCount}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-overflow-scroll tw-scrollbar-hide tw-bg-horny-gray-900 tw-rounded-md tw-border tw-border-horny-gray-600 tw-py-2 tw-flex-no-wrap tw-opacity-75">
        <div className="tw-flex tw-flex-row tw-items-center tw-mx-3 tw-gap-4">
          {connection.Tags.map((tag) => (
            <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-w-auto tw-text-nowrap flex-shrink-0 tw-font-bold">
              <img
                src={appConfig.host.EMOJI + '/128/' + tag.icon + '.png'}
                className="tw-w-5 tw-h-5 tw-drop-shadow-sm tw-block tw-grayscale"
              />
              {t(tag.title as any)}
            </div>
          ))}
          <div className='tw-opacity-0'>.</div>
        </div>
      </div>
      <div className="tw-text-lg tw-opacity-75">
        <div className={cn(isStoryHidden && 'tw-line-clamp-3')} ref={storyRef}>
          {replaceUserName(formatStory(connection.Story), user!)}
        </div>
        {isEllipsisActive && (
          <div
            className="tw-cursor-pointer tw-w-full tw-text-lg tw-items-center tw-flex tw-justify-center tw-gap-2 tw-p-2"
            onClick={() => setIsStoryHidden(!isStoryHidden)}
          >
            <span className="tw-text-base">
              {isStoryHidden ? t('More') : t('Less')}
            </span>
            <FontAwesomeIcon icon={isStoryHidden ? faDownToLine : faUpToLine} />
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-row tw-gap-6 tw-text-2xl tw-justify-end">
        <motion.div
          whileTap={{ scale: 0.8 }}
          className="tw-cursor-pointer tw-px-2"
          onClick={() => {
            connection.Status;
            mutate(connection.Status === 'Favorite' ? null : 'Favorite');
          }}
        >
          <FontAwesomeIcon
            icon={faBookmark}
            className={cn(
              'tw-shadow',
              status === 'Favorite' && 'tw-text-horny-red-500'
            )}
          />
        </motion.div>
        <motion.div
          whileTap={{ scale: 0.8 }}
          className="tw-cursor-pointer tw-px-2"
          onClick={() => {
            mutate(connection.Status === 'Archived' ? null : 'Archived');
          }}
        >
          <FontAwesomeIcon
            icon={faBoxArchive}
            className={cn(
              'tw-shadow',
              status === 'Archived' && 'tw-text-horny-red-500'
            )}
          />
        </motion.div>
        <div className="tw-border-l tw-pl-8 tw-border-l-horny-gray-600 tw-content-center">
          <motion.div
            className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-cursor-pointer"
            onClick={() => {
              capture({
                event: 'page_open',
                data: {
                  page_name: 'Chat',
                },
              });
              loadChat(connection);
              setIsChatOpen(true);
            }}
            key={connection.BotId}
            whileTap={{ scale: 0.8 }}
          >
            <FontAwesomeIcon icon={faCommentDots} className="tw-shadow" />
            <div className="tw-text-base tw-font-bold">{t('Chat')}</div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}
