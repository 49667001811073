import './index.css';
// import '@vidstack/react/player/styles/base.css';
// import { MediaPlayer, MediaProvider, Track } from '@vidstack/react';
import { useState } from 'react';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { MediaPlayer } from '@vidstack/react';

export type ChatMediaPhotoProps = {
  src: string;
};
export function ChatMediaVideo({ src }: ChatMediaPhotoProps) {
  const [paused, setPaused] = useState(true);
  const playIcon = paused ? (
    <Icon
      src={IconSrc.Play}
      onClick={() => setPaused(false)}
      size={IconSize.XL}
      style={{ zIndex: 3 }}
      className="position-absolute m-auto media_video_play-icon"
    />
  ) : (
    <Icon
      src={IconSrc.Pause}
      onClick={() => setPaused(true)}
      size={IconSize.XL}
      style={{ zIndex: 3 }}
      className="position-absolute m-auto media_video_play-icon"
    />
  );
  // return <div></div>
  return (
    <MediaPlayer className="w-100">
      <video
        className="w-100"
        src="/chat/media/video/girls.mp4"
        preload="auto"
        data-video="0"
        controls
      />
    </MediaPlayer>
  );
  //     return    <Video controls poster="https://media-files.vidstack.io/poster.png">

  //   </Video>
  // return <MediaPlayer src='/chat/media/video/girls.mp4' controls={true} paused={paused} className='position-relative'>
  //         {playIcon}

  //     <MediaProvider>
  //     <Track
  //         src="english.vtt"
  //         kind="subtitles"
  //         label="English"
  //         lang="en-US"
  //         default
  //         />
  //         </MediaProvider>
  // </MediaPlayer>
  // return  <Player url={'/chat/media/video/girls.mp4'} controls={true}  light={true} previewTabIndex={0} loop={true} playIcon={playIcon}/>
}
