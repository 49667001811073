import { useEffect, useState } from 'react';
import { ConfigureAppearance } from 'src/components/CharacterGeneration/ConfigureAppearance/ConfigureAppearance';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { FilterQuery } from 'src/services/API/BotAPI';
import {
  CharacterGenerationAppearance,
  CharacterGenerationPersonality,
  CharacterGenerationStage,
  ImageGenerationModelType,
} from 'src/services/API/CharacterAPI';
import { countFilters } from '../Shared/utils';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { Personality } from 'src/components/CharacterGeneration/Personality/Personality';
import './index.css';
import { useTranslation } from 'react-i18next';

export type TopBotFiltersProps = {
  onSelect: (filters: FilterQuery) => any;
  storedFilters?: FilterQuery;
};
export function TopBotFilters({ onSelect, storedFilters }: TopBotFiltersProps) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<FilterQuery>(
    storedFilters || {
      Appearance: {},
      Personality: {},
    }
  );
  useEffect(() => {
    setFiltersCount(() => countFilters(filters));
  }, [filters]);
  const [filtersCount, setFiltersCount] = useState(0);
  const [tab, setTab] = useState<
    CharacterGenerationStage.Appearance | CharacterGenerationStage.Personality
  >(CharacterGenerationStage.Personality);
  const [appearanceFiltersCount, setAppearanceFilters] = useState();
  const elements = {
    [CharacterGenerationStage.Appearance]: (
      <ConfigureAppearance
        fetched={(filters.Appearance as CharacterGenerationAppearance) || null}
        onChoice={async () => {}}
        isGenerated={false}
        onBack={() => {}}
        model={ImageGenerationModelType.Anime}
        onFilter={(key, val) => {
          if (val === null) {
            setFilters((prev) => {
              const filtersToSet = { ...prev };
              delete filtersToSet.Appearance![key];
              return filtersToSet;
            });
          } else {
            setFilters((prev) => ({
              ...prev,
              Appearance: {
                ...prev.Appearance,
                [key]: val,
              },
            }));
          }
        }}
      />
    ),
    [CharacterGenerationStage.Personality]: (
      <Personality
        fetched={
          (filters?.Personality as CharacterGenerationPersonality) || null
        }
        onChoice={async () => {}}
        onFilter={(key, val) => {
          if (val === null) {
            setFilters((prev) => {
              const filtersToSet = { ...prev };
              delete filtersToSet.Personality![
                key as keyof CharacterGenerationPersonality
              ];
              return filtersToSet;
            });
          } else {
            if (key === 'Kinks') {
              val = JSON.parse(val);
            }
            setFilters((prev) => ({
              ...prev,
              Personality: {
                ...prev.Personality,
                [key]: val,
              },
            }));
          }
        }}
      />
    ),
  };
  return (
    <div className="px-6 h-100 top-bot-filters position-relative">
      <div
        className="d-flex gap-4 mt-20 justify-content-center fw-bold position-absolute z-3 w-100"
        style={{ top: 0, left: 0 }}
      >
        <div
          onClick={() => setTab(CharacterGenerationStage.Personality)}
          className={`d-flex gap-1 horny-text_very_tiny cursor-pointer align-items-center py-2 px-3 horny-border_100 max-w-max horny-bg-dark_75  d-flex align-items-center ${tab === CharacterGenerationStage.Personality ? '' : 'horny-inner-opacity-half'}`}
          style={{
            outline:
              tab === CharacterGenerationStage.Personality
                ? '1px solid rgba(255, 255, 255, 0.5)'
                : 'none',
          }}
        >
          <Icon src={IconSrc.Brain} size={IconSize.XSmall} />
          <div>{t('Personality')}</div>
          {filtersCount - Object.keys(filters.Appearance!).length > 0 && (
            <span>
              ({filtersCount - Object.keys(filters.Appearance!).length}{' '}
              {t('active')})
            </span>
          )}
        </div>
        <div
          onClick={() => setTab(CharacterGenerationStage.Appearance)}
          className={`d-flex gap-1  horny-text_very_tiny  cursor-pointer align-items-center py-2 px-3 horny-border_100 max-w-max horny-bg-dark_75  d-flex align-items-center ${tab === CharacterGenerationStage.Appearance ? '' : 'horny-inner-opacity-half'}`}
          style={{
            outline:
              tab === CharacterGenerationStage.Appearance
                ? '1px solid rgba(255, 255, 255, 0.5)'
                : 'none',
          }}
        >
          <Icon src={IconSrc.show} size={IconSize.XSmall} />
          <div>{t('Appearance')}</div>
          {Object.keys(filters.Appearance!).length > 0 && (
            <span>
              ({Object.keys(filters.Appearance!).length} {t('active')})
            </span>
          )}
        </div>
      </div>
      <div className="h-100">
        {/* <div className=''></div> */}
        {elements[tab]}
      </div>
      <div
        className="text-center position-absolute z-2 py-5 m-auto horny-bg-gradient_grey_opac"
        style={{ bottom: 0, maxWidth: 600, left: 0, right: 0 }}
      >
        <HornyButton
          height={HornyButtonHeight.Tall}
          {...(filtersCount > 0 ? {} : { disabled: true })}
          onClick={() => onSelect(filters)}
          className="mx-auto"
        >
          <div className="d-flex gap-2 align-items-center px-2 justify-content-between w-100">
            <div className="d-flex align-items-center gap-2">
              <Icon src={IconSrc.check} />
              {t('Apply filters')}
            </div>
            {filtersCount > 0 && (
              <div className="horny-bg-dark_half px-3 py-1 rounded-4 horny-text_very_tiny">
                {filtersCount}
              </div>
            )}
          </div>
        </HornyButton>
      </div>
    </div>
  );
}

// Apply filters {filtersCount > 0 ? filtersCount : null}
