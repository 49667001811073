import {
  createContext,
  useState,
  useContext,
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import {
  MediaPromptsResponse,
  PricesAPIModel,
  ResourcesAPI,
} from 'src/services/API/ResourcesAPI';
import { useUserContext } from './User.context';

export type ResourceContextProps = {
  prices: PricesAPIModel | null;
  mediaPrompts: MediaPromptsResponse | null;
  setPrices: (prices: PricesAPIModel) => any;
  setMediaPrompts: (mediaPrompts: MediaPromptsResponse) => any;
};
export const ResourcesContext = createContext<ResourceContextProps>({
  prices: null,
  mediaPrompts: null,
  setPrices: (prices: PricesAPIModel) => {},
  setMediaPrompts: (mediaPrompts: MediaPromptsResponse) => {},
});
export const ResourcesContextProvider = ResourcesContext.Provider;
export const useResourcesContext = () =>
  useContext<ResourceContextProps>(ResourcesContext);

export const ResourceProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const isNutakuShare = process.env.REACT_APP_DOMAIN_TYPE === 'NutakuShare';
  const [prices, setPrices] = useState<PricesAPIModel | null>(null);
  const [mediaPrompts, setMediaPrompts] = useState<MediaPromptsResponse | null>(
    null
  );
  const { user } = useUserContext();
  const isFetchingPricesRef = useRef(false);
  const isFetchingPromptsRef = useRef(false);
  useEffect(() => {
    if (!prices && !isFetchingPricesRef.current) {
      // && !isNutakuShare
      isFetchingPricesRef.current = true;
      ResourcesAPI.getPrices().then(({ data }) => {
        setPrices(data);
        isFetchingPricesRef.current = false;
      });
    }
  });

  useEffect(() => {
    if (!mediaPrompts && user && !isFetchingPromptsRef.current) {
      // && !isNutakuShare
      isFetchingPromptsRef.current = true;
      ResourcesAPI.getMediaPrompts().then(({ data }) => {
        setMediaPrompts(data);
        isFetchingPromptsRef.current = false;
      });
    }
  });

  return (
    <ResourcesContextProvider
      value={{
        prices,
        setPrices,
        mediaPrompts,
        setMediaPrompts,
      }}
    >
      {!prices || (user && !mediaPrompts) ? null : children}
      {/* isNutakuShare && children */}
    </ResourcesContextProvider>
  );
};
