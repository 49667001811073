import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PromptPicker } from 'src/components/CharacterGeneration/ConfigureAppearance/PromptPicker';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { useUserContext } from 'src/context/User.context';
import { ImageGenerationModel } from 'src/services/API/CharacterAPI';
import { RequestMediaParams } from 'src/services/API/ConnectionsAPI';
import { MediaType } from 'src/services/API/MediaAPI';
import { PlaceAPIModel } from 'src/services/API/ResourcesAPI';
import { UserAPI } from 'src/services/API/UserAPI';
import { replaceUserName } from 'src/shared/helpers';
import { LocalMessageProps } from '../LocalMessage/LocalMessage';
import { ChatNames } from './ChatNames';
import { LeftMessagesCount } from './LeftMessagesCount';
import { PromptPickParams } from './LocalScenario';
import { InputPurpose, RequestInput } from './RequestInput';

export enum InteractionType {
  RequestPhoto = 'requestPhoto',
  RequestVideo = 'requestVideo',
  RequestAudio = 'requestAudio',
}

export type InteractionProps = {
  disabled: boolean;
  requestTextMessage: (text: string) => Promise<boolean>;
  requestMedia: (
    data: Omit<Omit<RequestMediaParams, 'BotId'>, 'Model'>,
    mediaType: MediaType
  ) => any;
  Name: string;
  userResponses: string[];
  userResponsesDisabled: boolean;
  mediaChoiseDisabled: boolean;
  mediaInProgress: number | null;
  disabledWriting: boolean;
  Author: string;
  addLocalMessages: (localMessages: LocalMessageProps[]) => any;
  clearLocalMessages: () => any;
  IsBotAuthor: boolean;
  Place: PlaceAPIModel;
  BotId: string;
  model: ImageGenerationModel;
};

export function Interaction({
  disabled,
  Author,
  disabledWriting,
  Name,
  requestTextMessage,
  mediaInProgress,
  requestMedia,
  mediaChoiseDisabled,
  userResponses,
  userResponsesDisabled,
  addLocalMessages,
  clearLocalMessages,
  IsBotAuthor,
  Place,
  BotId,
  model,
}: InteractionProps) {
  const { t } = useTranslation();
  const [selectedMediaType, setSelectedMediaType] = useState<MediaType>(
    MediaType.Photo
  );
  const iconSrc = selectedMediaType
    ? {
        [MediaType.Photo]: IconSrc.Photo,
        [MediaType.Audio]: IconSrc.Audio,
        [MediaType.Video]: IconSrc.Video,
      }[selectedMediaType]
    : IconSrc.Photo;

  const [mediaChoiceUnfold, setMediaChoiceUnfold] = useState<boolean>(false);
  const [userChoiceUnfold, setUserChoiceUnfold] = useState<boolean>(
    userResponses.length > 0 && !userResponsesDisabled
  );

  const { addModal, removeModal } = useModalContext();

  const clickMediaChoiceRef = useRef(null);
  const clikUserChoiceRef = useRef(null);
  const requestTextMessageRef = useRef<HTMLInputElement>(null);
  const [requestTextContent, setRequestTextContent] = useState('');
  const { user, setUser } = useUserContext();
  const { capture } = useAnalyticsContext();
  const [mediaScenarioStarted, setMediaScenarioStarted] = useState(false);
  const [mediaScenarioPlace, setMediaScenarioPlace] = useState('');
  const [selectedUserResponse, setSelectedUserResponse] = useState('');
  const [promptPickProps, setPromptPickProps] =
    useState<PromptPickParams | null>(null);
  // const {messages} = useMessages('85127c15-9239-4c83-aeb2-1b086fa2b7f8');
  // console.log({messages})

  const changeUserIsGenerateChoices = () => {
    UserAPI.changeIsGenerateChoices(!user?.IsGenerateChoices).then(() => {
      setUser({ ...user!, IsGenerateChoices: !user?.IsGenerateChoices });
    });
  };
  useEffect(() => {
    if (!mediaChoiceUnfold && !mediaScenarioStarted && userResponses.length) {
      setUserChoiceUnfold(true);
    }
  }, [userResponses]);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        !!clickMediaChoiceRef.current &&
        !document
          .getElementById('interaction-container-media')
          ?.contains(e.target as Node) &&
        clickMediaChoiceRef.current !== e.target &&
        !(clickMediaChoiceRef.current as HTMLElement).contains(e.target as Node)
      ) {
        setMediaChoiceUnfold(false);
      }
      return;
    };
    if (mediaChoiceUnfold) {
      window.addEventListener('click', handleOutsideClick, {});
    }
    return () => {
      !mediaChoiceUnfold &&
        window.removeEventListener('click', handleOutsideClick);
    };
  });

  const variants = {
    open: { height: 'auto', opacity: 1 },
    closed: {
      height: 0,
      opacity: 0,
    },
  };

  const requestText = (text: string) => {
    if (!disabledWriting) {
      return requestTextMessage(text).then((res) => {
        setUserChoiceUnfold(false);
        return !!res;
      });
    }
  };

  const slicedUserResponses = userResponses.slice(0, 1);

  return (
    <div className="d-flex gap-3 text-center flex-column m-auto px-5 py-2">
      <ChatNames Author={Author} Name={Name} BotId={BotId} />
      <div className="position-relative w-100 text-end">
        <div className="w-100 d-flex justify-content-between">
          <div className="d-flex gap-3 align-items-center">
            <div
              ref={clickMediaChoiceRef}
              className="w-max-auto position-relative"
            >
              {mediaChoiceUnfold && (
                <div
                  className="position-absolute border border-1 w-max-content p-2 d-flex flex-column gap-2 rounded-3"
                  id="interaction-container-media"
                  style={{
                    top: -105,
                    left: -5,
                    opacity: disabled ? 0.5 : 1,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                  }}
                >
                  {(Object.keys(MediaType) as Array<keyof typeof MediaType>)

                    .filter((key) => Number.isNaN(Number(key)))
                    .map((mediaType) => {
                      return (
                        <HornyButton
                          key={mediaType}
                          className={
                            mediaType !== 'Photo' ? 'pe-none opacity-75' : ''
                          }
                          icon={IconSrc[mediaType]}
                          height={HornyButtonHeight.Short}
                          bg={HornyButtonBG.Red}
                          // data-anal={'media_request'}
                          // data-anal-request_type={'generate'}
                          // data-anal-media_type={mediaType.toLowerCase()}
                          // data-anal-is_own_bot={IsBotAuthor}
                          onClick={() => {
                            const modal = addModal({
                              children: (
                                <PromptPicker
                                  description={t(
                                    'Select a propmpt for your media'
                                  )}
                                  charGen={false}
                                  buttonText={`${t('Request')} ${t(MediaType[mediaType] as any)}`}
                                  multiplier={1}
                                  isGenerated={true}
                                  // showNudity={false}
                                  onPick={(params) => {
                                    requestMedia(
                                      {
                                        // Place: mediaScenarioPlace,
                                        ...params,
                                      },
                                      selectedMediaType
                                    );
                                    // setPromptParams(params);
                                    // onLevelDown([
                                    //   {
                                    //     sender: ChatMessageAuthor.User,
                                    //     content: params.title,
                                    //   },
                                    //   {
                                    //     sender: ChatMessageAuthor.Bot,
                                    //     content: 'Please select the place',
                                    //     emoji: '128540',
                                    //   },
                                    // ]);
                                    removeModal(modal);
                                  }}
                                  // multiplier={1}
                                  // isGenerated={false}
                                  model={model}
                                />
                              ),
                            });
                            // setMediaChoiceUnfold(false);
                            // setUserChoiceUnfold(false);
                            // setSelectedMediaType(MediaType[mediaType]);
                            // if (!disabled) {
                            //   setMediaScenarioStarted(true);
                            //   setMediaChoiceUnfold(false);
                            //   setRequestTextContent('');
                            //   addLocalMessages([
                            //     {
                            //       sender: ChatMessageAuthor.Bot,
                            //       content:
                            //         'Please select what you`d like to see',
                            //       emoji: `128520`,
                            //     },
                            //   ]);
                            // }
                          }}
                          // type={HornyButtonType.Chat}
                        >
                          {`${t('Request')} ${t(MediaType[mediaType] as any)}`}
                        </HornyButton>
                      );
                    })}
                </div>
              )}
              <HornyButton
                icon={mediaScenarioStarted ? IconSrc.Back : iconSrc}
                height={HornyButtonHeight.Short}
                bg={HornyButtonBG.Red}
                onClick={() => {
                  if (mediaScenarioStarted) {
                    clearLocalMessages();
                    setMediaScenarioStarted(false);
                    setMediaScenarioPlace('');
                    setUserChoiceUnfold(true);
                  } else {
                    setMediaChoiceUnfold(true);
                  }
                }}
                // type={HornyButtonType.Chat}
                {...(mediaChoiceUnfold || disabled || mediaChoiseDisabled
                  ? { disabled: true }
                  : {})}
              >
                {mediaScenarioStarted ? t('Back') : t('Request media')}{' '}
                {/* ${selectedMediaType ? selectedMediaType :*/}
              </HornyButton>
            </div>
            {/* Author: <span className="opacity-75 d-flex me-2 ">@{Author}</span> */}
            {
              <span
                className={` d-inline-flex position-relative justify-content-center horny-text_tiny align-items-center `}
                style={{ height: 20, width: 20 }}
              >
                <span>
                  {mediaInProgress === null ? <span>∞</span> : mediaInProgress}
                </span>
                <Icon
                  src={
                    mediaInProgress || mediaInProgress === null
                      ? IconSrc.Loader
                      : IconSrc.Circle
                  }
                  size={IconSize.Medium}
                  className={`position-absolute rounded-circle   w-100 h-100    ${mediaInProgress || mediaInProgress === null ? 'horny-spin ' : '   '}`}
                  style={{ left: 0, top: 0 }}
                />
              </span>
            }
          </div>
          <div className="d-flex gap-3">
            <div
              ref={clikUserChoiceRef}
              className="w-max-auto position-relative"
            >
              <HornyButton
                height={HornyButtonHeight.Short}
                bg={HornyButtonBG.Orange}
                onClick={changeUserIsGenerateChoices}
                // type={HornyButtonType.Chat}
                {...(mediaChoiceUnfold ||
                userResponsesDisabled ||
                mediaScenarioStarted ||
                !userResponses.length
                  ? { disabled: true }
                  : {})}
              >
                <Icon
                  src={
                    userResponses.length && !userResponsesDisabled
                      ? userChoiceUnfold
                        ? IconSrc.hide
                        : IconSrc.show
                      : IconSrc.Loader
                  }
                  size={IconSize.XSmall}
                  className={`${userResponses.length && !userResponsesDisabled ? '' : 'horny-spin'}`}
                />
                {t('Suggested')}
              </HornyButton>
              {/*  */}
            </div>
          </div>
        </div>
      </div>
      {/* userChoiceUnfold &&
            !userResponsesDisabled && */}

      <motion.div
        className="position-relative mw-100 text-center d-flex flex-column gap-3 align-items-center"
        animate={user?.IsGenerateChoices ? 'open' : 'closed'}
        variants={variants}
        initial="open"
      >
        {slicedUserResponses.map((userResponse, i) => {
          return (
            <HornyButton
              key={userResponse + i + Date.now()}
              height={HornyButtonHeight.Long}
              bg={HornyButtonBG.Orange}
              // data-anal={'message_sent'}
              // data-anal-is_suggested={'true'}
              // data-anal-is_own_bot={IsBotAuthor}
              onClick={() => {
                setSelectedUserResponse(replaceUserName(userResponse, user!));
                // !disabledWriting &&
                //   requestTextMessage(userResponse).then((res) => {
                //     if (!!res) {
                //       capture({
                //         event: 'message_sent',
                //         data: {
                //           request_type: 'generate',
                //           is_own_bot: IsBotAuthor,
                //           is_suggested: true,
                //           bot_id: BotId,
                //         },
                //       });
                //       setRequestTextContent('');
                //       setUserChoiceUnfold(false);
                //     }
                //   });
              }}
              disabled={disabledWriting ? true : undefined}
              // type={HornyButtonType.Chat}
            >
              <Icon src={IconSrc.Chat} />{' '}
              <p className="text-truncate m-0">
                {replaceUserName(userResponse, user!)}
              </p>
            </HornyButton>
          );
        })}
        {/* {mediaScenarioStarted && (
          <LocalScenario
            model={model}
            IsBotAuthor={IsBotAuthor}
            place={Place}
            onPromptPick={(params) => setPromptPickProps(params)}
            onLevelDown={(messages: LocalMessageProps[]) =>
              addLocalMessages(messages)
            }
            onChoice={(params) => {
              requestMedia(
                {
                  // Place: mediaScenarioPlace,
                  ...params,
                },
                selectedMediaType
              )
                .then(() => {
                  setMediaScenarioStarted(false);
                  clearLocalMessages();
                  setMediaScenarioPlace('');
                })
                .catch(() => {})
                .finally(() => {});
            }}
          />
        )} */}
      </motion.div>

      {!mediaScenarioStarted && (
        <div className="d-flex flex-column gap-2">
          <RequestInput
            BotId={BotId}
            IsBotAuthor={IsBotAuthor}
            purpose={InputPurpose.Text}
            defaultValue={selectedUserResponse}
            disabled={
              (!mediaScenarioStarted && disabledWriting) ||
              (mediaScenarioStarted && !!mediaScenarioPlace) //||
              // (!mediaScenarioStarted && !user!.MessagesLeft)
            }
            onSubmit={async (text: string) => {
              // if (!mediaScenarioStarted) {
              return requestText(text);
              // } else {
              //   setMediaScenarioPlace(text);
              //   return true;
              // }
            }}
            placeholder={t('Type whatever you want...')}
          />
          <div className="opacity-75 d-flex justify-content-center">
            <LeftMessagesCount />
          </div>
        </div>
      )}
    </div>
  );
}
