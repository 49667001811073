import { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { Link } from 'src/components/Shared/Link/Link';
import { appConfig } from 'src/config/app';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from '../../Shared/HornyButton/HornyButton';
import './index.css';

const hintStorageKey = 'languageHintWasShown';
export function LanguageHint() {
  const [t] = useTranslation();
  const [hintIsShown, setHintIsShown] = useState(() => {
    return !!localStorage.getItem(hintStorageKey);
  });

  const updateHintShowStatus = () => {
    localStorage.setItem(hintStorageKey, 'true');
    setHintIsShown(true);
  };

  return !hintIsShown ? (
    <div className="z-2 position-fixed horny-absolute_full d-flex align-items-center justify-content-center mx-2">
      <div
        className="px-7 py-11 d-flex flex-column gap-4 horny-shadow-gray horny-bg_grey_opac rounded-3 text-white w-100"
        style={{ maxWidth: 400 }}
      >
        <div className=" gap-3 d-flex justify-content-start align-items-center">
          <span
            style={{
              fontSize: '300%',
            }}
            // dangerouslySetInnerHTML={{
            //     __html: '&#127758;'
            // }}
          >
            🌎
          </span>
          <span className="fs-4 fw-bold">
            {t(
              'Explore different languages and use our service only if you are 18 or older.'
            )}
          </span>
        </div>
        <div>
          {t(
            'You can interact with Al in various languages, not limited to English'
          )}
          <br />
          <br />
          <div
            className="d-flex gap-2 justify-content-center w-100"
            style={{
              fontSize: '275%',
            }}
          >
            <Image
              width={305}
              src={appConfig.host.CDN + '/img/flags/flags.png?width=600'}
            />
            {/* <b>🇯🇵</b> <b>🇨🇳 </b>
          <b>🇷🇺 </b>
          <b>🇮🇹</b> <b>🇧🇷 </b>
          <b>🇪🇸</b> <b>🇩🇪</b> <b>🇫🇷</b>
          <b> 🇬🇧</b> */}
          </div>
          <br />
          {t('By pressing the button “Ok, got it”, you agree to the')}{' '}
          <Link url="https://temptationgames.com/privacy-policy">
            {t('Privacy Policy')}
          </Link>{' '}
          {t('and')}{' '}
          <Link url="https://temptationgames.com/terms-of-service">
            {t('Terms of Service')}
          </Link>
          . {t('Also you confirm that you are 18 years old or older')}.
        </div>

        <div className="text-center mt-5">
          <HornyButton
            bg={HornyButtonBG.Red}
            height={HornyButtonHeight.Tall}
            onClick={() => updateHintShowStatus()}
          >
            <div className="d-flex gap-3">
              <Icon src={IconSrc.Language} size={IconSize.Small} />
              {t('Ok, got it')}
            </div>
          </HornyButton>
        </div>
      </div>
    </div>
  ) : null;
}
