import { ChatMediaPhoto } from './ChatMediaPhoto/ChatMediaPhoto';
import './index.css';
import { ChatMediaVideo } from './ChatMediaVideo/ChatMediaVideo';
import { ChatMediaAudio } from './ChatMediaAudio/ChatMediaAudio';
import { MediaPreviewAPIModel, MediaType } from 'src/services/API/MediaAPI';
import { ChatMediaPreview } from './ChatMediaPreview';

export type ChatMediaProps = {
  className?: string;
  media: MediaPreviewAPIModel[];
};

type ElementsToRender = {
  [chatMediaType in MediaType]: (...args: any[]) => JSX.Element;
};

export const elementsToRender: ElementsToRender = {
  [MediaType.Photo]: (src: string, id) => (
    <ChatMediaPhoto src={src} key={id} MediaId={id} />
  ),
  [MediaType.Video]: (src: string, id) => <ChatMediaVideo src={src} key={id} />,
  [MediaType.Audio]: (src: string, id) => <ChatMediaAudio src={src} key={id} />,
};
export function ChatMedia({ media, className = '' }: ChatMediaProps) {
  return (
    <div
      className={
        'd-grid row-gap-2 justify-content-start flex-wrap align-items-start mw-100 chat_media ' +
        className
      }
    >
      {media.map(({ MediaType, PreviewUrl, MediaId }) => {
        return (
          <ChatMediaPreview
            key={MediaId}
            MediaId={MediaId}
            PreviewUrl={PreviewUrl}
            MediaType={MediaType}
          />
        );
      })}
    </div>
  );
}
