import { Clipboard } from '@capacitor/clipboard';
import { AxiosError } from 'axios';
import { motion } from 'framer-motion';
import i18next from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { appConfig } from 'src/config/app';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { useNotificationsContext } from 'src/context/Notifications.Context';
import { NutakuEnv, useNutakuContext } from 'src/context/Nutaku.Context';
import { useUserContext } from 'src/context/User.context';
import {
  EnterCodeResponseMessage,
  UserAPI,
  UserAPIModel,
} from 'src/services/API/UserAPI';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import { verifyUserHandle } from 'src/shared/helpers';
import { focusEditableElement } from 'src/shared/utils';
import { Authorization } from '../Authorization/Authorization';
import { NotificationType } from '../Layout/Notification/Notification';
import {
  HornyButton,
  HornyButtonHeight,
} from '../Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from '../Shared/Icon/Icon';
import { NewInput } from '../Shared/Input/NewInput';
import { Link } from '../Shared/Link/Link';
import { LanguagePopup, Languages } from './LanguagePopup';
import { UserDelete } from './UserDelete';

type UserProfileProps = {
  user: UserAPIModel;
};

export function UserProfile({ user: u }: UserProfileProps) {
  const { t } = useTranslation();
  const userNickNameHint = t('The user name must be');
  const { setUser, user } = useUserContext();
  const [shareTextId, setShareTextId] = useState(t('Copy'));
  const [shareTextVersion, setShareTexVersion] = useState(t('Copy'));
  const [saveText, setSaveText] = useState(t('Edit'));
  const [isEditing, setIsEditing] = useState(false);
  const handleInputRef = useRef<HTMLSpanElement>(null);
  const [userHandle, setUserHandle] = useState(user!.Nickname);
  const [inputValue, setInputValue] = useState(user!.Nickname);
  const { addNotification } = useNotificationsContext();
  const { isNutaku } = useNutakuContext();
  const { nutakuEnv } = useNutakuContext();

  const [isEnterCodeFetching, setIsEnterCodeFetching] = useState(false);
  const [codeInput, setCodeInput] = useState<string | null>(null);
  const [message, setMessage] = useState<EnterCodeResponseMessage | null>(null);

  const handleCodeNewValue = (value: string) => {
    setCodeInput(value);
  };

  const handleCodeEnter = () => {
    if (codeInput) {
      setIsEnterCodeFetching(true);
      UserAPI.enterCode({ Code: codeInput }).then((response) => {
        if (response.data.User !== null) {
          setUser(response.data.User);
        }
        setIsEnterCodeFetching(false);
        setMessage(response.data.Message);
      });
    }
  };

  useEffect(() => {
    setUserHandle(user!.Nickname);
  }, [user!.Nickname]);
  const changeUserHandle = () => {
    if (user?.IsAnonymous) {
      return addModal({
        showBalance: false,
        children: <Authorization />,
        className: 'z-50 horny-bg-gradient_red',
      });
    }
    if (handleInputRef.current) {
      if (
        inputValue.length < 3 ||
        inputValue.length > 32 ||
        !verifyUserHandle(inputValue)
      ) {
        return addNotification({
          type: NotificationType.Info,
          message: userNickNameHint,
          timestamp: Date.now(),
        });
      }

      UserAPI.changeHandle(inputValue)
        .then(({ data: userResponse }) => {
          setUser({
            ...user!,
            Nickname: userResponse.Nickname,
          });
          setUserHandle(userResponse.Nickname);
          setInputValue(userResponse.Nickname);
          setIsEditing(false);
          setTimeout(() => {
            setSaveText(t('Saved'));
            setTimeout(() => {
              setSaveText(t('Edit'));
            }, 1500);
          }, 50);
          setError('');
        })
        .catch((e: AxiosError) => {
          console.log(e);

          if (e.response?.status && e.response.status < 500) {
            setError(t(e.response?.data as any));
          }
          // setIsEditing(false);
        });
    }
  };

  useEffect(() => {
    if (isEditing) {
      setTimeout(() => focusEditableElement(handleInputRef), 100);
    }
  }, [isEditing]);

  const copyIdToClipboard = (text: string, cb: (t: string) => any) => {
    const handleCopySuccess = () => {
      console.log('Content copied to clipboard');
      cb(t('Copied'));
      setTimeout(() => {
        cb(t('Copy'));
      }, 2000);
    };

    const handleCopyFailure = () => {
      console.error('Failed to copy');
    };

    let copyPromise;

    if (nutakuEnv == NutakuEnv.Android) {
      Clipboard.write({
        string: text,
      });
      copyPromise = Promise.resolve();
    } else {
      copyPromise = navigator.clipboard.writeText(text);
    }

    copyPromise.then(handleCopySuccess, handleCopyFailure);
  };

  const limitInputLength = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    console.log({ e: (e.target as HTMLSpanElement).innerText });
    // if (handleInputRef.current) {
    if (
      e.key !== 'Backspace' &&
      (e.target as HTMLSpanElement).innerText.length >= 32
    ) {
      e.preventDefault();
      return false;
    }

    if (!/^[^\s]+$/.test(e.key)) {
      e.preventDefault();
      return false;
    }

    // }

    return true;
  };

  const LinksData = [
    {
      url: 'https://temptationgames.com/privacy-policy',
      children: t('Privacy Policy'),
    },
    {
      url: 'https://temptationgames.com/terms-of-service',
      children: t('Terms of Service'),
    },
    {
      url: 'https://temptationgames.com/#block-ad19c8cf0b23468ab41bb66f7d6af393',
      children: t('Support'),
    },
    {
      url: 'https://feedback.temptations.ai/',
      children: t('Report Bug / Suggest Feature'),
    },
  ];

  const [userName, setUserName] = useState(user!.Nickname);
  // useEffect(() => )
  const { user: contextUser, logout } = useUserContext();
  const { addModal } = useModalContext();

  const addLanguageModal = () => {
    addModal({
      children: <LanguagePopup />,
      className: 'horny-bg-dark_opac',
    });
  };
  // console.log({
  //   isEditing,
  //   inputValue,
  //   userHandle
  // });
  const { capture } = useAnalyticsContext();
  const [error, setError] = useState('');
  const currentLanguage = i18next.resolvedLanguage;
  return (
    <motion.div
      className="h-100 d-flex justify-content-center px-2"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      <div className="align-self-center px-1 w-100">
        <div className="px-6 py-11 d-flex flex-column gap-4  horny-bg_grey rounded-3 text-white w-100 horny-bg_grey_opac horny-shadow-gray">
          <div className=" mb-2  d-flex justify-content-between">
            <span className="fs-3 fw-bold">{t('Settings')}</span>
          </div>
          <div className="fs-4 fw-bold">{t('User')}</div>
          <div className="d-flex align-items-center">
            <img
              src=""
              style={{ width: 0, height: 0 }}
              onError={() => {
                capture({
                  event: 'page_open',
                  data: {
                    page_name: 'settings',
                  },
                });
              }}
            />
            <div className="">
              <span className="fw-bold">{t('ID')}:</span>{' '}
              <span className="ps-1 opacity-75">{user!.UserId}</span>
            </div>
            {nutakuEnv != NutakuEnv.Web && (
              <motion.div
                whileTap={{ scale: 0.8 }}
                className="ms-7 d-flex   align-items-center gap-1 cursor-pointer fw-bold"
                onClick={() => copyIdToClipboard(user!.UserId, setShareTextId)}
              >
                <Icon src={IconSrc.Copy} />
                <span className="text-nowrap">{shareTextId}</span>
              </motion.div>
            )}
          </div>

          <div className="d-flex align-items-center gap-3">
            <div className="fw-bold">{t('Language')}:</div>
            <motion.div
              whileTap={{ scale: 0.8 }}
              className="d-flex align-items-center gap-2 cursor-pointer fw-bold horny-bg-dark_half px-4 py-2 horny-border_100"
              onClick={() => addLanguageModal()}
            >
              <Icon
                src={Languages[currentLanguage!].icon}
                size={IconSize.XSmall}
              />
              <div>{Languages[currentLanguage!].language}</div>
            </motion.div>
          </div>

          <div className="d-flex ">
            <div className=" d-flex align-items-center">
              <span className="fw-bold">{t('Nickname')}:</span>
              {
                !isEditing ? (
                  <div className="ms-2   align-items-center py-1 px-4 rounded-4 opacity-75 horny-bg-dark_opac">
                    <span className="opacity-75">@{userHandle}</span>
                  </div>
                ) : (
                  <div className="ms-2   align-items-center py-1 px-4 text-white rounded-4   horny-bg-dark_opac">
                    <span className="opacity-75">
                      @
                      <span
                        contentEditable={'true'}
                        ref={handleInputRef}
                        onKeyDown={(e) => limitInputLength(e)}
                        onInput={(e) => {
                          console.log('changing');
                          setInputValue(
                            (e.target as HTMLSpanElement).innerText
                          );
                        }}
                        defaultValue={userHandle}
                      >
                        {userHandle}
                      </span>
                    </span>
                  </div>
                )
                // <div className="position-relative">
                //   <div className="position-absolute" style={{left: 18, bottom: 3}}>
                //     @
                //   </div>
                //   <div ref={handleInputRef}  className="ms-2  text-white d-inline-flex align-items-center py-1 pe-4 rounded-4   horny-bg-dark_opac border-0" style={{width: 100, height: 23.14, paddingLeft: 24}} defaultValue={user.Username}/>
                // </div>
              }
            </div>
            {isEditing ? (
              <motion.div
                whileTap={{ scale: 0.8 }}
                className={`cursor-pointer d-flex ms-5 align-items-center fw-bold gap-1 ${inputValue === userHandle || !inputValue ? ' pe-none opacity-50' : ''}`}
                onClick={() => {
                  if (!isEditing) {
                    setIsEditing(true);
                  } else {
                    changeUserHandle();
                  }
                }}
              >
                <Icon src={!isEditing ? IconSrc.Edit : IconSrc.Save} />
                <span>{!isEditing ? saveText : t('Save')}</span>
              </motion.div>
            ) : (
              <div
                className={` cursor-pointer d-flex ms-5 align-items-center fw-bold gap-1 ${isEditing && inputValue === userHandle ? ' pe-none opacity-50' : ''}`}
                onClick={() => {
                  if (!isEditing) {
                    setIsEditing(true);
                  } else {
                    changeUserHandle();
                  }
                }}
              >
                <Icon src={!isEditing ? IconSrc.Edit : IconSrc.Save} />
                <span>{!isEditing ? saveText : t('Save')}</span>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center">
            <div className="">
              <span className="fw-bold">{t('App version')}:</span>{' '}
              <span className="ps-1 opacity-75">{appConfig.app.version}</span>
            </div>
            {nutakuEnv != NutakuEnv.Web && (
              <motion.div
                whileTap={{ scale: 0.8 }}
                className="ms-7 d-flex   align-items-center gap-1 cursor-pointer fw-bold"
                onClick={() =>
                  copyIdToClipboard(appConfig.app.version, setShareTexVersion)
                }
              >
                <Icon src={IconSrc.Copy} />
                <span>{shareTextVersion}</span>
              </motion.div>
            )}
          </div>
          <div className="text-danger">{error ? error : ''}</div>
          <div className="fs-4 fw-bold">{t('Codes')}</div>
          <div className="d-flex flex-row gap-6">
            <NewInput
              onValueChange={handleCodeNewValue}
              defaultText={t('Enter code...')}
            />
            <HornyButton
              onClick={() => {
                handleCodeEnter();
              }}
              height={HornyButtonHeight.Tall}
              disabled={isEnterCodeFetching ? true : undefined}
            >
              <Icon
                src={isEnterCodeFetching ? IconSrc.Loader : IconSrc.check}
                size={IconSize.Small}
                className={`${isEnterCodeFetching && 'horny-spin'}`}
              />
              <div>{t('Enter Code')}</div>
            </HornyButton>
          </div>
          {message !== null && (
            <div className="horny-bg-dark_half p-4 rounded-2 fw-bold d-flex flex-row gap-3">
              <Icon
                src={
                  message?.Type === 'error' ? IconSrc.Warning : IconSrc.check
                }
                size={IconSize.Small}
              />
              <div>{t(message?.Message as any, message.Params)}</div>
            </div>
          )}
          <div className="fs-4 fw-bold mt-3">{t('Links')}</div>
          <div className="d-flex flex-column gap-3 no-underline-on-hover">
            <div className="d-flex justify-content-center gap-4 flex-wrap">
              {LinksData.map((data) => (
                <Link
                  {...data}
                  underlined={true}
                  icon={IconSrc.Link}
                  className="fw-bold"
                />
              ))}
            </div>

            <div className="d-flex justify-content-center gap-4">
              <Link
                children={t('Community on Discord')}
                url="https://discord.gg/G4KtPxH2Se"
                icon={IconSrc.Discord}
                className="fw-bold"
              />
              <Link
                children={t('Twitter')}
                url="https://twitter.com/TemptationsAI"
                icon={IconSrc.Twitter}
                className="fw-bold"
              />
            </div>
          </div>
          {!isNutaku && (
            <div className="text-white fw-bold text-decoration-underline text-center mt-5 d-flex flex-row gap-10 align-items-center justify-content-center">
              <u
                className="text-tiny text-danger cursor-pointer"
                onClick={() =>
                  addModal({
                    children: <UserDelete />,
                    className: 'horny-bg-dark_opac',
                  })
                }
              >
                {t('Delete account')}
              </u>
              {(!isNutaku || nutakuEnv == NutakuEnv.Android) && (
                <div
                  className="text-white fw-bold text-decoration-underline text-center cursor-pointer"
                  onClick={() => logout()}
                >
                  <u>{t('Log out')}</u>
                </div>
              )}
            </div>
          )}

          {/* {nutakuEnv == NutakuEnv.Android && (
            <div
              className="text-white fw-bold text-decoration-underline text-center mt-5 cursor-pointer"
              onClick={() => {
                logout();
                Nutaku.logout();
              }}
            >
              <u>Log out</u>
            </div>
          )} */}
        </div>
      </div>
    </motion.div>
  );
}

// || inputValue.length < 8 || inputValue.length > 32 || !verifyUserHandle(inputValue)
