import { Switch } from '@ark-ui/react';
import { useState } from 'react';
import { cn } from 'src/shared/utils';

type ArkSwitchProps = {
  onChange: (e: boolean) => void;
  defaultChecked: boolean;
  label: string;
};

export const ArkSwitch = ({ onChange, defaultChecked, label }: ArkSwitchProps) => {
  const [checked, setChecked] = useState(defaultChecked);
  return (
    <Switch.Root
      className="tw-flex tw-flex-row tw-gap-3 tw-items-center tw-cursor-pointer"
      onCheckedChange={(e) => {
        setChecked(e.checked);
        onChange(e.checked);
      }}
      defaultChecked={checked}
    >
      <Switch.Control
        className={cn(
          'tw-bg-horny-gray-900 tw-border tw-border-horny-gray-600 tw-rounded-full tw-w-16 tw-h-10 tw-flex tw-items-center tw-h'
        )}
      >
        <Switch.Thumb
          className={cn(
            'tw-rounded-full tw-bg-white tw-shadow-bezel tw-block tw-w-6 tw-h-6 tw-transition-all tw-duration-100',
            checked
              ? 'tw-translate-x-8 tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-600'
              : 'tw-translate-x-2 tw-bg-gradient-to-b tw-from-horny-gray-100 tw-to-horny-gray-400'
          )}
        />
      </Switch.Control>
      <Switch.Label className="tw-font-bold tw-text-xl">
        {label}
      </Switch.Label>
      <Switch.HiddenInput />
    </Switch.Root>
  );
};
