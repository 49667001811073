// import { useEffect, useState } from "react";
// import { TypePreview, TypePreviewSize } from "src/components/CharacterGeneration/Shared/TypePreview/TypePreview";
// import { useResourcesContext } from "src/context/ResourcesContext";
// import {  ImageGenerationModelType } from "src/services/API/CharacterAPI";
// import { NudityLevel } from "src/services/API/ResourcesAPI";
// import { HornyButton, HornyButtonHeight } from "../HornyButton/HornyButton";
// import { IconSrc } from "../Icon/Icon";
// import { MediaRequestBase } from "src/services/API/MediaAPI";
// import { AddBlock } from "../AddBlock/AddBlock";
// import { Price } from "../Price/Price";
// import { PromptPickParams } from "src/components/Chat/Interaction/LocalScenario";

import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { AddBlock } from 'src/components/Shared/AddBlock/AddBlock';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { IconSrc } from 'src/components/Shared/Icon/Icon';
import { Price } from 'src/components/Shared/Price/Price';
import { Subscriptions } from 'src/components/Subscription/Subscriptions';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import { ImageGenerationModelType } from 'src/services/API/CharacterAPI';
import { MediaRequestBase } from 'src/services/API/MediaAPI';
import { NudityLevel } from 'src/services/API/ResourcesAPI';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import { getImgUrl } from 'src/shared/helpers';
import {
  TypePreview,
  TypePreviewSize,
} from '../Shared/TypePreview/TypePreview';
import { useTranslation } from 'react-i18next';

export type PromptPickerProps = {
  onPick?: (data: MediaRequestBase) => any;
  // onPromptPick?: (params: PromptPickParams) => any;
  // prompts: CharacterAppearanceCategoryAPIModel[];
  model: ImageGenerationModelType;
  isGenerated: boolean;
  multiplier: number;
  buttonText?: string;
  charGen?: boolean;
  description?: string;
  // categoryToFilterOut?: string;
  // multiplier: number;
  // showNudity: boolean;
};

export function PromptPicker({
  charGen = true,
  description = 'Select the pose for the photo',
  ...props
}: PromptPickerProps) {
  const { t } = useTranslation();
  const mediaPrompts = useResourcesContext().mediaPrompts!.Prompts.filter((c) =>
    c.SubCategories.find((s) =>
      s.Prompts.find(
        (p) =>
          p.AllowedModels.includes(props.model) &&
          (charGen ? p.IsCharGen === true : true)
      )
    )
  );

  // .filter(
  //   (c) => c.Title === 'Pose'
  // );
  const { prices } = useResourcesContext();
  const { capture } = useAnalyticsContext();
  const { user } = useUserContext();
  const { addModal } = useModalContext();
  const { NudityLevels } = useResourcesContext().mediaPrompts!;

  const [selectedCategory, setSelectedCategory] = useState(
    mediaPrompts![0].Title
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    mediaPrompts![0].SubCategories.filter((sub) =>
      sub.Prompts.find(
        (p) =>
          p.AllowedModels.includes(props.model) &&
          (charGen ? p.IsCharGen === true : true)
      )
    )[0]?.Title
  );
  const [selectedPropmpt, setSelectedPropmpt] = useState(
    ''
    // mediaPrompts![0].SubCategories[0].Prompts[0].Id
  );

  const filterSubcategories = (categoryTitle: string) => {
    return mediaPrompts!
      .find((c) => c.Title === categoryTitle)!
      .SubCategories.filter((sub) =>
        sub.Prompts.find(
          (p) =>
            p.AllowedModels.includes(props.model) &&
            (charGen ? p.IsCharGen === true : true)
        )
      );
  };

  const [selectedNudity, setSelectedNudity] = useState<NudityLevel | null>(
    null
  );
  const [isFilled, setIsFilled] = useState(false);
  // const [allowedStateNudityLevels, setAllowedStateNudityLevels] = useState<NudityLevel[]>([]);
  useEffect(() => {
    setIsFilled(!!selectedPropmpt && !!selectedNudity);
  }),
    [selectedPropmpt, selectedNudity];
  useEffect(() => {
    setSelectedNudity(null);
  }, [selectedCategory, selectedSubCategory, selectedPropmpt]);

  const allowedNudityLevels = mediaPrompts
    .find((c) => c.Title === selectedCategory)
    ?.SubCategories.find((s) => s.Title === selectedSubCategory)
    ?.Prompts.find((p) => p.Id === selectedPropmpt)?.AllowedNudityLevels;

  return (
    <motion.div
      className="w-100 h-100 d-flex flex-column justify-content-center"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      {/* rounded-3 */}
      <div className="px-6 pb-11 d-flex flex-column gap-4 h-100 text-white horny-bg_grey_opac">
        {/* <div>Это не Advanced!</div> */}
        <div className=" overflow-y-auto pt-10 horny-border-bottom-white horny-disable-scrollbar">
          <div className="mb-3 pt-11">
            <div className="fs-1">
              <b>{t('Select prompt')}</b>
            </div>
            <img
              src=""
              style={{ width: 0, height: 0 }}
              onError={() => {
                capture({
                  event: 'page_open',
                  data: {
                    page_name: 'image_prompts',
                  },
                });
              }}
            />
            <div>{description}</div>
          </div>
          <div>
            <div className="fs-3 mb-2 fw-bold">{t('Category')}</div>
            <div className="overflow-x-scroll horny-disable-scrollbar d-flex gap-3">
              {mediaPrompts.map((category) => {
                return (
                  <div
                    className="d-inline-block"
                    onClick={() => {
                      if (selectedCategory === category.Title) {
                        return;
                      }
                      setSelectedCategory(category.Title);
                      setSelectedSubCategory(
                        mediaPrompts
                          .find((c) => c.Title === category.Title)
                          ?.SubCategories.find((s) =>
                            s.Prompts.find(
                              (p) =>
                                p.AllowedModels.includes(props.model) &&
                                (charGen ? p.IsCharGen === true : true)
                            )
                          )?.Title || ''
                      );
                      setSelectedPropmpt('');
                      setSelectedNudity(null);
                    }}
                  >
                    <TypePreview
                      src={
                        filterSubcategories(category.Title)[0].Prompts.find(
                          (p) =>
                            p.AllowedModels.includes(props.model) &&
                            (charGen ? p.IsCharGen === true : true)
                        )!.Image[props.model]
                      }
                      size={TypePreviewSize.Small}
                      selected={selectedCategory === category.Title}
                    />
                    <div
                      className="text-center w-100  horny-text_medium pt-2"
                      style={{ maxWidth: 84 }}
                    >
                      {t(category.Title as any)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className="fs-3 mb-2 fw-bold">{t('Subcategory')}</div>

            <div className="overflow-x-scroll horny-disable-scrollbar d-flex gap-3">
              {filterSubcategories(selectedCategory).map((subCategory) => {
                return (
                  <div
                    className="d-inline-block"
                    onClick={() => {
                      if (selectedSubCategory === subCategory.Title) {
                        return;
                      }
                      setSelectedSubCategory(subCategory.Title);
                      setSelectedPropmpt('');
                      setSelectedNudity(null);
                    }}
                  >
                    <TypePreview
                      src={
                        subCategory.Prompts.find(
                          (p) =>
                            p.AllowedModels.includes(props.model) &&
                            (charGen ? p.IsCharGen === true : true)
                        )!.Image[props.model]
                      }
                      size={TypePreviewSize.Small}
                      selected={selectedSubCategory === subCategory.Title}
                    />
                    <div
                      className="text-center w-100  horny-text_medium pt-2"
                      style={{ maxWidth: 84 }}
                    >
                      {t(subCategory.Title as any)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className="fs-3 mb-2 fw-bold">{t('Pose')}</div>

            <div
              className="d-inline-block horny-valign-top justify-content-center overflow-y-scroll horny-disable-scrollbar"
              // style={{ marginRight: '-1rem', maxHeight: '300px' }}
            >
              {/* overflow-x-scroll d-flex gap-3 */}
              {mediaPrompts
                .find((c) => c.Title === selectedCategory)
                ?.SubCategories.find((s) => s.Title === selectedSubCategory)
                ?.Prompts.filter(
                  (prompt) =>
                    prompt.AllowedModels.includes(props.model) &&
                    (charGen ? prompt.IsCharGen === true : true)
                )
                .map((prompt) => {
                  return (
                    <div
                      className={`d-inline-block position-relative horny-valign-top me-3 mb-3 justify-content-center cursor-pointer`}
                      onClick={() => {
                        if (
                          prompt.IsPremium === true &&
                          !user?.MergedSubscription.Level
                        ) {
                          return addModal({
                            children: <Subscriptions source="pose_premium" />,
                          });
                        }

                        if (selectedPropmpt === prompt.Id) {
                          return;
                        }
                        // setAllowedNudityLevels(prompt.AllowedNudityLevels);
                        setSelectedPropmpt(prompt.Id);
                        setSelectedNudity(null);
                      }}
                    >
                      <TypePreview
                        src={prompt.Image[props.model]}
                        size={TypePreviewSize.Small}
                        selected={selectedPropmpt === prompt.Id}
                      />
                      <div
                        className="text-center horny-text_medium pt-2 text-break"
                        style={{ maxWidth: '84px' }}
                      >
                        {' '}
                        {t(prompt.Title as any)}
                      </div>
                      {prompt.IsPremium === true &&
                        !user?.MergedSubscription.Level && (
                          <div
                            className="position-absolute w-75 d-flex align-items-center px-2 py-1 rounded-2 horny-bg-dark_half"
                            style={{
                              top: 60,
                              left: '50%',
                              transform: 'translateX(-50%)',
                            }}
                          >
                            <img
                              src={getImgUrl(
                                'subscriptions/Premium.png?width=200'
                              )}
                              className="w-100"
                            />
                          </div>
                        )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="text-center ">
          <div>
            <div className="fs-3 mb-2 fw-bold">{t('Nudity')}</div>

            <div className="overflow-x-scroll d-flex gap-3 mb-2 horny-disable-scrollbar">
              {NudityLevels.map((nudityLevel) => {
                return (
                  <div
                    className={`d-inline-block ${allowedNudityLevels?.includes(nudityLevel.Title) ? '' : ' pe-none opacity-25 '}`}
                    onClick={() => setSelectedNudity(nudityLevel.Title)}
                  >
                    <TypePreview
                      src={nudityLevel.Image}
                      size={TypePreviewSize.VerySmall}
                      selected={selectedNudity === nudityLevel.Title}
                    />
                    <div
                      className="text-center w-100 horny-text_medium pt-2 text-break"
                      style={{ maxWidth: '60px' }}
                    >
                      {t(nudityLevel.Title as any)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <HornyButton
            height={HornyButtonHeight.Tall}
            {...(!isFilled ? { disabled: true } : {})}
            onClick={() => {
              if (!isFilled) {
                return;
              }

              props.onPick!({
                PromptId: selectedPropmpt,
                NudityLevel: selectedNudity!,
              });
            }}
            icon={IconSrc.Magic}
            text={props.buttonText || `${t('Generate cover images')}`}
            className="mx-auto"
            // {...(selectedNudity && selectedPropmpt
            //   ? {
            //       'data-anal': 'media_request',
            //       'data-anal-request_type': 'regenerate',
            //       'data-anal-media_type': 'image',
            //       'data-anal-is_own_bot': true,
            //       'data-anal-bot_id': null,
            //     }
            //   : {})}
          >
            {props.isGenerated && (
              <AddBlock className="horny-bg-dark_half">
                <Price amount={prices!.Media.Photo * props.multiplier} />
              </AddBlock>
            )}
          </HornyButton>
        </div>
      </div>
    </motion.div>
  );
}
