import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import {
  addClickMagicPurchase,
  addTrafficJunkyTag,
} from 'src/context/AnalyticsContext/helpersDOM';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import { UserAPI, UserAPIModel } from 'src/services/API/UserAPI';
import {
  SubscriptionProviderFlowProps,
  SubscriptionProviderFlowStage,
} from './SubscriptionProviderFlow';
 
export function BoostyProviderFlow(props: SubscriptionProviderFlowProps) {
  const { t } = useTranslation();
  const storedSelected = useMemo(() => {
    return props.selectedProvider;
  }, [props.selectedProvider]);
  const [stage, setStage] = useState<SubscriptionProviderFlowStage>(
    props.selectedProvider
      ? SubscriptionProviderFlowStage.ReadyToUpdate
      : SubscriptionProviderFlowStage.Init
  );
  const [refreshing, setRefreshing] = useState(false);
  const { setUser, user } = useUserContext();
  const { capture } = useAnalyticsContext();
  const emailRef = useRef<HTMLSpanElement>(null);
  const { prices } = useResourcesContext();

  useEffect(() => {
    if (
      props.selectedProvider === true &&
      !storedSelected &&
      stage === SubscriptionProviderFlowStage.StartedPayment
    ) {
      setStage(SubscriptionProviderFlowStage.ReadyToUpdate);
    }
  }, [props.selectedProvider]);
 
  const refresh = () => {
    if (refreshing) {
      return false;
    }
    setRefreshing(true);
    const storedUser = JSON.parse(JSON.stringify({ ...user })) as UserAPIModel;
    UserAPI.updateBoostyUser()
      .then(() =>
        UserAPI.get().then(async ({ data }) => {
          if (!storedUser?.Subscription.Level && data.Subscription.Level) {
            await addClickMagicPurchase(data.Subscription.Level).catch(
              console.error
            );

            //captureSubscriptionByTrafficJunky(data.Subscription.Level);
          }
          if (
            data.Subscription.Level !== storedUser?.Subscription.Level &&
            !!data.Subscription.Level
          ) {
            // capture({
            //   event: 'subscribe_process',
            //   data: {
            //     state: 'Boosty_verified',
            //     level: props.subscriptionLevel,
            //     income: prices!.Subscriptions[props.subscriptionLevel].Price,
            //   },
            // });
            // UserAPI.sendRevenue(
            //   prices!.Subscriptions[props.subscriptionLevel].Price
            // );
            await addTrafficJunkyTag(
              prices!.Subscriptions[props.subscriptionLevel].Price,
              props.subscriptionLevel
            );
            setUser(data);
          }
        })
      )
      .finally(() => {
        setStage(SubscriptionProviderFlowStage.ReadyToUpdate);
        setRefreshing(false);
      });
  };

  const addBoostyUser = () => {
    if (refreshing || !emailRef.current || !emailRef.current.innerText) {
      return;
    }
    setRefreshing(true);
    UserAPI.addBoostyUser(emailRef.current.innerText)
      .then(() =>
        UserAPI.get().then(({ data }) => {
          setUser(data);
        })
      )
      .finally(() => setRefreshing(false));
  };

  const stageElement = {
    [SubscriptionProviderFlowStage.Init]: (
      <>
        <HornyButton
          data-anal="subscribe_process"
          data-anal-state="Click_boosty"
          data-anal-level={props.subscriptionLevel}
          onClick={() => {
            window.open(
              `https://boosty.to/temptationsai/purchase/${props.subscriptionId}?ssource=DIRECT&share=subscription_link`,
              '_blank'
            );
            setStage(SubscriptionProviderFlowStage.StartedPayment);
          }}
          bg={HornyButtonBG.Red}
          height={HornyButtonHeight.Shorter}
          className="w-max-content"
        >
          <Icon src={IconSrc.Boosty} size={IconSize.Small} />
          {t('Subscribe with Boosty')}
        </HornyButton>
        <HornyButton
          onClick={() => {
            setStage(SubscriptionProviderFlowStage.StartedPayment);
          }}
          bg={HornyButtonBG.Red}
          height={HornyButtonHeight.Shorter}
          className="w-max-content"
        >
          <Icon src={IconSrc.Boosty} size={IconSize.Small} />
          {t('Already subscribed')}
        </HornyButton>
      </>
    ),

    [SubscriptionProviderFlowStage.StartedPayment]: (
      <div className="d-flex align-items-center gap-2">
        <div className="fw-bold">{t('Enter your Boosty email')}: </div>
        <div
          className="ms-2 align-items-center  px-4 text-white rounded-4   horny-bg-dark_opac"
          style={{ minWidth: '200px', height: 24 }}
        >
          <span
            contentEditable={'true'}
            ref={emailRef}
            className="d-flex h-100 w-100 horny-text_tiny lh-1 boosty_input align-items-center  position-relative"
          ></span>
        </div>
        <Icon
          className="cursor-pointer"
          src={IconSrc.Save}
          size={IconSize.Big}
          data-anal="subscribe_process"
          data-anal-state="Email_sent_boosty"
          data-anal-level={props.subscriptionLevel}
          onClick={() => {
            addBoostyUser();
          }}
        />
      </div>
    ),
    [SubscriptionProviderFlowStage.StartedVerification]: (
      <HornyButton
        onClick={() => refresh()}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
      >
        <Icon
          src={IconSrc.Reload}
          size={IconSize.Small}
          className={`${refreshing ? 'horny-spin' : ''}`}
        />
        {t('Refresh')}
      </HornyButton>
    ),
    [SubscriptionProviderFlowStage.ReadyToUpdate]: (
      <HornyButton
        data-anal="subscribe_process"
        data-anal-state="Click_upgrade_boosty"
        data-anal-level={props.subscriptionLevel}
        className="w-max-content"
        onClick={() => {
          window.open(
            `https://boosty.to/temptationsai/purchase/${props.subscriptionId}?ssource=DIRECT&share=subscription_link`,
            '_blank'
          );
          setStage(SubscriptionProviderFlowStage.StartedVerification);
        }}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
      >
        <Icon src={IconSrc.Boosty} size={IconSize.Small} />
        {t('Upgrade')}
      </HornyButton>
    ),
  };
  return stageElement[stage];
}
