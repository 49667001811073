import { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { isMobile } from 'src/shared/helpers';

const defaultRequestTextContent = '';
export enum InputPurpose {
  Place = 'Place',
  Text = 'Text',
}
export type RequestInputProps = {
  BotId: string;
  disabled: boolean;
  onSubmit: (text: string) => Promise<unknown>;
  placeholder: string;
  purpose: InputPurpose;
  IsBotAuthor: boolean;
  defaultValue: string;
};

export function RequestInput({
  disabled,
  onSubmit,
  placeholder,
  purpose,
  BotId,
  IsBotAuthor,
  defaultValue = '',
}: RequestInputProps) {
  const { t } = useTranslation();
  const [requestTextContent, setRequestTextContent] = useState(() => {
    return defaultValue;
  });
  const [shifPressed, setShiftPressed] = useState(false);
  const { capture } = useAnalyticsContext();
  const [rowsCount, setRowsCount] = useState(1);
  const handleInputRef = useRef<HTMLTextAreaElement | null>(null);
  const recalculateRows = (textareaElement: HTMLTextAreaElement) => {
    const style = window.getComputedStyle(textareaElement);
    const lineHeight = parseFloat(style.getPropertyValue('line-height'));
    const paddingTop = parseFloat(style.getPropertyValue('padding-top'));
    const paddingBottom = parseFloat(style.getPropertyValue('padding-bottom'));

    const totalHeight =
      textareaElement.scrollHeight - paddingTop - paddingBottom;

    const numberOfLines = totalHeight / lineHeight;
    if (numberOfLines > 5) {
      return;
    }
    setRowsCount(numberOfLines);
  };

  useEffect(() => {
    defaultValue &&
      defaultValue !== requestTextContent &&
      setRequestTextContent(defaultValue);
  }, [defaultValue]);
  // useEffect(() => {
  //   if (placeholder === 'Enter the place') {
  //     setTimeout(() => makeElementEditable(handleInputRef), 700);
  //   }
  // }, [placeholder]);

  const analytics = {
    [InputPurpose.Place]: {},
    [InputPurpose.Text]: {
      ['data-anal']: 'message_sent',
      ['data-anal-is_suggested']: 'true',
      ['data-anal-is_own_bot']: IsBotAuthor,
    },
  };

  const submitText = () => {
    return onSubmit(requestTextContent).then((res) => {
      capture({
        event: 'message_sent',
        data: {
          request_type: 'generate',
          is_own_bot: IsBotAuthor,
          is_suggested: false,
          bot_id: BotId,
        },
      });
      setRequestTextContent(defaultRequestTextContent);
      setRowsCount(1);
      setShiftPressed(false);
      return res;
    });
  };

  const detectShiftPressed = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Shift') {
      setShiftPressed(true);
    }
    return true;
  };

  const detectShiftUp = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Shift') {
      setShiftPressed(false);
    }
    return true;
  };
  const detectEnter = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      if (!isMobile() && shifPressed && !requestTextContent) {
        return false;
      }
      !disabled &&
        !isMobile() &&
        shifPressed &&
        requestTextContent &&
        submitText();
      return false;
    } else {
      return true;
    }
  };
  const [height, setHeight] = useState(40);
  return (
    <div
      className={`d-flex justify-content-center w-100 ${disabled ? 'pe-none opacity-75' : ''}`}
    >
      <InputGroup className="d-flex w-100 rounded-2 overflow-hidden position-relative z-0 justify-content-center horny-bg-gradient_orange_light  rounded-2">
        <Form.Control
          ref={handleInputRef}
          onChange={(e) => {
            setRequestTextContent(e.target.value);
            if (handleInputRef.current !== null) {
              recalculateRows(handleInputRef.current);
            }
            // if (e.target.offsetHeight === e.target.scrollHeight && e.target.value.length > requestTextContent.length){
            //   return;
            // }
            // setHeight(0);
            // setHeight(e.target.scrollHeight)
            // setTimeout(()=> setHeight(e.target.scrollHeight), 10);
          }}
          disabled={disabled}
          as="textarea"
          onKeyDown={(e) => {
            detectShiftPressed(e);
            detectEnter(e);
          }}
          onKeyUp={(e) => detectShiftUp(e)}
          // ref={requestTextMessageRef}
          // as={requestTextContent.length > 32 ? "textarea" : "input" }
          placeholder={
            isMobile() ? placeholder : t('Press Shift + Enter to send message')
          }
          rows={rowsCount}
          // {...(requestTextContent.length > 32 ? {rows: 5}: {})}
          value={requestTextContent}
          // defaultValue={defaultValue}
          style={{
            backgroundColor: 'transparent',
            width: 'calc(100% - 60px)',
            resize: 'none',
          }}
          aria-describedby="basic-addon1"
          className="horny-text_orange horny-input     border-0 horny-text_medium text-left p-4 horny-text_orange  horny-disable-scrollbar"
        ></Form.Control>
        <div
          className={`d-flex cursor-pointer align-items-center p-4 ${!requestTextContent ? ' pe-none opacity-50 ' : ''}`}
          onClick={() => {
            !disabled && submitText();
          }}
          // style={{ width: 75 }}
        >
          <Icon
            src={disabled ? IconSrc.Loader : IconSrc.send_orange}
            style={{ bottom: 0 }}
            className={`cursor-pointer ${disabled ? 'horny-spin' : ''}`}
            size={IconSize.Small}
            // {...analytics[purpose]}
            // onClick={() => {
            //   !disabled && submitText();
            // }}
            // onSubmit(requestTextContent).then((res) => {
            //   !!res && setRequestTextContent(defaultRequestTextContent);
            // });
          />
          {/* <div className="pe-none" style={{ width: 16, height: 0 }}></div> */}
        </div>
      </InputGroup>
    </div>
  );
}
