import { useState, useEffect, useRef } from 'react';
import useOnScreen from './useOnScreen';
import {
  ChatMessageAPIModel,
  ChatMessageStatus,
  ConnectionsAPI,
  GetChatResponse,
  RegenerateMessageParams,
  RequestMediaParams,
} from 'src/services/API/ConnectionsAPI';
import { ChatStatus } from './Chat';
import { MediaType } from 'src/services/API/MediaAPI';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useResourcesContext } from 'src/context/ResourcesContext';
const useMessages = (chatResponse: GetChatResponse) => {
  const [messages, setMessages] = useState<ChatMessageAPIModel[]>(
    chatResponse.Messages
  );
  const { capture } = useAnalyticsContext();
  const [lastMessageRef, setLastMessageRef] = useState(null);
  const isIntersecting = useOnScreen({ current: lastMessageRef });
  const [total, setTotal] = useState(chatResponse.Total);
  const [status, setStatus] = useState<ChatStatus>(ChatStatus.Init);
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const intervalUserChoiceRef = useRef<NodeJS.Timer | null>(null);

  const [listeningForPending, setListeningForPending] = useState(
    !!chatResponse.Messages.find(
      (m) => m.MessageStatus !== ChatMessageStatus.ready
    )
  );
  const [listeningForChatInfo, setListeningForChatInfo] = useState(
    !!chatResponse.ChatInfo.MediaInProgress
  );

  const [mediaInProgress, setMediaInProgress] = useState<number>(
    chatResponse.ChatInfo.MediaInProgress
  );
  const [userChoiceIsReady, setUserChoiceIsReady] = useState<boolean>(
    !!chatResponse.ChatInfo.IsResponsesReady
  );

  const textIsPending = useRef(
    !!chatResponse.Messages.find(
      (m) => m.MessageStatus === ChatMessageStatus.preparing
    )
  );
  const mediaIsPending = useRef(
    !!chatResponse.Messages.find(
      (m) => m.MessageStatus === ChatMessageStatus.pendingLink
    )
  );
  const pendingMessagesListening = useRef(false);
  const chatInfoListening = useRef(false);
  const [userResponses, setUserResponses] = useState<string[]>(
    chatResponse.ChatInfo.UserResponseChoices
  );

  const requestTextMessage = async (text: string) => {
    if (textIsPending.current) {
      return false;
    }
    textIsPending.current = true;
    const prevStatus = status;
    setStatus(ChatStatus.WaitingText);
    return ConnectionsAPI.requestText({
      BotId: chatResponse.Chat.BotId,
      Text: text,
    })
      .then(({ data }) => {
        setMessages([...(messages || []), ...data.Messages]);
        setUserChoiceIsReady(false);
        return true;
      })
      .catch(() => {
        setStatus(prevStatus);
        // mediaIsPending.current = false;
        return false;
      });
  };
  const { prices } = useResourcesContext();
  const pricesMatch = {
    [MediaType.Photo]: prices!.Media.Photo,
    [MediaType.Video]: prices!.Media.Video,
    [MediaType.Audio]: prices!.Media.Audio,
  };
  const requestMedia = async (
    data: RequestMediaParams,
    mediaType: MediaType,
    random?: boolean
  ) => {
    if (status === ChatStatus.Waiting) {
      return false;
    }
    const prevStatus = status;
    setStatus(ChatStatus.Waiting);
    const mediaRequestMethod = {
      [MediaType.Photo]: () => ConnectionsAPI.requestPhoto(data),
      [MediaType.Video]: () => ConnectionsAPI.requestVideo(data),
      [MediaType.Audio]: () => ConnectionsAPI.requestAudio(data),
    }[mediaType];

    return mediaRequestMethod()
      .then(({ data }) => {
        capture({
          event: 'media_request',
          data: {
            request_type: random ? 'random' : 'generate',
            is_own_bot: chatResponse.Chat.IsBotAuthor,
            is_suggested: false,
            bot_id: chatResponse.Chat.BotId,
            media_type: mediaType,
          },
        });
        capture({
          event: 'currency_spent',
          data: {
            amount: pricesMatch[mediaType],
            spent_on: mediaType,
          },
        });
        setMediaInProgress((mediaInProgress || 0) + 1);
        setMessages([...(messages || []), ...data.Messages]);
        setStatus(prevStatus);
        //  setUserChoiceIsReady(false);
        mediaIsPending.current = true;
        return true;
      })
      .catch(() => {
        setStatus(prevStatus);
        // mediaIsPending.current = false;

        return false;
      });
  };

  const requestRandomMedia = async (
    messageId: string,
    mediaType: MediaType
  ) => {
    if (status === ChatStatus.Waiting) {
      return false;
    }
    const prevStatus = status;
    setStatus(ChatStatus.Waiting);
    const data = {
      MessageId: messageId,
      BotId: chatResponse.Chat.BotId,
    };
    const mediaRequestMethod = {
      [MediaType.Photo]: () => ConnectionsAPI.generateRandomImage(data),
      [MediaType.Video]: () => ConnectionsAPI.generateRandomImage(data),
      [MediaType.Audio]: () => ConnectionsAPI.generateRandomImage(data),
    }[mediaType];
    return mediaRequestMethod()
      .then(({ data }) => {
        capture({
          event: 'media_request',
          data: {
            request_type: 'random',
            is_own_bot: chatResponse.Chat.IsBotAuthor,
            is_suggested: false,
            bot_id: chatResponse.Chat.BotId,
            media_type: mediaType,
          },
        });
        capture({
          event: 'currency_spent',
          data: {
            amount: pricesMatch[mediaType],
            spent_on: mediaType,
          },
        });
        setMediaInProgress((mediaInProgress || 0) + 1);
        setMessages([...(messages || []), ...data.Messages]);
        setStatus(prevStatus);
        //  setUserChoiceIsReady(false);
        mediaIsPending.current = true;
        return true;
      })
      .catch(() => {
        setStatus(prevStatus);
        // mediaIsPending.current = false;

        return false;
      });
  };

  const regenerateMedia = async (
    data: RegenerateMessageParams,
    mediaType: MediaType
  ) => {
    if (status === ChatStatus.Waiting) {
      return false;
    }
    const prevStatus = status;
    setStatus(ChatStatus.Waiting);
    const mediaRequestMethod = {
      [MediaType.Photo]: () => ConnectionsAPI.regeneratePhoto(data),
      [MediaType.Video]: () => ConnectionsAPI.regeneratePhoto(data),
      [MediaType.Audio]: () => ConnectionsAPI.regeneratePhoto(data),
    }[mediaType];
    return mediaRequestMethod()
      .then(({ data }) => {
        capture({
          event: 'media_request',
          data: {
            request_type: 'regenerate',
            is_own_bot: chatResponse.Chat.IsBotAuthor,
            is_suggested: false,
            bot_id: chatResponse.Chat.BotId,
            media_type: mediaType,
          },
        });

        capture({
          event: 'currency_spent',
          data: {
            amount: pricesMatch[mediaType] / 2,
            spent_on: mediaType,
            regenerate: true,
          },
        });

        setMediaInProgress((mediaInProgress || 0) + 1);
        setMessages([...(messages || []), ...data.Messages]);
        setStatus(prevStatus);
        //  setUserChoiceIsReady(false);
        mediaIsPending.current = true;
        return true;
      })
      .catch(() => {
        setStatus(prevStatus);
        //  setUserChoiceIsReady(false);
        return false;
      });
  };
  const regenerateText = async (params: RegenerateMessageParams) => {
    if (textIsPending.current) {
      return false;
    }
    textIsPending.current = true;
    setStatus(ChatStatus.WaitingText);
    return ConnectionsAPI.regenerateText(params)
      .then(({ data }) => {
        capture({
          event: 'message_sent',
          data: {
            request_type: 'regenerate',
            is_own_bot: chatResponse.Chat.IsBotAuthor,
            is_suggested: false,
            bot_id: chatResponse.Chat.BotId,
          },
        });
        setMessages([
          ...(messages || []).map((m) => {
            if (m.Id === params.MessageId) {
              m.MessageStatus = ChatMessageStatus.preparing;
            }
            return m;
          }),
        ]);
        setUserChoiceIsReady(false);
        return true;
      })
      .catch(() => {
        // mediaIsPending.current = false;

        return false;
      });
  };

  // useEffect(() => {
  //   if (status === ChatStatus.Init && !userChoiceInitRef.current){
  //     userChoiceInitRef.current = true;
  //     ConnectionsAPI.getChatInfo({BotId: botId});
  //   }
  // })

  useEffect(() => {
    if (listeningForPending && !pendingMessagesListening.current) {
      // setListeningForPending(false);
      // userChoiceIsPending.current = false;
      pendingMessagesListening.current = true;
      const pendingMediaMessagesIds = (messages || [])
        .filter(
          ({ MessageStatus }) => MessageStatus == ChatMessageStatus.pendingLink
        )
        .map(({ Id }) => Id);
      if (pendingMediaMessagesIds.length === 0) {
        // setListeningForPending(false);
        // pendingMessagesListening.current = false;
        mediaIsPending.current = false;
        // textIsPending.current = false;
        // return;
      }
      const pendingTextMessagesIds = (messages || [])
        .filter(
          ({ MessageStatus }) => MessageStatus == ChatMessageStatus.preparing
        )
        .map(({ Id }) => Id);

      if (pendingTextMessagesIds.length === 0) {
        // setListeningForPending(false);
        // pendingMessagesListening.current = false;
        // mediaIsPending.current = false;
        textIsPending.current = false;
        // return;
      }

      if (!pendingMediaMessagesIds.length && !pendingTextMessagesIds.length) {
        setListeningForPending(false);
        pendingMessagesListening.current = false;
        return;
      }

      ConnectionsAPI.getPendingMessages({
        BotId: chatResponse.Chat.BotId,
        MessageId: [...pendingMediaMessagesIds, ...pendingTextMessagesIds],
      }).then(({ data }) => {
        const currentMessages = [...(messages || [])];
        let fetchedMessages = [...data.MessagePairs];

        const messagesToAppend: typeof messages = [];

        currentMessages.forEach(({ Id: currentMessageId }, i) => {
          const matchingFetchedElemnt = fetchedMessages.find(
            ({ Message: { Id: fetchedMessageId } }) =>
              currentMessageId === fetchedMessageId
          );
          if (
            matchingFetchedElemnt &&
            matchingFetchedElemnt.Message.MessageStatus ===
              ChatMessageStatus.ready
          ) {
            currentMessages[i] = matchingFetchedElemnt.Message;
            if (matchingFetchedElemnt.LinkedMessage) {
              messagesToAppend.push(matchingFetchedElemnt.LinkedMessage);
            }
          }
        });
        const mergedeMessages = [...currentMessages, ...messagesToAppend];
        setMessages(mergedeMessages);
        // setListeningForPending(false);
        if (
          !mergedeMessages.find(
            ({ MessageStatus }) =>
              MessageStatus === ChatMessageStatus.pendingLink ||
              MessageStatus === ChatMessageStatus.preparing
          )
        ) {
          setStatus(ChatStatus.Idle);
        }
        setListeningForPending(false);
        pendingMessagesListening.current = false;
      });
    }
  });

  useEffect(() => {
    if (listeningForChatInfo && !chatInfoListening.current) {
      // setListeningForChatInfo(false);
      // userChoiceIsPending.current = false;
      chatInfoListening.current = true;
      if (
        !userChoiceIsReady ||
        mediaInProgress === null ||
        mediaInProgress > 0
      ) {
        // console.log({
        //   userChoiceIsReady,
        //   mediaInProgress,
        // });
        ConnectionsAPI.getChatInfo({
          BotId: chatResponse.Chat.BotId,
        })
          .then(({ data: { ChatInfo } }) => {
            setMediaInProgress(ChatInfo.MediaInProgress);
            if (
              ChatInfo.IsResponsesReady ||
              ChatInfo.IsResponsesReady === undefined
            ) {
              setUserResponses(ChatInfo.UserResponseChoices);
              setUserChoiceIsReady(true);
            }
          })
          .finally(() => {
            setListeningForChatInfo(false);
            chatInfoListening.current = false;
          });
      } else {
        setListeningForChatInfo(false);
        chatInfoListening.current = false;
      }
    }
  });

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      //  if (!listeningForPending && (mediaInProgress || !userChoiceIsReady)){
      //   userChoiceIsPending.current = true;
      setListeningForPending(true);
      // }
    }, 1000 * 4);
    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    intervalUserChoiceRef.current = setInterval(() => {
      setListeningForChatInfo(true);
    }, 1000 * 5);

    return () => {
      intervalUserChoiceRef.current &&
        clearInterval(intervalUserChoiceRef.current);
    };
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      const prevStatus = status;
      setStatus(ChatStatus.Loading);
      ConnectionsAPI.getMessages({
        StartFrom: (messages || []).length,
        PageSize: 40,
        BotId: chatResponse.Chat.BotId,
      })
        .then((res) => {
          // console.log({ res });
          return res;
        })
        .then(({ data }) => {
          setTotal(data.Total);
          setMessages(data.Messages.concat(messages || []));
          setStatus(ChatStatus.Idle);
        })
        .catch(() => {
          setStatus(prevStatus);
        });
      // setMessages(async (old) =>   ConnectionsAPI.getMessages({from: messages.length, size: 10}).then(({data}) => data.messages.concat(old)));
    }
  }, [isIntersecting]);

  return {
    messages,
    setMessages,
    requestTextMessage,
    setLastMessageRef,
    total,
    status,
    requestMedia,
    mediaInProgress,
    userChoiceIsReady,
    userResponses,
    mediaIsPending,
    regenerateMedia,
    regenerateText,
    requestRandomMedia,
    textIsPending,
  };
};

export default useMessages;
