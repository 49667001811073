import { AxiosResponse, AxiosError } from 'axios';
import {
  createContext,
  useState,
  useContext,
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import { Modal, ModalProps } from 'src/components/Layout/Modal/Modal';
import {
  ShowSubscriptionsReason,
  Subscriptions,
} from 'src/components/Subscription/Subscriptions';
import { getAxiosInstance } from 'src/services/axios/axios';
import { useAnalyticsContext } from './AnalyticsContext/AnalyticsContext';
import { useUserContext } from './User.context';
import { useNotificationsContext } from './Notifications.Context';
import { NotificationType } from 'src/components/Layout/Notification/Notification';
import { Authorization } from 'src/components/Authorization/Authorization';

export type AddModalProps = Omit<ModalProps, 'timestamp'>;
export type AddModalFunc = (modal: AddModalProps) => any;
export type ModalContextProps = {
  modals: ModalProps[];
  addModal: AddModalFunc;
  removeModal: (timestamp: number) => any;
  closeTopModal: () => any;
};
export const ModalContext = createContext<ModalContextProps>({
  modals: [],
  addModal: (modal: AddModalProps) => {},
  removeModal: (timestamp: number) => {},
  closeTopModal: () => {},
});

export const ModalContextProvider = ModalContext.Provider;
export const useModalContext = () =>
  useContext<ModalContextProps>(ModalContext);
const axiosInstance = getAxiosInstance();

export const ModalProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const [modals, setModals] = useState<ModalProps[]>([]);
  const { setPurchaseFrom, capture } = useAnalyticsContext();
  const { user } = useUserContext();
  const { addNotification } = useNotificationsContext();

  const responseCatcherIsSetUp = useRef(false);
  useEffect(() => {
    if (!responseCatcherIsSetUp.current) {
      responseCatcherIsSetUp.current = true;
      axiosInstance.interceptors.response.use(
        (res: AxiosResponse<any>) => res,
        (error: AxiosError) => {
          if (Number(error.response?.status) === 402) {
            const actionFrom = errorUrlsSourceMatch[error.request.url];
            actionFrom && setPurchaseFrom(actionFrom);
            const reason = (error.response?.data as string)?.includes(
              'messages'
            )
              ? ShowSubscriptionsReason.Messages
              : ShowSubscriptionsReason.Energy;
            if (
              reason === ShowSubscriptionsReason.Messages &&
              user &&
              user.MergedSubscription.Level !== null
            ) {
              addNotification({
                type: NotificationType.Error,
                message:
                  'Some error occured while processing your message. Please contact us to work it out.',
                timestamp: Date.now(),
              });
              throw error;
            }
            addModal({
              showBalance: false,
              onClose: () => setPurchaseFrom('none'),
              children:
                user?.IsAnonymous === true ? (
                  <Authorization showReason={reason} />
                ) : (
                  <Subscriptions source="user_balance" showReason={reason} />
                ),

              className:
                user?.IsAnonymous === true
                  ? 'z-50 horny-bg-gradient_red'
                  : 'horny-bg-dark_opac',
            });

            // addModal({
            //   children: <Subscriptions showReason={reason} />,
            //   className: 'horny-bg-dark_opac',
            //   showBalance: false,
            //   onClose: () => setPurchaseFrom('none'),
            // });
          }

          throw error;
        }
      );
    }
  });
  const addModal = (modal: AddModalProps) => {
    // typeof modal.children.type === 'function' &&
    //   capture({
    //     event: 'page_open',
    //     data: { page_name: modal.children.type.name },
    //   });

    const timestamp = Date.now();
    setModals((prevState) => [
      ...prevState,
      {
        ...modal,
        className: modal.className || 'horny-bg-dark_opac',
        timestamp,
      },
    ]);
    return timestamp;
  };

  const closeTopModal = () => {
    setModals((prevState) => [...prevState.slice(0, prevState.length - 1)]);
  };

  const removeModal = (timestamp: number) =>
    setModals((prevState) =>
      [...prevState].filter((modal) => modal.timestamp !== timestamp)
    );

  return (
    <ModalContextProvider
      value={{
        modals,
        addModal: (modal) => addModal(modal),
        removeModal: (timestamp) => removeModal(timestamp),
        closeTopModal: () => closeTopModal(),
      }}
    >
      {modals.map((modal) => (
        <Modal {...modal} key={modal.timestamp} />
      ))}
      {children}
    </ModalContextProvider>
  );
};

// const pageOpenMatch = {
//     Media_my (медиа мои)
//     Media_community (медиа комьюнити)
//     Settings (настройки)
//     Subscriptions (окно покупки премиума)
//     Characters_my (connections → my characters)
//     Characters_all (connections → all characters)
//     Media_fullscreen (когда на полный экран открываешь фото / аудио / видео)
//     Create_character_personality
//     Create_character_story
//     Create_character_model
//     Create_character_appearance
//     Create_character_pose
//     Create_character_final
// }

const errorUrlsSourceMatch: Record<string, string> = {
  createcharacterdraft: 'Char_generation',
  generatebotimage: 'Media_regeneration',
  requestimage: 'Media_regeneration',
  requestvideo: 'Media_regeneration',
  requestaudio: 'Media_regeneration',
  regenerateimage: 'Media_request',
  addtextmessage: 'No_free_messages',
  regeneratetextmessage: 'No_free_messages',
};
