import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Authorization } from 'src/components/Authorization/Authorization';
import { FindCharacter } from 'src/components/Discover/FindCharacter';
import { AddBlock } from 'src/components/Shared/AddBlock/AddBlock';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { Price } from 'src/components/Shared/Price/Price';
import { Timer } from 'src/components/Shared/Timer/Timer';
import { Subscriptions } from 'src/components/Subscription/Subscriptions';
import { UserProfile } from 'src/components/UserProfile/UserProfile';
import { useModalContext } from 'src/context/Modal.Context';
import { useUserContext } from 'src/context/User.context';
import './index.css';

export type HeaderProps = {
  Energy: number;
};

export type HeaderProperties = {
  showLogin?: boolean;
};

export function Header({ showLogin = true }: HeaderProperties) {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { addModal } = useModalContext();
  const [balanceShown, setBalanceShown] = useState(true);
  const subscribeButton = (
    <motion.div
      whileTap={{ scale: 0.8 }}
      className=" horny-shadow horny-bg-gradient_red rounded-5 align-items-center py-1 px-8 d-flex gap-2 fw-bold"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        addModal({
          showBalance: false,

          children: user!.IsAnonymous ? (
            <Authorization />
          ) : (
            <Subscriptions source="user_balance" />
          ),

          className: user!.IsAnonymous
            ? 'z-50 horny-bg-gradient_red'
            : 'horny-bg-dark_opac',
        });
      }}
    >
      <Icon src={IconSrc.Premium} size={IconSize.Smaller} />
      {t('Subscribe')}
    </motion.div>
  );

  const innerContainerRef = useRef<HTMLDivElement>(null);
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const innerContainer = innerContainerRef.current;
      const outerContainer = outerContainerRef.current;
      if (innerContainer && outerContainer) {
        setTimeout(() => {
          const hasOverflow =
            innerContainer.clientWidth > outerContainer.clientWidth;
          console.log(
            'inner:',
            innerContainer.scrollWidth,
            'outer:',
            outerContainer.clientWidth,
            'hasOverflow:',
            hasOverflow
          );
          setIsOverflow(hasOverflow);
        }, 10); // 100 мс задержка
      }
    };

    checkOverflow();
  }, []);

  return (
    <header
      className="position-relative z-10 w-max-content text-end py-6 ps-0 pe-6 d-flex justify-content-end top-0 overflow-x-hidden"
      style={{ right: 0 }}
      ref={outerContainerRef}
    >
      {!user ? (
        showLogin ? (
          <AddBlock
            className="horny-bg-dark_half cursor-pointer"
            onClick={() => {
              addModal({
                children: <Authorization />,
                bg: 'bg-danger',
                className: 'z-50 horny-bg-gradient_red',
              });
            }}
          >
            <div className="fw-bold">
              <span>{t('Log In')}</span>
            </div>
          </AddBlock>
        ) : null
      ) : (
        <div className="d-flex gap-3" ref={innerContainerRef}>
          {!isOverflow &&
            user &&
            user.MergedSubscription.Level === null &&
            subscribeButton}
          <AddBlock
            className="horny-bg-dark_half fs-5 d-flex align-items-center cursor-pointer"
            onClick={() => {
              addModal({
                children: user.IsAnonymous ? (
                  <Authorization />
                ) : (
                  <Subscriptions source="user_balance" />
                ),
                className: user.IsAnonymous
                  ? 'z-50 horny-bg-gradient_red'
                  : 'horny-bg-dark_opac',
                showBalance: false,
              });
            }}
          >
            {user.Energy <= 0 ? (
              user.IsAnonymous ? (
                <div className="d-flex horny-text_tiny align-items-center gap-1 cursor-pointer">
                  {t('Sign up to get more')}{' '}
                  <Icon src={IconSrc.Energy} size={IconSize.Tiny} />
                </div>
              ) : (
                <div className="d-flex horny-text_tiny align-items-center gap-1 cursor-pointer">
                  {t('More')} <Icon src={IconSrc.Energy} size={IconSize.Tiny} />{' '}
                  {t('in')}
                  &nbsp; <Timer onTimeReach={console.log} />
                </div>
              )
            ) : (
              <Price
                header={true}
                amount={user.Energy}
                size={IconSize.Small}
                className="fs-5"
              />
            )}
          </AddBlock>
        </div>
      )}
    </header>
  );
}

export function ProfileHeader() {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { addModal } = useModalContext();
  return user ? (
    user.IsAnonymous ? (
      <header
        className="position-relative z-1 w-max-content text-start py-6 ps-6 pe-0 d-flex justify-content-end top-0 profile-header"
        style={{ left: 0 }}
      >
        <AddBlock
          className="horny-bg-dark_half cursor-pointer"
          onClick={() => {
            addModal({
              children: <Authorization />,
              bg: 'bg-danger',
              className: 'z-50 horny-bg-gradient_red',
            });
          }}
        >
          <div className="fw-bold">
            <span>{t('Log In')}</span>
          </div>
        </AddBlock>
      </header>
    ) : (
      <header
        className="position-relative z-1 w-max-content text-end py-6 ps-6 pe-0 d-flex justify-content-end top-0 profile-header"
        style={{ left: 0 }}
      >
        <AddBlock
          className="horny-bg-dark_half cursor-pointer"
          onClick={() => {
            addModal({
              children: <UserProfile user={user} />,
              className: 'horny-bg-dark_opac',
            });
          }}
        >
          <Icon src={IconSrc.Settings} size={IconSize.Small} />
        </AddBlock>
        <AddBlock
          className="horny-bg-dark_half cursor-pointer ms-2"
          onClick={() => {
            addModal({
              children: <FindCharacter />,
              className: 'horny-bg-dark_opac',
            });
          }}
        >
          <Icon src={IconSrc.Find} size={IconSize.Small} />
        </AddBlock>
      </header>
    )
  ) : null;
}

export function CloseHeader(props: HeaderProps) {
  return (
    <header
      className="position-absolute  w-100 text-end py-6 ps-0 pe-6  d-flex justify-content-end top-0 z-10"
      style={{ left: 0 }}
    >
      <AddBlock className="horny-bg-dark_half">
        <Price amount={props.Energy || 0} />
      </AddBlock>
    </header>
  );
}
export type HeaderContainerProps = {
  absolute?: boolean;
};
export function HeaderContainer({ absolute = false }: HeaderContainerProps) {
  return (
    <div
      className={`position-${absolute ? 'absolute' : 'sticky'} d-flex w-100 justify-content-between z-1`}
    >
      <ProfileHeader />
      <Header />
    </div>
  );
}
