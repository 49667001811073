import { motion } from 'framer-motion';
import { Trans } from 'react-i18next';
import { Authorization } from 'src/components/Authorization/Authorization';
import { AddBlock } from 'src/components/Shared/AddBlock/AddBlock';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { Timer } from 'src/components/Shared/Timer/Timer';
import { Subscriptions } from 'src/components/Subscription/Subscriptions';
import { useModalContext } from 'src/context/Modal.Context';
import { useUserContext } from 'src/context/User.context';

export type SubProposalProps = {
  onClose: () => any;
};

export const subProposalStorageKey = 'showSubProposal';
export function SubProposal({ onClose }: SubProposalProps) {
  const { addModal } = useModalContext();
  const { user } = useUserContext();
  return (
    <div
      className="w-100 px-6 d-flex gap-6 justify-content-between align-items-center  horny-bg_grey border-top border-white"
      style={{ height: 60 }}
    >
      <div></div>
      <motion.div
        whileTap={{ scale: 0.8 }}
        className="d-flex gap-4  horny-shadow horny-bg-gradient_red rounded-5 align-items-center px-5 py-2 cursor-pointer"
        onClick={() => {
          if (user?.IsAnonymous) {
            return addModal({
              showBalance: false,
              children: <Authorization />,
              className: 'z-50 horny-bg-gradient_red',
            });
          }
          addModal({
            children: <Subscriptions source="offer_button" />,
            showBalance: false,
          });
        }}
      >
        <Icon src={IconSrc.Premium} size={IconSize.Medium} />
        <div className="d-flex gap-2 fw-bold horny-text_tiny">
          <Trans i18nKey="Premium up to" components={[<u />]} />
          {/* // Premium up to // <u>33% Off</u> */}
        </div>
        <AddBlock className="horny-bg-dark_half px-2 py-1 horny-text_tiny fw-bold">
          <Timer onTimeReach={() => {}} />
        </AddBlock>
      </motion.div>
      <motion.div
        onClick={() => onClose()}
        className="cursor-pointer"
        whileTap={{ scale: 0.8 }}
      >
        <Icon src={IconSrc.exit} size={IconSize.XSmall} />
      </motion.div>
    </div>
  );
}
