import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { IconSrc } from 'src/components/Shared/Icon/Icon';
import {
  CharacterAPI,
  CharacterGenerationDraftAPIModel,
  CharacterGenerationStage,
  CharaterGenerationModelAPIModel,
  ImageGenerationModel,
  ImageGenerationModelType,
} from 'src/services/API/CharacterAPI';
import { OnChoiceCallback } from '../CharacterGeneration';
import {
  TypePreview,
  TypePreviewSize,
} from '../Shared/TypePreview/TypePreview';

export type ConfigureImageGenerationModelProps = {
  onChoice: OnChoiceCallback;
  onForward?: (() => any) | null;
  fetched: ImageGenerationModel | null;
  onBack: () => any;
};

export function ConfigureImageGenerationModel({
  onBack,
  onChoice,
  onForward,
  fetched,
}: ConfigureImageGenerationModelProps) {
  const { t } = useTranslation();
  const fetchedRef = useRef(false);
  const [models, setModels] = useState<
    CharaterGenerationModelAPIModel[] | null
  >(null);

  const [selected, setSelected] = useState<ImageGenerationModelType | null>(
    fetched
  );
  const updateDraft = () => {
    if (!selected) {
      return;
    }

    const stage = CharacterGenerationStage.ImageGenerationModel;
    const data: CharacterGenerationDraftAPIModel[typeof stage] = selected;
    onChoice(stage, data);
  };
  const getPhotoElement = ({
    Image,
    Title,
    Available,
  }: CharaterGenerationModelAPIModel) => {
    return (
      <div
        className={`position-relative  d-flex align-items-center justify-content-center flex-column gap-2 ${!Available ? 'pe-none ' : ''}`}
        onClick={() => setSelected(Title)}
        style={{ width: 'calc(50% - 10px)' }}
      >
        {/* <div className={`${!Available ? ' pe-none opacity-75 ' : ''}`}> */}
        <TypePreview
          src={Image}
          size={TypePreviewSize.Big}
          selected={selected === Title}
          className={!Available ? 'opacity-50' : ''}
        />
        {/* </div> */}
        <div className="fw-bold">{t(Title as any)}</div>
        {!Available && (
          <>
            <div className="position-absolute w-100 h-100 d-flex flex-column justify-items-center align-items-center top-0 justify-content-center"></div>
            {<div className="m-auto position-absolute">{t('Coming soon')}</div>}
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!fetchedRef.current) {
      fetchedRef.current = true;
      CharacterAPI.getAppearanceModels()
        .then(({ data }) => {
          setModels(data);
        })
        .finally(() => {});
    }
  });
  return (
    <div className="w-100 d-flex flex-column gap-2 justify-content-between h-100 text-start">
      <div className="overflow-y-auto  pt-20 horny-border-bottom pb-3 h-100 horny-disable-scrollbar">
        <div className="d-flex flex-column gap-3 position-relative">
          <div className="fs-1 fw-bold">{t('Model')}</div>
          <div className="opacity-75">
            {t('Select the desired visual style for your AI girlfriend.')}
          </div>
          <div className="d-flex gap-2 justify-content-between">
            {models &&
              models.slice(0, 2).map((model) => getPhotoElement(model))}
          </div>
          <div className="d-flex gap-2 justify-content-between">
            {models && models.slice(2).map((model) => getPhotoElement(model))}
          </div>
        </div>
      </div>

      {
        <div className=" mx-auto  d-flex gap-4 flex-column align-items-end pb-4">
          {/* {onForward && (
            <HornyButton
              height={HornyButtonHeight.Tall}
              onClick={() => onForward()}
              icon={IconSrc.Next}
              text="Keep this model"
              className="mx-auto"
            ></HornyButton>
          )} */}

          <HornyButton
            height={HornyButtonHeight.Tall}
            {...(!selected ? { disabled: true } : {})}
            onClick={() => updateDraft()}
            icon={IconSrc.Next}
            text={t('Choose appearance')}
            className="mx-auto"
            //   {...{
            //     'data-anal': 'media_request',
            //     'data-anal-request_type': 'regenerate',
            //     'data-anal-media_type': 'image',
            //     'data-anal-is_own_bot': true,
            //     'data-anal-bot_id': null,
            //   }}
          ></HornyButton>

          <div className="mx-auto cursor-pointer  fw-bold text-decoration-underline">
            <u onClick={onBack}>{t('Back')}</u>
          </div>
        </div>
      }
    </div>
  );
}
