import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { AddBlock } from 'src/components/Shared/AddBlock/AddBlock';
import { Icon, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { MediaType } from 'src/services/API/MediaAPI';
import './index.css';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';

export enum ReplayContent {
  Text = 'text',
  Media = 'media',
}

export type ReplayProps = {
  onReplay: () => any;
  disabled: boolean;
  content: ReplayContent;
  mediaType?: MediaType;
  IsBotAuthor: boolean;
  BotId: string;
  isRegenerating: boolean;
};
export function Replay({
  onReplay,
  disabled,
  content,
  mediaType,
  IsBotAuthor,
  BotId,
  isRegenerating,
}: ReplayProps) {
  const { prices } = useResourcesContext();

  // const [searchParams] = useSearchParams();
  // console.log({mediaType, prices});

  const pricesMatch = mediaType
    ? {
        [MediaType.Audio]: prices!.Media['Audio'],
        [MediaType.Video]: prices!.Media['Video'],
        [MediaType.Photo]: prices!.Media['Photo'],
      }[mediaType]
    : 0;
  //   content === ReplayContent.Text ? 0 :0;
  const [regenerating, setRegenerating] = useState(false);
  return (
    <AnimatePresence>
      <motion.div
        whileTap={{ scale: 0.8 }}
        initial="initial"
        animate="in"
        transition={TransSpringPopup}
        variants={AnimParamInOutOpacityY100}
        className={`d-flex flex-column justify-content-center horny-gap_tiny ${content === ReplayContent.Text ? 'ps-2' : ''} ${regenerating || disabled ? 'opacity-75 pe-none' : 'cursor-pointer'}`}
      >
        <div
          className="d-flex justify-content-center"
          {...(content === ReplayContent.Media
            ? {
                'data-anal': 'media_request',
                'data-anal-request_type': 'regenerate',
                'data-anal-media_type': mediaType!.toLowerCase(),
                'data-anal-is_own_bot': IsBotAuthor,
                'data-anal-bot_id': BotId,
              }
            : {
                'data-anal': 'message_sent',
                'data-anal-request_type': 'regenerate',
                'data-anal-is_own_bot': IsBotAuthor,
                'data-anal-bot_id': BotId,
              })}
          onClick={() => {
            setRegenerating(true);
            onReplay().then(() => setRegenerating(false));
          }}
        >
          <Icon
            className={`replay_icon ${regenerating || isRegenerating ? 'horny-spin' : ''}`}
            src={IconSrc.Reload}
          />
        </div>
        {content !== ReplayContent.Text && (
          <AddBlock icon={IconSrc.Energy}>
            <div className="text-white horny-text_small d-inline-block align-baseline">
              {pricesMatch / 2}
            </div>
          </AddBlock>
        )}
      </motion.div>
    </AnimatePresence>
  );
}
