import { useRef, useLayoutEffect, useMemo } from 'react';

const useKeepScrollPosition = (deps: any[] = []) => {
  const containerRef = useRef(null);
  const previousScrollPosition = useRef(0);

  useMemo(() => {
    if (containerRef?.current) {
      const container = containerRef?.current;
      previousScrollPosition.current =
        (container as HTMLElement)?.scrollHeight -
        (container as HTMLElement)?.scrollTop;
    }
  }, [...deps]);

  useLayoutEffect(() => {
    if (containerRef?.current) {
      const container = containerRef?.current || {};
      (container as HTMLElement).scrollTop =
        (container as HTMLElement)?.scrollHeight -
        previousScrollPosition.current;
    }
  }, [...deps]);

  return {
    containerRef,
  };
};

export default useKeepScrollPosition;
